const currentUrl = window.location.href
const translation = {
  'translation.key': 'value',
  'loginPage.login.title': 'Кіру',
  'loginPage.login.submit': 'Кіру керек',
  'loginPage.login.guest': 'Демо-нұсқа',
  'loginPage.login': 'Логин',
  'loginPage.email': 'E-mail',
  'loginPage.pass': 'Пароль',
  'loginPage.timeZone': 'Сағаттық белдеу',
  'loginPage.lang': 'Тілі',
  'loginPage.userName': 'Пайдаланушының аты',
  'loginPage.nameCompany': 'Компанияның атауы',
  'loginPage.textTermsOfUse': "Басып 'Тіркелу керек' 'Сіз шартты қабылдайсыз",
  'loginPage.linkTermsOfUse': 'Пайдаланушының келісімін',
  'name.column': 'Баған атауы',
  'valid.text': 'өрісті толтырыңыз',
  back: 'бұрын',
  'come.back': 'keri qaıtý',
  'pixels.group': 'экран нүктелері',

  // Регистрация  / Тіркеу
  'monitoring.apply_filter': 'Применить фильтры',
  'loginPage.register.title': 'Тіркеу',
  'loginPage.register.submit': 'Тіркелу керек',
  help: 'Справка',
  delete: 'Жою',
  'monitoring.filterMax': '30-дан астам сүзгілерді қосуға болады',
  // Смена пароля / Парольді ауыстыру
  'loginPass.changePass.adminPass': 'Әкімшіден алынған паролді енгізіңіз',
  'loginPass.recovery.title': 'Парольдi қайта тіктеу',
  'loginPass.changePass.title': 'Паролді өзгерту',
  'loginPass.changePass.newPass': 'Жаңа пароль',
  'loginPass.changePass.inputPass': 'Парольді енгізіңіз',
  'loginPass.changePass.inputNewPass': 'Жаңа парольді кірітің',
  'loginPass.changePass.current': 'Ағымдағы пароль',
  'loginPass.changePass.empty': 'Пароль сізге белгісіз болса, бос қалдырыңыз',
  'loginPass.changePass.repeatNewPass': 'Парольді қайталаңыз',
  'loginPass.changePass.confirmNewPass': 'Парольдi растаңыз',
  'loginPass.changePass.submit': 'Кіру керек',
  'loginPass.errors.pass': 'Парольді енгізіңіз',
  'loginPass.errors.newPass': 'Парольді тағы бір рет енгізіңіз',
  'loginPass.errors.notMatchPass': 'Енгізілген парольдер сәйкес келмейді',
  'loginPass.errors.lengthPass': 'Пароль ұзындығы 5 тен 25 дейін болуы керек',

  // Выбор компании / Компанияны таңдау
  'loginPage.company.title': 'Компанияны таңдау',
  'loginPage.company.name': 'Компанияның атауы',
  'loginPage.company.submit': 'Кіру керек',

  // Qr code
  'loginPage.haveAccount': 'У меня есть учетная запись ',
  'loginPage.passHint': 'Ваш пароль состоит минимум из 4 символов.',
  'loginPage.recovery.toMain': 'На главную',
  'loginPass.recovery.hint':
    'На указанный e-mail будет отправлено письмо с ссылкой на восстановление пароля',
  'loginPass.recovery.content':
    'Для восстановления аккаунта, перейдите по ссылке в письме,отправленном на  эл. почту',
  'loginPage.sixCode': 'шестизначный код:',
  'loginPage.carName': 'название машины',
  'loginPage.autoType': 'Тип авто:',
  'loginPage.autoName': 'Имя авто:',
  'loginPage.addObject': 'Добавить объект',
  'loginPage.dontHaveAccount': 'Я новый пользователь SKIF.PRO2',
  'loginPage.enterCode': 'введите шестизначный код:',
  'loginPage.phoneHolder': 'телефон в формате +XXXXXXXXXXXX',
  'loginPage.mail': 'Ваш email',
  'loginPage.password': 'Пароль:',
  'loginPage.yourpassword': 'Ваш пароль',
  'loginPage.offer': 'оферте',
  'loginPage.accept': ' Я принимаю условия изложенные в ',
  'loginPage.register': 'Зарегистрироваться',
  'loginPage.orgName': 'Название вашей организации:',
  'loginPage.orgName.holder': ' название организации',
  'loginPage.timeZones': 'Часовой пояс:',
  'loginPage.name': 'Ваше имя',
  'loginPage.name.label': 'Имя:',
  'loginPage.back': 'Назад',
  'loginPage.validation.sixCode': 'Вы должны ввести шестизначный код:',
  'loginPage.validation.codeType':
    'шестизначный код должен состоять из заглавных английских букв и цифр',
  'loginPage.validation.choosecartype': 'Выберите тип машины',
  'loginPage.validation.tiemzone': 'Выберите  часовой пояс',
  'loginPage.validation.entercarname': 'Введите имя машины',
  'loginPage.validation.name': ' Введите Ваше имя',
  'loginPage.validation.telephone': 'Введите Ваш телефон',
  'loginPage.validation.email': 'Введите Ваш email',
  'loginPage.validation.password': 'Введите Ваш пароль',
  'loginPage.validation.email.correctform':
    'введите правильный адрес электронной почты',
  'loginPage.validation.enter6code': 'Вы должны ввести шестизначный код',
  'loginPage.validation.acceptTerms': 'пожалуйста, примите условия',

  // Подтверждение / Растау
  'loginPage.confirm.title': 'Растау',
  'loginPage.confirm.text1': 'Пайдаланушы тіркелген',
  'loginPage.confirm.text2':
    'Тіркеуді растау үшін көрсетілген поштаға жіберілген хаттағы сілтеме бойынша өтіңіз',
  'loginPage.confirm.submit': 'Басты бетке',

  // Восстановление / Қалпына келтіру
  'loginPass.recovery.back': 'Артқа',
  'loginPage.recovery.title': 'Қалпына келтіру',
  'loginPage.recovery.text1': 'Аккаунтты қалпына келтіру үшін өтіңіз',
  'loginPage.recovery.text2':
    'Көрсетілген поштаға жіберілген хаттағы сілтеме бойынша',

  // Ссылки / Сілтемелер
  'loginPage.links.ifHaveNotAccount': 'Аккаунт жоқ па?',
  'loginPage.links.ifHaveAccount': 'Аккаунт бар ма?',
  'loginPage.links.register': 'Тіркеу',
  'loginPage.links.lostPass': 'Парольді ұмыттыңыз ба?',
  'loginPage.links.login': 'Кіру керек',

  // Ошибки почты / Поштаның қателері
  'loginPage.errors.email': 'Пайдаланушының атын немесе email енгізіңіз',
  'loginPage.errors.emailError': 'Қолданыстағы email енгізіңіз',
  'loginPage.errors.pass': 'Парольді енгізіңіз',
  'loginPage.errors.name': 'Атын енгізіңіз',
  'loginPage.errors.nameCompany': 'Компанияның атауын енгізіңіз',
  'loginPage.errors.region': 'Выберите регион',

  // Таймзона / Таймаймақ
  'loginPage.hint.timeZone': 'Сағаттық белдеуді таңдаңыз',
  'loginPage.hint.lang': 'Тілді таңдаңыз',
  'loginPage.hint.companyName': 'Компанияны таңдаңыз',

  // Кнопки / Кнопкалар
  'loginPage.btn.send': 'Жіберу керек',

  // Кнопки на карте / Картадағы кнопкалар
  logout: 'Шығу керек',

  // Telegram
  'loginPage.telegramChatId': 'Telegram-ға арналған пошталық хабарламалар',
  'loginPage.errors.telegramChatId':
    'Telegram үшін поштаның идентификаторын енгізіңіз',
  'loginPage.enterTheTelegramBot': 'Телеграмма ботын енгізіңіз',
  'loginPage.clickOnStartTheBot-':
    'Бастау батырмасын басыңыз, бот Telegram үшін пошталық идентификаторды қайтарады',

  // Меню / Мәзір
  'menuSelect.reports': 'Есептер',
  'menuSelect.monitoring': 'Мониторинг',
  'menuSelect.geozones': 'Геоаймақтар',
  'menuSelect.notifications': '* Хабарламалар',
  'menuSelect.users': 'Пайдаланушылар',
  'menuSelect.routes': 'Бағыттар',
  'menuSelect.objects': 'Объектілер',
  'menuSelect.race': 'Рейстер',

  // Карта / Карта
  'map.search': 'Нақты мекенжайды табу',
  select: 'Таңдау',
  noMatch: 'Совпадений не найдено',
  // Пользователи / Пайдаланушылар
  'catalog.user': 'Пайдаланушы анықтамалығы',
  'btn.select': 'Таңдау керек',
  'btn.editlist': 'ТІЗІМДІ РЕДАКЦИЯЛАУ КЕРЕК',
  'btn.finisheditlist': 'РЕДАКЦИЯЛАУДЫ АЯҚТАУ КЕРЕК',
  'btn.delete': 'Өшіру',
  'btn.cancel': 'Болдырмау',
  'btn.create': 'Жасау',
  'btn.update': 'Жаңарту',
  'btn.save': 'Сақтау',
  'user.invite_user': 'Шақыру',
  'user.invite_all': 'Барлық компанияға шақыру',
  'user.invite_email': 'Email',
  'user.invite_role': 'Роль',
  'user.invite_role_error': 'Роль енгізіңіз',
  'user.history_change': 'Өзгеріс тарихы',
  'user.create_user': 'Пйдаланушы жасау',
  'user.edit_user': 'Пайдаланушыны редакциялау',
  'users.hint.role': 'Пайдаланушы ролін таңдаңыз',
  'users.hint.lang': 'Тілді таңдаңыз',
  'users.subscribe': 'Пйдаланушыны шақыру',
  'users.approve_close_form':
    'Өзгерістер сақталмаған. Сіз профильді сақтамай шынында да жапқыңыз келе ме?',
  'users.close_form': 'Профильді жабу ',
  'users.invite': 'Шақыру',
  'users.invite_all': 'Барлық компанияларға шақыру ',
  'users.name': 'Аты',
  'users.email': 'Пошта',
  'users.change_password_title': 'Парольді өзгерту',
  'users.sessions': 'Сессиялар',
  'users.enter': 'Кіру',
  'users.exit': 'Шығу',
  'users.change_password': 'Өзгерту',
  'users.current_password': 'Ағымдағы пароль',
  'users.invite_level_access': 'Қолжетімдік деңгейі',
  'users.email_placeholder': 'Поштаны енгізіңіз',
  'users.password_empty': 'Пароль сізге белгісіз болса, бос қалдырыңыз',
  'users.isDriver': 'Водитель',

  name: 'Аты',

  // Геозоны / Геоаймақтар
  'geozones.square': 'Площадь:',
  'geozones.library': 'Геоаймақтар анықтамалығы',
  'geozones.create': 'Геоаймақтар қосу',
  'geozones.edit': 'Геоаймақты редакциялау',
  'geozones.add': 'Қосу',
  'geozones.color': 'Түсі',
  'geozones.name': 'Атауы',
  'geozones.figure': 'Фигурасы',
  'geozones.type': 'Типі',
  'geozones.width': 'Қалыңдығы',
  'geozones.editor': 'Редакциялау',
  'geozones.delete': 'Өшіру',
  'geozones.use_as_address': 'Адрес ретінде қолданыңыз',
  'geozones.unitArea': 'Единицы измерения площади:',
  'geozones.meter': 'M',
  'geozones.km': 'Km',
  'geozones.hectar': 'Ha',
  'geozones.red': 'Красный',
  'geozones.orange': 'Оранжевый',
  'geozones.blue': 'Синий',
  'geozones.grey': 'Серый',
  'geozones.green': 'Зеленый',
  // Сессии
  'sessions.title': 'Сессиялар',
  'sessions.day': 'Күндер',
  'sessions.week': 'Апта',
  'sessions.month': 'Ай',
  'sessions.time': 'Күні/Уақыты',
  'sessions.user': 'Пайдаланушы',
  'sessions.event': 'Оқиғалар',
  'sessions.ip': 'IP мекенжайы',

  // Объекты / Объектілер
  'objects.total.group': 'Объектілер саны',
  'objects.add.objects': 'Объектілер қосу',
  'objects.add.objects.in.group': 'Топқа объектілер қосу',
  objects: 'Объект',
  object: 'Объект',
  'objects.showmonitoring': 'Показать в Мониторинге',
  'objects.name': 'Атын енгізіңіз',
  'objects.objects': 'Объектілер',
  'objects.groups': 'Объектілер',
  'objects.groups.access': 'Права доступа к группе',
  'objects.groups.access-title': 'Права на группу',
  'objects.groups.close-all': 'Свернуть все',
  'objects.groups.objects-in-group': 'Объекты в группе',
  'objects.trailers': 'Тіркемелер',
  'objects.locators': 'Ссылки доступа',
  'objects.locators-date-start': 'Дата и время начала доступа',
  'objects.locators-date-end': 'Дата и время завершения доступа',
  'objects.locators-date-select': 'Выберите дату',
  'objects.locators-link': 'Ссылка',
  objects_locator_create: 'Создание ссылки доступа',
  objects_locator_change: 'Редактирование ссылки',
  'objects.locator.copy-link': 'Ссылка скопирована',
  'objects.locator.status': 'Статус',
  'objects.locator.access-object': 'Объект доступа',
  'objects.users': 'Пайдаланушылар',
  'objects.delete': 'Өшіру',
  'objects.create.new.group': 'Топ жасау',
  'objects.create.edit.group': 'Топты редакциялау',
  'objects.add.units.to.group': 'Топқа объектілерді қосу',
  'objects.name.group': 'Топтың атауы',
  'objects.title': 'Объектілердің анықтамалығы',
  'objects.titleTrailers': 'Справочник прицепов',
  'objects.titleLocators': 'Справочник ссылок доступа',
  'objects.titlegroups': 'Объектілер тобының анықтамалығы',
  'objects.name.number': 'Аты/Нөмірі',
  'objects.type': 'Объектің типі',
  'objects.unit': 'Өлшем бірлігі',
  'objects.device': 'Қондырғының типі',
  'objects.imei': 'Бірегей IMEI',
  'objects.editImei': 'Таңдалған IMEI өңдеу',
  'units.create': 'Объектіні жасау',
  'units.update': 'Объектіні редакциялау',
  'objects_tabs.main': 'Негізгілер',
  'objects_tabs.extra': 'Қосымша',
  'objects_tabs.sensor': 'Бергіш',
  'objects_tabs.TO': 'ТО',
  'objects_tabs.main.analyst': 'Талдаушы',
  'objects_tabs.main.name.error': 'Атын/Нөмірін енгізіңіз',
  'objects_tabs.main.type.error': 'Объект типін таңдаңыз',
  'objects_tabs.main.device.error': 'Қондырғы типін енгізіңіз',
  'objects_tabs.main.id.error': 'Бірегей ID енгізіңіз',
  'objects_tabs.main.phone.error': 'Телефон енгізіңіз',
  'objects_tabs.main.analyst.error': 'Талдаушыны енгізіңіз',
  'objects_tabs.extra.motohours': 'Моточасы',
  'objects_tabs.extra.trips': 'Поездки',
  'objects_tabs.extra.fuel': 'Топливо',
  'objects_tabs.extra.etc': 'Дополнительно',
  'objects_tabs.extra.custom': 'Пользовательские',
  'objects_tabs.extra.name': 'Аты',
  'objects_tabs.extra.value': 'Мәні',
  'objects_tabs.extra.addNewField': 'Жаңа өрісті қосу',
  'objects_tabs.extra.changeField': 'Өрісті өзгерту',
  'objects_tabs.extra.update_field': 'Өрісті жаңарту',
  'objects_tabs.sensors.units': 'Өлшем бірлігі',
  'objects_tabs.sensors.hide_in_monitoring': 'Скрывать в мониторинге',
  'objects_tabs.sensors.sensors_create': 'Бергішті жасау',
  'objects_tabs.sensors.sensors_edit': 'Бергішті редакциялау',
  'objects_tabs.sensors.type_sensor': 'Бергіштің типі',
  'objects_tabs.sensors.error_type_sensor': 'Введите тип датчика!',
  'objects_tabs.sensors.error_formula': 'Выберите формулу!',
  'objects_tabs.sensors.formula.columns': 'Баған формуласы',
  'objects_tabs.sensors.error_param12': 'Выберите параметр 1 или параметр 2!',
  'objects_tabs.sensors.option1': 'Параметр 1',
  'objects_tabs.sensors.option2': 'Параметр ',
  'objects_tabs.sensors.formula': 'Формула',
  'objects_tabs.sensors.hide_in_reports': 'Скрывать в отчетах',
  'objects_tabs.sensors.description': 'Сипаттама',
  'objects_tabs.sensors.border_bottom': 'Төменгі шекара',
  'objects_tabs.sensors.coefficientA': 'А коэффициенті',
  'objects_tabs.sensors.coefficientB': 'Б коэффициенті',
  'objects_tabs.sensors.valueX': 'Х мәні',
  'objects_tabs.sensors.valueY': 'Y мәні',
  'objects_tabs.sensors.enterValue': '!мәнін енгізіңіз',
  'objects_tabs.sensors.keyExist': 'Мұндай кілт қолданыста бар!',
  'objects_tabs.sensors.upperBorder': 'Х жоғарғы шекарасы',
  'objects_tabs.sensors.lowerBorder': 'Х төменгі шекарасы',
  'objects_tabs.sensors.smoothing': 'Тегістеу',
  'objects_tabs.sensors.coefficient': 'Коэффициент',
  'objects_tabs.sensors.selectValueX': 'х! Мәнін таңдаңыз',
  'objects_tabs.sensors.selectValueY': '! мәнін таңдаңыз',
  'objects_tabs.sensors.selectFromValue': 'fromValue! таңдаңыз',
  'objects_tabs.sensors.selectA': 'а! Мәнін таңдаңыз',
  'objects_tabs.sensors.selectB': 'b! Мәнін таңдаңыз',
  'objects_tabs.sensors.actions': 'Әрекеттер',
  'objects_tabs.sensors.show_graph': 'Показать график',
  'objects_tabs.sensors.show_table': 'Показать таблицу',
  'objects_tabs.drivers.driver': 'Жүргізуші',
  'objects_tabs.drivers.from': 'на слово добавляется окончание',
  'objects_tabs.drivers.to': 'Бойынша',
  'objects_tabs.drivers.add': 'Жүргізушіні қосу',
  'objects_tabs.drivers.change': 'Жүргізушіні өзгерту',
  'objects_tabs.trailer': 'Тіркеме',
  'objects_tabs.trailers': 'Тіркемелер',
  'objects_tabs.trailer.add': 'Тіркеме қосу',
  'objects_tabs.trailer.change': 'Тіркеме өзгерту',
  'trailer.width': 'Тіркеме ені(метр)',
  'objects_tabs.services.create_form': 'ТҚ құру',
  'objects_tabs.services.edit_form': 'ТҚ редакциялау',
  'objects_tabs.services.name_service': 'Сервис',
  'objects_tabs.services.description': 'Сипаттама',
  'objects_tabs.services.active_on_interval_mileage':
    'Жүгіріс бойынша интервалды белсенділендіру',
  'objects_tabs.services.active_on_interval_days':
    'Күнмен интервалды белсенділендіру',
  'objects_tabs.services.interval_days_numb_day': 'Күнмен интервал, күн/күндер',
  'objects_tabs.services.last_one_date': 'Соңғы рет, мерзім',
  'objects_tabs.services.interval_mileage_numb_km':
    'Жүгіріс бойынша интервал, күн/км',
  'objects_tabs.services.last_one_numb_km': 'Соңғы рет, күн/км',
  'objects_tabs.services.interval_mileage': 'Жүгіріс',
  'objects_tabs.services.interval_days': 'Күндер',

  'objects_tabs.services.warning.name': 'Қызмет атауын енгізіңіз!', // Введите названия сервиса!
  'objects_tabs.services.warning.description': 'Сипаттаманы енгізіңіз!', // Введите описание
  'objects_tabs.services.warning.date': 'Күнін енгізіңіз!', // Введите дату!
  'objects_tabs.services.date.placeholder': 'Күнін енгізіңіз!', // Введите дату!
  'objects_tabs.services.mileageInterval': 'Жүгіру аралығы енгізіңіз!', // Введите интервал по пробегу
  'objects_tabs.services.lastTime': 'Соңғы рет енгізіңіз!', // Введите последний раз!
  'objects_tabs.services.intervalInDays': 'Аралықты күнмен енгізіңіз!', // Введите интервал в днях!
  'objects_tabs.services.intervalInMotoHours':
    'Мотосағатымен интервалды қосыңыз', // Активировать интервал по моточасам
  'objects_tabs.services.intervalInMotoHoursDate':
    'Мотосағаттық аралығы, күні/сағат', // Интервал по моточасам, число/часы
  'objects_tabs.services.lastTimeDate': 'Соңғы рет, күні/сағат', // Последний раз, число/часы
  'objects_tabs.services.lastTimeDate.warning': 'Соңғы рет енгізіңіз!', // Введите последний раз!

  objects_trailer_create: 'Тіркеме құру',
  objects_trailer_change: 'Тіркеме өзгерту',
  objects_trailer_moreFields: 'Қосымша өріс',
  objects_trailer_key: 'Кілт',
  objects_phone1: 'Телефон 1',
  objects_phone2: 'Телефон ',
  objects_noPicture: 'Сурет таңдалмады',
  objects_server: 'Мәліметтер қабылдау сервері: ',
  objects_port: 'Порт: ',
  'objects.fillfield': 'Необходимо заполнить поля в разделе Основные',

  // events
  'events.create': 'Іс-шара жасау',
  'events.update': 'Іс-шараны жаңарту',
  'events.datetime': 'Күні/Уақыт',
  'events.deviation_minutes': 'Ауытқу, мин.',
  'events.filled': 'Жанармай құю, л.',
  'events.consumed': 'Расход топлива, л.',
  'events.price': 'Баға',
  'events.description': 'Сипаттама',
  'events.stolen': 'Жанармай төгілді, л.',
  'events.service_name': 'Сервис',
  'events.motohours': 'Мотоцикл сағ.',
  'events.millage': 'Қашықтық км.',
  'events.duration_hours': 'Ұзақтығы сағ.',
  'events.guaranteed': 'Кепілдік',
  'events.start_date': 'Басталу',
  'events.finish_date': 'Аяқталу',
  'events.user_id': 'Жүргізуші',
  'events.type_name': 'Атын теріңіз',
  'events.duration_minutes': 'ДҰзақтығы, мин  ',
  'events.type_duration_hours': 'Ұзақтығы, сағ.',
  'events.requiredFields': 'Міндетті өрістер',

  'events.validation.required': 'Міндетті өріс',
  'events.validation.datetime': 'Күн уақытын енгізіңіз',
  'events.validation.number': '0-ден көп болуы керек',

  'error.502':
    'К сожалению, сервер временно недоступен. Попробуйте повторить действие чуть позже.',
  'error.429': 'Слишком много попыток, попробуйте позже.',

  // Отчеты / Есептер
  reports: 'Есептер',
  'reports.chart.intervals': 'Аралығы',
  'reports.chart.thefts_markers': 'Су төгетін маркерлер',
  'reports.chart.fillings_markers': 'Толтыру маркерлері',
  'reports.chart.thefts': 'Ұрлық',
  'reports.chart.fillings': 'Толтырулар',
  'reports.build-report': 'Салу',
  'reports.base-table': 'Базалық кесте',
  'reports.group-table': 'Кестелер тобы',
  'reports.select-table': 'Кесте таңдау',
  'reports.select-template': 'Кесте шаблонын таңдау',
  'reports.select-object': 'Көлік құралын таңдау',
  'reports.select-group-object': 'Объектілер тобын таңдау',
  'reports.not-select': 'Таңдалмады',
  'reports.object': 'Объект',
  'reports.group-object': 'Объектілер тобы',
  'reports.period': 'Кезең',
  'reports.reports-visit-geozone': 'Геоаймаққа бару бойынша есеп',
  'reports.reports-size-screen.minimum': '1/4',
  'reports.reports-size-screen.thirdScreen': '1/3',
  'reports.reports-size-screen.medium': '1/2',
  'reports.reports-size-screen.maximum': 'Бүкіл экран',
  'reports.was-deleted': 'был удален',
  'reports.add-group-report': 'Шаблонға қосу',
  'reports.edit-report': 'Редакциялау',
  'reports.load-report': 'Жүктеу',
  'reports.show-color': 'Жолдың түсі',
  'reports.print-report': 'Басып шығару',
  'reports.show-chart': 'Кесте',
  'reports.action': 'Әрекет',
  'reports.edit-reports': 'Есеп параметрлерін редакциялау',
  'reports.table.type': 'Тип Таблицы',
  'reports.add-table-to-reports': 'Шаблоннан кесте қосу',
  'reports.reports-template': 'Шаблонды редакциялау',
  'reports.tab-main': 'Жалпы',
  'reports.tab-columns': 'Бағаналар',
  'reports.tab-geozones': 'Геоаймақтар',
  'reports.tab-main.group': 'Топтама',
  'reports.tab-main.group-field': 'Өріс бойынша топтастыру',
  'reports.tab-main.show-total': 'Қорытындысын көрсету',
  'reports.tab-main.interval-priority': 'Есеп беру интервалының басымдығы',
  'reports.tab-main.interval-priority-tooltip': `Егер бұл параметр белсенді болса және интервал Shift басына немесе аяғына дейін кесілсе, онда ол есепке толығымен қосылады.
    <br>
    Егер бұл параметр белсенді болмаса, онда нақты Shift аралығы есепке қосылады.
    <br>
    Мысалы, ауысым 08: 00-де, ал сапар 06: 00-де басталса,
    <br>
    содан кейін «есеп беру аралықтарының басымдығы» белсенді орнатылған жағдайда, сапар туралы есепте интервал сағат 06: 00-де басталады,
    <br>
    егер «есеп беру аралықтарының басымдығы» параметрі белсенді болмаса, онда сағат 08: 00-де.`,
  'reports.editTemplate.nameTemplate': ' Шаблонның атауы',
  'reports.add-new-template': 'Жаңа шаблон қосу',
  'reports.name-template': 'Шаблонның аты',
  'reports.add-in-template': 'Шаблонға қосу',
  'reports.is-already-template': 'Қолданыстағы',
  'reports.new-temlate': 'Жаңа',
  'reports.templateWarning': 'Шаблонның атын енгізіңіз',
  'reports.tables': 'Кестелер',
  'reports.onlyResult': 'Тек қорытындыларын көрсету',
  'reports.connect': 'Кестемен байланыстыру',
  'reports.attached': 'Файл тіркелген',
  'reports.show_annotation_on_click': 'Жолды басу арқылы аннотация',
  'reports.show_annotation_on_click_hint':
    'Белсендірілген болса, есеп жолын басқан кезде оқиға туралы аннотация көрсетіледі.',

  'reports.column': 'Баған',
  'reports.operation': 'Пайдалану',
  'reports.value': 'Мән',
  'reports.contains': 'қамтиды',
  'reports.list_key': 'Excel үлгісіне арналған кілттер тізімі',
  'reports.copy_key':
    'Кілтті Excel үлгісіндегі ұяшыққа көшіріп, үлгіні SKIF.PRO ішіне жүктеңіз.',
  'reports.key_hint':
    'Excel бағдарламасында есеп үлгісін жасаңыз, содан кейін тиісті кілттерді көшіру арқылы оған деректерді орналастырыңыз. Үлгіні сақтаңыз және оны SKIF.PRO-ға жүктеңіз, жүйе есеп жасалған кезде кілттердің орнына мәндерді тауып, ауыстырады.',
  'reports.sensor_placeholder': 'Сенсорлық маска',
  'reports.mini_hint':
    'Көшіруден кейін Excel үлгісі ұяшығын таңдап, CTRL+V пернелер тіркесімін басыңыз.',
  'reports.copy': 'Көшіру',
  'reports.tables.tabs.general': 'Жалпы',
  'reports.tables.tabs.columns': 'Бағандар',
  'reports.tables.tabs.sensors': 'Сенсорлар',
  'reports.tables.tabs.additional_columns': 'Косу. спикерлер',
  'reports.tables.tabs.data': 'Деректер',
  'reports.tables.tabs.hint':
    'Жабық есептерде пайдаланушы өзі құрастырылған сенсорлар мен параметрлерді өзгерте алмайды.',
  'reports.tables.tabs.mask_sensor': 'Сенсорлық маскалар',
  'reports.tables.tabs.mask_sensor_hint':
    'Есепті жасау үшін көрсетілген маскалардың кем дегенде біреуін қанағаттандыратын нысан сенсорлары таңдалады (толығырақ ',
  'reports.tables.tabs.type_table': 'Кесте түрі:',
  'reports.tables.tabs.table_name': 'Аты:',
  'reports.tables.tabs.all_protocol': 'Барлық протоколдар',
  'reports.tables.tabs.parameters': 'Опцияны таңдаңыз',
  'report.tables.tabs.name_mask': 'Датчик атауының маскасы',
  'report.tables.tabs.enter_value': 'Мән енгізіңіз',
  'report.tables.tabs.add_mask': 'Сенсор атауы бойынша маска қосыңыз',
  'report.tables.tabs.parameters': 'Параметрлер',
  'report.tables.tabs/parameter_hint':
    'Есепті құруға қатысатын параметрлердің атауларын таңдаңыз.',
  'report.tables.tabs.parameter': 'Параметр',
  'report.tables.tabs.add_parameter': 'Параметрді қосыңыз',
  'reports.tables.tabs.make_close_report': 'Есепті жеке етіңіз',
  'reports.tables.tabs.geozones': 'Географиялық қоршаулар',
  'reports.edit_template': 'Үлгіні өзгерту',
  'reports.enter_name': 'Атыңызды енгізіңіз!',
  'reports.template': 'Үлгі',
  'reports.tables.fields.name': {
    label: 'Аты',
    error: 'Атыңызды енгізіңіз!'
  },
  'reports.tables.fields.showfillings': 'Толтыруларды көрсету',
  'reports.tables.fields.showThefts': 'Жанармай құю станцияларын көрсету',
  'reports,tables.fields.mask_name_sensor': 'Жанармай сенсорының атауы маскасы',
  'reports.tables.fields.add_mask': 'Сенсор атауы бойынша маска қосыңыз',
  // Легенда / Негіз
  'tracking.speed.legend.text': 'жылдамдық градациясы',
  'reports.tables.fields.grouping': 'Топтастыру',
  'reports.tables.fields.grouping_by_field': 'Өріс бойынша топтастыру',
  'reports.form_for_unloading': 'Жүктеп салу пішіні (excel үлгісі)',
  'reports.tables.fields.template_upload': 'Жүктеп алу',
  'reports.show_key': 'Excel үлгісіне арналған кілттер тізімін қараңыз',
  'reports.tables.fields.excel_template_name': 'Excel үлгісінің аты',
  'reports.tables.fields.sensor_name_mask': 'Датчик атауының маскасы',
  'reports.tables.add_mask_sensor': 'Сенсор атауы бойынша маска қосыңыз',

  // Мониторинг / Мониторинг
  monitoring: 'Мониторинг',
  'monitoring.datepoint': 'Байланыста болды',
  'monitoring.add-objects': 'Объектілерді қосу',
  'monitoring.filter-journal-events': 'Оқиғалар журналының сүзгісі',
  'monitoring.view-events': 'Оқиғалар түрі',
  'monitoring.objects': 'Объектілер',
  'monitoring.send-command': 'Команда жіберу',
  'monitoring.change-object': 'Объектіні өзгерту',
  'monitoring.registration-event': 'Оқиға тіркеу',
  'monitoring.fast-track': 'Жылдам трек',
  'monitoring.fast-report': 'Жылдам есеп',
  'monitoring.create-event': 'Іс-шара жасау',
  'monitoring.type-event': 'Оқиға типі',
  'monitoring.position': 'Жағдай',
  'monitoring.violation': 'Бұзу',
  'monitoring.date-and-time': 'Мерзім және уақыт',
  'monitoring.sendCommand-title': 'Команда жіберу',
  'monitoring.enter-description': 'Сипаттама енгізіңіз',
  'monitoring.trips': 'Сапарлар',
  'monitoring.graph': 'Кесте',
  'monitoring.editObject': 'Объектіні редакциялау',
  'monitoring.activeFilters': 'Белсенді сүзгілер',
  'monitoring.choose': 'Таңдау',
  'monitoring.searchHolder': 'тұтану, отын',
  'monitoring.addFilter': 'Сүзгіні қосыңыз',
  'monitoring.accept': 'Қолдану',
  'monitoring.filters': 'Сүзгілер',
  // Журнал событий / Оқиға журналы
  'journal-events': 'Оқиға журналы',
  'journal-events.filter': 'Сүзгі',
  'monitoring.maxUnits':
    'Бір уақытта 300-ден астам нысанды көрсетуге болмайды, нысандар санын азайту үшін филтрді пайдаланыңыз.',

  'monitoring.filter.name': 'Аты',
  'monitoring.filter.imei': 'IMEI',
  'monitoring.filter.phoneNumber': 'Телефоны',
  'monitoring.filter.customFields': 'Арнаулы өрістер',
  'monitoring.filter.sensorName': 'Датчиктің аты',
  'monitoring.filter.sensorType': 'Сенсор түрі',
  'monitoring.filter.sensorParam': 'Сенсор параметрі',
  'monitoring.filter.event': 'Оқиға',
  'monitoring.filter.driver': 'Жүргізуші',

  'monitoring.filter.fieldsEmpty': 'Барлық өрістер толтырылуы керек',
  'monitoring.terminal_on': 'Терминал қосылған',
  select_all: 'Барлығын таңдаңыз',
  // Уведомления / Хабарламалар
  'notifications.motohours': 'Қозғалтқыш сағаттары бойынша',
  'notifications.notificationName': 'Хабарламаның аты',
  'notifications.responsetime': 'Мин. хабарландыруды іске қосу уақыты',
  'notifications.periodcontrol': 'Ағымдағы уақытқа қатысты бақылау кезеңі',
  'notifications.notification': 'Хабарламалар',
  'notifications.period': 'Кезең',
  'notifications.name': 'Атауы',
  'notifications.view': 'Түрі',
  'notifications.objects': 'Объектілер',
  'notifications.enterName': 'Атын енгізіңіз',
  'notifications.change': ' Хабарламаны өзгерту',
  'notifications.add': 'Хабарлама қосу',
  'notifications.main': 'Негізгі',
  'notifications.more': 'Қосымша',
  'notifications.delivery': 'Жеткізу',
  'notifications.text': 'Мәтін',
  'notifications.control': 'Бақылау',
  'notifications.types': 'Хабарлама типі',
  'notifications.markAsRead': 'Оқылған деп белгілеу',
  'notifications.template': 'Хабарлама шаблоны',
  'notifications.textDescription':
    'Төменде келтірілген арнайы параметрлерді пайдаланып хабарлама мәтінін енгізіңіз. Хабарлама жұмыс істеген кезде бұл параметрлер нақты мәндермен ауыстырылады',
  'notifications.enterNotifyText': 'Хабарлама мәтінін енгізіңіз',
  'notifications.paramTab': 'Параметрлер кестешесі',
  'notifications.latitude': 'Ендік',
  'notifications.longitude': 'Бойлық',
  'notifications.driver': 'Жүргізуші',
  'notifications.speed': 'Жылдамдық',
  'notifications.objectName': 'Объектінің атауы',
  'notifications.sensors': 'Бергіштер',
  'notifications.sensorValue': 'Сенсордың мәні',
  'notifications.adress': 'Мекенжай',
  'notifications.time': 'Жұмыс істеу уақыты',
  'notifications.fill': 'Жанармай құю',
  'notifications.drain': 'Жанармай төгу',
  'notifications.final_fuel': 'Соңғы жанармай',
  'notifications.geozone_name': 'Геозона атауы',
  'notifications.tripName': 'Рейстің атауы',
  'notifications.workType': 'Жұмыстың типі',
  'notifications.pointName': 'Нүктенің атауы',
  'notifications.deviation': 'Ауытқу',

  'notifications.deliveryMethod': 'Жеткізу әдісі',
  'notifications.chooseEmail': 'Ең болмаса 1 email таңдаңыз!',
  'notifications.choosePhone': 'Ең болмаса 1 телефон таңдаңыз',
  'notifications.chooseUser': 'Кем дегенде бір пайдаланушыны таңдаңыз',
  'notifications.chooseDeliveryType': 'Жеткізу түрін таңдаңыз',
  'notifications.use_speed_limit': ' жоғары жылдамдықты шектеу ',
  'notification.use_speed_description':
    'Бұл құсбелгіні белсендіру жүргізушіге төменде таңдалған геоаймақтарға кіру кезінде газ педалын басу арқылы нысанды көрсетілген максималды жылдамдықтан жоғарылатуға мүмкіндік бермейді.',
  km_hour: 'км/сағ',
  'notifications.asEvent': 'Оқиға ретінде тіркеу ',
  'notifications.asViolation': 'Бұзу ретінде тіркеу',
  'notifications.temporaryValueMonitoring': 'Мәнді уақытша бақылау',
  'notifications.from': 'бастап',
  'notifications.to': 'дейін',
  'notifications.monday': 'Дүйсенбі',
  'notifications.tuesday': 'Сейсенбі',
  'notifications.wednesday': 'Сәрсенбі',
  'notifications.thursday': 'Бейсенбі',
  'notifications.friday': 'Жұма',
  'notifications.saturday': 'Сенбі',
  'notifications.sunday': 'Жексенбі',
  'notifications.geozones.control': 'Геоаймақ бақылау',
  'notifications.geozones.verificationType': 'Тексеру типі',
  'notifications.geozones.chooseVerficationType': 'Тексеру типін таңдаңыз',
  'notifications.geozones.geozones': ' Геоаймақтар',
  'notifications.simpleTransport': 'Көлік құралының тұрып қалуы',
  'notifications.maxSpeed': 'Жылдамдық, көп емес',
  'notifications.kmph': 'км/сағ.',
  'notifications.allowedDowntime': 'Қабылдайтын тоқталу',
  'notifications.min': 'мин.',
  'notifications.warning':
    'Тұрып қалу ұзақтығын немесе жылдамдықты таңдаңыз, көп емес',
  'notifications.connexionLost': 'Байланысты жоғалту',
  'notifications.lossDuration': 'Жоғалту ұзақтығы',
  'notifications.lostMsg': 'Жүгіріс аралығын немесе күн аралығын таңдаңыз!',
  'notifications.sensor.control': 'Бергіш мәнін бақылау',
  'notifications.sensor.controleGeo': 'Географиялық қоршауды бақылау',
  'notifications.sensor.type': 'Бергіш типі',
  'notifications.sensor.maskByName': 'Аты бойынша маска',
  'notifications.sensor.meaning': 'Мағынасы',
  'notifications.sensor.warning':
    'Бергіш түрін немесе масканы аты бойынша таңдаңыз!',
  'notifications.sensor.meaningFrom': '... бастап мәні',
  'notifications.sensor.minimalTime': 'Мин. Жауап беру уақыты:',
  'notifications.sensor.writeNumber': 'Нөмірін енгізіңіз',
  'notifications.sensor.meaningTo': '... дейін мәні',
  'notifications.sensor.warningFromTo': 'бастап немесе дейін мәнін таңдаңыз',
  'notifications.sensor.work': 'Жұмыс істеу',
  'notifications.sensor.selectFrame': 'Рамкалар таңдаңыз',
  'notifications.service.maintenance':
    'Жоспарлы ТҚ мерзімінің жақындағаны туралы хабарлау',
  'notifications.service.days': 'күндер',
  'notifications.service.for': 'үшін',
  'notifications.service.byMilage': 'Жүгіріс бойынша',
  'notifications.service.km': 'км',
  'notifications.service.warning':
    'Жүгіріс аралығын немесе күн аралығын таңдаңыз!',
  'notifications.trips.mask': 'Рейс атауының маскасы',
  'notifications.trips.validationOne': 'Сервис атауын дұрыс енгізіңіз',
  'notifications.trips.alertEvent': 'Ескерту оқиғалары ',
  'notifications.trips.start': 'Рейстің басталуы',
  'notifications.trips.end': 'Рейстің соңы',
  'notifications.trips.departure': 'Бақылау нүктелерінен жіберу',
  'notifications.trips.onlyViolation': 'Тек бұзу жағдайында',
  'notifications.trips.arrCheckPoint': 'Бақылау нүктелеріне келу',
  'notifications.trips.pass': 'Бақылау нүктелерін өткізу (Қатаң тәртіп)',
  'notifications.velocity.speed': 'Жылдамдық бақылау',
  'notifications.velocity.min': 'кем емес',
  'notifications.velocity.max': 'көп емес',
  'notifications.velocity.warning':
    'Жылдамдық енгізіңіз, көп емес немесе кем емес',
  'notifications.velocity.geozones': 'Геоаймақтар',
  'notifications.online': 'Желіде',
  'notifications.byEmail': 'Электрондық пошта арқылы',
  'notifications.bySms': 'Sms арқылы',
  'notifications.byPush': 'Хабарландыруларды итеріңіз',
  'notifications.byTelegram': 'Telegram желісінде',
  'notifications.email.placeHolder': 'Email енгізіңіз...',
  'notifications.phone.placeHolder': ' Телефон енгізіңіз ...',
  'notifications.push.placeHolder': 'Іздеу',
  'notifications.telegram.placeHolder': 'Атын немесе telegram',

  // notifications texts
  'notifications.text.arrival':
    'Хабардар етеміз жақындағаны туралы немесе мерзімін кейінге қалдыру жоспарлы техникалық қызмет көрсету объектісінен %UNIT%',
  'notifications.text.exit':
    '%UNIT% в %TIME% тіркелген кіру географиялық аймаққа/  географиялық аймақтың жылдамдығы шығу %SPEED% жанында %ADDRESS%',
  'notifications.text.sensor':
    '%UNIT% в %TIME% тіркелген қосу датчик жылдамдығы %SPEED% жанында %ADDRESS%',
  'notifications.text.trip':
    'тіркелген қар маршруттан / рейс жанында %ADDRESS%',
  'notifications.text.speed':
    '%UNIT% в %TIME% асып, рұқсат етілетін шегі жылдамдығының мәнімен %SPEED% жанында %ADDRESS%',
  'notifications.text.connection':
    'Қабір байланысты жоғалту немесе координаттарды %UNIT% в %TIME% жанында %ADDRESS%',
  'notifications.text.idle':
    'Объект %UNIT% в %TIME% асып рұқсат етілген уақыты жанында %ADDRESS%',
  'notifications.text.filling':
    '%UNIT% %TIME% де %ADDRESS% маңайында %LAST_FUEL% дейін %FILL% жанармай құйды.',
  'notifications.text.drain':
    '%UNIT% в %TIME% слил %DRAIN% до %LAST_FUEL% возле %ADDRESS%',
  'notifications.text.geozone.inside':
    '%UNIT%, %SPEED% жылдамымен %TIME%-та %GEOZONE% геозонасына кірді жазылды, %ADDRESS%-ге жақында.',
  'notifications.text.geozone.outside':
    '%UNIT%, %SPEED% жылдамымен %TIME%-та %GEOZONE% геозонасынан шығды жазылды, %ADDRESS%-ге жақында.',

  // trips & routes
  'routesTrips.title': 'Бағыттар мен рейстер',
  'routesTrips.routes': 'Бағыттар',
  'routesTrips.trips': 'Рейстер',
  'routesTrips.name': 'Аты',
  'routesTrips.addTrip': 'Рейс қосу',
  'routesTrips.editTrip': 'Рейсті редакциялау',
  'routesTrips.trip.name': 'Аты*',
  'routesTrips.trip.date.placeholder': 'Мерзімін таңдаңыз',
  'routesTrips.trip.date.set_days_period': 'Мерзімнің күндерін сүзіңіз',
  'routesTrips.trip.date.reset_days_period': 'Қалпына келтіру кезеңі күндері',
  'routesTrips.trip.date.filter_days': 'Күндерді сүзу',
  'routesTrips.trip.date.total_days_period': 'Кезеңнің жалпы күндері',
  'routesTrips.trip.date.cancel_days_period': 'Мереке күндерін қоспағанда',
  'routesTrips.trip.date.week_days.monday': 'Дү',
  'routesTrips.trip.date.week_days.tuesday': 'Се',
  'routesTrips.trip.date.week_days.wednesday': 'Сә',
  'routesTrips.trip.date.week_days.thursday': 'Бе',
  'routesTrips.trip.date.week_days.friday': 'Жұ',
  'routesTrips.trip.date.week_days.saturday': 'Се',
  'routesTrips.trip.date.week_days.sunday': 'Же',
  'routesTrips.trip.date.week_days.all_days': 'Барлық күндер',
  'routesTrips.trip.date.week_days.even': 'Тіпті',
  'routesTrips.trip.date.week_days.odd': 'Тіпті емес',
  'routesTrips.trip.name.placeholder': 'Рейстің атауы',
  'routesTrips.trip.validation': 'Толтыруға арналған міндетті өріс',
  'routesTrips.trip.validation.total_period':
    'Сіз кем дегенде бір жалпы күн қосуыңыз керек',
  'routesTrips.trip.object': 'Объект',
  'routesTrips.trip.object.placeholder': 'Объект',
  'routesTrips.trip.activationType': 'Белсенділендіру типі',
  'routesTrips.trip.activationType.tooltip':
    ", Егер' бірінші нүктенің уақыты бойынша ' таңдалса, онда рейстің басталуы қатаң түрде бірінші бақылау нүктесінде көрсетілген уақыт бойынша белгіленеді. Егер' бірінші нүктеге кіру бойынша 'таңдалса, онда рейстің басталуы бірінші нүкте аймағына кіру уақыты бойынша белгіленеді, ал келесі барлық уақыттар бірінші бақылау нүктесінде көрсетілген' келу ' уақыты мен бірінші нүкте аймағына кіру уақыты арасындағы айырма уақытына ауыстырылады. Егер 'бірінші нүктеден шығу бойынша' таңдалса, онда рейстің басталуы бірінші нүкте аймағынан шығу уақыты бойынша белгіленеді, ал кейінгі барлық бақылау уақыттары бірінші бақылау нүктесінде көрсетілген 'кету' уақыты мен бірінші нүкте аймағынан шығу уақыты арасындағы айырмашылық уақытына ауысады.",

  'routesTrips.trip.firstPoint': 'Бірінші нүкте уақыты бойынша',
  'routesTrips.trip.entranceToFirstPoint': 'Бірінші нүктеге кіру бойынша',
  'routesTrips.trip.leavingToFirstPoint': 'Бірінші нүктеден шығу бойынша',
  'routesTrips.trip.order': 'Өту тәртібі',
  'routesTrips.trip.order.strict': 'Қатаң',
  'routesTrips.trip.order.arbitrary': 'Еркін',
  'routesTrips.trip.period': 'Кезең',
  'routesTrips.trip.driver': 'Жүргізуші',
  'routesTrips.trip.workType': 'Жұмыстар типі',
  'routesTrips.trip.trailer': 'Тіркеме',
  'routesTrips.trip.point': 'Нүкте',
  'routesTrips.trip.arrival': 'Келу',
  'routesTrips.trip.arrivalDev': 'Келудің ауытқуы',
  'routesTrips.trip.departure': 'Жөнелту',
  'routesTrips.trip.departureDev': 'Жөнелтудің ауытқуы',
  'routesTrips.trip.clearDays': 'Қосымша тәуліктерді тазалау',
  'routesTrips.trip.addDays': 'Кезекті нүктелерге тәуліктер қосу',
  'routesTrips.trip.removeDays': 'Кезекті нүктелерден тәуліктерді өшіру',
  'routesTrips.trip.pointsValidation': 'Ең болмаса бір нүкте таңдаңыз',
  'routesTrips.trip.addFromRoutes': 'Бағыт нүктесін қосу',
  'routesTrips.trip.addFromMap': 'Картадан нүкте қосу',
  'routesTrips.trip.addFromGeozones': 'ГЕОАЙМАҚТАН ҚОСУ',
  'routesTrips.trip.editGeozone.title': 'Редактирование геозоны',
  'routesTrips.trip.editGeozone.figure': 'Фигурасы',
  'routesTrips.trip.editGeozone.name': 'Атауы ',
  'routesTrips.trip.createGeozone.title': 'Картадан нүкте қосу',
  'routesTrips.trip.createGeozone.figure': 'Фигурасы',
  'routesTrips.trip.createGeozone.name': 'Атауы',
  'routesTrips.trip.addFromRoutes.title': 'БАҒЫТТАН НҮКТЕ ҚОСУ',
  'routesTrips.trip.addFromGeozones.title': 'Геоаймақтан қосу',
  'routesTrips.route.create': 'Бағыт жасау',
  'routesTrips.route.name.placeholder': 'Имя маршрута',
  'routesTrips.route.edit': 'Бағытты редакциялау',
  'routesTrips.route.addFromMap': 'Картадан нүкте қосу',
  'routesTrips.route.addFromGeozones': 'ГЕОАЙМАҚТАН ҚОСУ',
  'routesTrips.route.point': 'Нүкте',
  'routesTrips.route.createGeozone.title': 'Картадан нүкте қосу',
  'routesTrips.route.createGeozone.figure': 'Фигурасы',
  'routesTrips.route.createGeozone.name': 'Атауы',
  'routesTrips.route.createGeozone.width': 'Ені',
  'routesTrips.route.addFromGeozones.title': 'Геоаймақтан қосу',
  // Справочник / Анықтамалық
  'mailing.title': 'Таратулар анықтамалығы',
  'mailing.name': 'Атауы',
  'mailing.date': 'Мерзімі',
  'mailing.isActive': 'Белсенділігі',
  'mailing.edit': 'Өзгерту',
  'mailing.create': 'Тарату жасау',
  'mailing.enterName': 'Атын енгізіңіз',
  'mailing.template': 'Шаблон таңдау',
  'mailing.vehicle': 'Көлік түрін таңдау',
  'mailing.chooseObject': 'Объект таңдаңыз',
  'mailing.chooseGroup': 'Топ таңдаңыз',
  'mailing.object': 'Объект',
  'mailing.objectGroup': 'Объект тобы',
  'mailing.periodicity': 'Кезеңділік',
  'mailing.periodicity.warning': 'Кезеңділік енгізіңіз',
  'mailing.daysweek': 'Апта күндері',
  'mailing.daysweek.warning': 'Апта күндерін енгізіңіз',
  'mailing.adresses': 'Адресаттар',
  'mailing.adresses.warning': 'Тарату адресаттарын енгізу ',
  'mailing.subject': 'Хабарлама мәтіні',
  'mailing.subject.warning': 'Хабарлама енгізіңіз',
  'mailing.timeDateSent': 'Соңғы жөнелту уақыты/мерзімі',

  // Annotation
  'annotation.data_date': 'Деректердің алынған күні:',
  'annotation.reports': 'Есептер:',
  'annotation.gps_data': 'GPS-деректер:',
  'annotation.sensors': 'Сенсорлар:',
  'annotation.additional': 'Қосымша:',
  'annotation.last_params': 'Соңғы деректер:',

  // История изменений / Өзгеріс тарихы

  'history.title': 'Өзгеріс тарихы',
  'history.date': 'Мерзімі/Уақыты',
  'history.author': 'Автор',
  'history.operation': 'Операция',
  'history.event': 'Оқиға',
  'history.edit': 'Редакциялау',
  'history.create': 'Жасау',
  'history.delete': 'Өшіру',

  // ADMIN
  'admin-panel': 'Әкімші панелі',
  'admin-panel.timezone': 'Часовой пояс',
  'admin-panel.title': 'Әкімші панелі',
  'admin-panel.company': 'Компания',
  'admin-panel.is-company': 'Введите компанию!',
  'admin-panel.show': 'Показать',
  'admin-panel.module': 'Модуль',
  'admin-panel.period': 'Период',
  'admin-panel.finance': 'Финансовый отчет',
  'admin-panel.download-finance': 'Скачать отчет',

  // Terminal
  'edit-terminal': 'Терминалды өңдеу',
  'add-terminal': 'Терминалдарды қосу',
  'send-terminal': 'Жіберу',
  'status-terminal': 'Kүй',
  'command-terminal': 'Пәрмен',
  'text-command-terminal': 'Пәрмен мәтіні',
  'response-terminal': 'Жауап',

  // retranslators
  'edit-retranslators': 'Редактировать ретранслятор ',
  'add-retranslators': 'Добавить ретранслятор',
  // navigation

  'nav.users': 'Пайдаланушылар',
  'nav.objects': 'Объектілер',
  'nav.geozones': 'Геоаймақтар',
  'nav.notifications': 'Хабарлама',
  'nav.trips': 'Рейстер',
  'nav.mailing': 'Тарату',
  'nav.reports': 'Есептер',
  'nav.monitoring': 'Мониторинг',
  'nav.admin': 'Админ-панель',

  // MEASURE

  'measure.title': 'Қашықтық пен ауданды өлшеу',
  'measure.lastPoint': 'Соңғы нүкте',
  'measure.distance': 'Қашықтық',
  'measure.region': 'Облыс',
  'measure.cancel': 'Болдырмау',
  'measure.finish': 'Өлшеуді аяқтау',

  // Formula
  'formula.tooltip':
    'Формулада +, -, /, *, жақшалар арифметикалық амалдарын қолдануға болады) (,\n' +
    '$ N түріндегі нөмір бойынша тұрақтылар мен басқа бағандардың мәніне сілтемелер,\n' +
    'мұнда N - «Бағандар» қойындысындағы бағанның нөмірі,\n' +
    'бағанның мәнін оның көрінуіне қарамастан қолдануға болады',

  // CompanySETTINGS
  'company.name': 'Аты',
  'company.passwordLabel': 'Жетілдірілген қауіпсіздік режимі',
  'company.passwordLabelText1':
    'Қауіпсіздікті арттыру үшін бұл режимді белсендіру жаңа пайдаланушылардан күшті құпия сөздерді жасауды талап етеді. Бұл құпия сөз кемінде 15 таңбадан тұруы керек және үлкен және кіші әріптерді, сандарды және арнайы таңбаларды қамтуы керек.',
  'company.passwordLabelText2':
    'Құпия сөзді өзгерткен кезде пайдаланушы әкімші құпия сөзін пайдаланып жүйеге кіріп, содан кейін тіркелгі үшін өзінің құпия сөзін орнатуы керек. Осылайша, әкімші пайдаланушы тіркелгісінің құпия сөзін білмейді.',
  'company.timeZone': 'Сағаттық белдеу',
  'company.integrator': 'Дилер',
  'company.exitTime': 'N минуттық әрекетсіздіктен шығу',
  'company.tooltip': 'немесе бос өріс – әрекетсіздікке шығу болмайды',

  // 500 Page
  'mainPage.title': 'SKIF-ке хош келдіңіз!',
  'mainPage.sorry':
    'Өкінішке орай сіздің сілтемеңіз енді жұмыс істемейді. Операцияны қайталауға тырысыңыз',
  'mainPage.back': 'Басты бетке',

  // Map Layers
  'map.style': 'Стиль карты',
  'map.yandex': 'Яндекс Карты',
  'map.googleSatelite': 'Google Sattelite',
  'map.googleRoadMap': 'Google Roadmap',
  'map.googleTerrain': 'Google Terrain',
  'map.googleHybrid': 'Google Hybrid',
  'map.osm': 'OSM',
  'map.here': 'Here',
  'map.bing': 'Bing',
  'map.bingSatellite': 'Bing Satellite',

  // Hide from  Map
  'hide.events': 'Оқиғалар',
  'hide.events.text': 'Оқиғаларды картаға жасыру',
  // Hide from  Map

  hide: 'СТЕРЕТЬ',
  'hide.title': 'Видимость на карте',
  'hide.objects': 'Объекты',
  'hide.objects.text': 'Скрыть объекты на карте',
  'hide.tracks': 'Треки',
  'hide.tracks.text': 'Скрыть треки на карте',
  'hide.tracks.signatures': 'Скрыть подписи на треках',
  'hide.tracks.group': 'Топтастырудың ең үлкен қашықтығы',
  'hide.notifications': 'Хабарландырулар',
  'hide.notifications.text': 'Картадағы хабарландыруларды жасыру',
  'hide.geozones': 'Геозоны',
  'hide.geozones.text': 'Скрыть геозоны на карте',
  'hide.trips': 'Маршруты и Рейсы ',
  'hide.trips.text': 'Скрыть маршруты и рейсы',
  'hide.all': 'Все элементы',
  'hide.all.text': 'Скрыть все элементы',
  // billing
  'tariff.information': 'ИНФОРМАЦИЯ ПО ТАРИФУ',
  'tariff.current': 'Ваш текущий тариф:',
  'tariff.price': 'Стоимость тарифа:',
  'tariff.payement-date': 'Оплата:',
  'tariff.balance': 'На счету:',
  'tariff.units': 'объектов',
  'tariff.notselected': 'Не выбран',
  'tariff.currency-month': 'валюта/мес',
  'tariff.currency': 'вал',
  'tariff.company-blocked': 'компания заблокирована',
  'tariff.trial': 'Ознакомительный период:',
  'tariff.paid': 'Оплаченный период',
  'tariff.rouble': 'руб',
  'tariff.kz': 'KZT',

  'tariff.remaining': 'Осталось:',
  'tariff.next-payment': ' Следующее списание:',
  'tariff.payment-history': 'ИСТОРИЯ СПИСАНИЙ И ОПЛАТ',
  'tariff.pay': 'ОПЛАТИТЬ ТАРИФ ',
  'tariff.choose-pay': ' ВЫБРАТЬ И ОПЛАТИТЬ ТАРИФ ',

  'tariff.payments-history': 'История платежей',
  'tariff.date-time': 'Дата/Время',
  'tariff.operation': 'Операция ',
  'tariff.amount': 'Сумма на счёте',
  'tariff.currency-type': 'валюты',

  'tariff.choose': 'Выбор тарифного плана',
  'tariff.txt-one':
    'Использование системы мониторинга SKIF PRO является платным.',
  'tariff.txt-two': 'Вы можете выбрать Тариф необходимый для Вашей компании.',
  'tariff.txt-three': 'Первые 7 дней являются бесплатными.',
  'tariff.choose-units': ' Выберите нужное количество объектов:',
  'tariff.info-one': 'Стоимость 1 объекта в системе SKIF PRO составляет',
  'tariff.info-two--part-one': 'на',
  'tariff.info-two--part-two': 'календарных дней.',
  'tariff.tariffprice': 'Стоимость тарифа:',
  'tariff.payableto': 'к оплате:',
  'tariff.txt-four': 'Если Вы захотите уменьшить тариф, Вам будет',
  'tariff.txt-five': 'необходимо обратиться в поддержку.',
  'tariff.txt-six': ' Нажимая кнопку “Согласен” вы соглашаетесь с',
  'tariff.txt-seven': 'тарифом, правилами оплаты и',
  'tariff.offers': 'оферты.',
  'tariff.accept': 'Согласен',
  'tariff.blocked-info':
    'На счету Компании недостаточно средств. Доступ к компании заблокирован.',
  copy: 'IMEI скопирован!',
  // tariff ADMINKA
  'admintariff.newtariff': 'Новый тариф',
  'admintariff.oldtariff': 'Старый тариф',
  'admintariff.historyobjects': 'объектов',
  'admintariff.company': 'Компания',
  'admintariff.tariff': 'Тариф',
  'admintariff.date': 'Дата следующего платежа',
  'admintariff.for': 'за ',
  'admintariff.objects': 'объектов каждые',
  'admintariff.days': 'дней',
  'admintariff.refill': 'Пополнить',
  'tariff.amounttopay': 'Сумма',

  'admintariff.changetitle': 'Изменение тарифа',
  'admintariff.cancel': 'Отменить',
  'admintariff.accept': 'Подтвердить',
  'admintariff.object': 'объекта',
  'admintariff.objectsnumber': 'Количество объектов',
  'admintariff.price': 'Стоимость одного объекта',
  'admintariff.period': 'Период оплаты',
  'admintariff.paymentdate': 'Дата платежа',
  // Другое / Басқа
  'company.retransmit': 'Ретранслировать в ЕФИС',
  'company.date-format': 'Формат даты',
  'company.time-format': 'Формат времени',
  search: 'Іздеу',
  error: 'Қате',
  loading: 'Жүктеу',
  close: 'Жабу',
  send: 'Жөнелту',
  phone: 'Телефон',
  type: 'Типі',
  add: 'Қосу',
  cancel: 'Болдырмау',
  value: 'Мәні',
  role: 'Ролі',
  email: 'Пошта',
  time: 'Уақыт',
  author: 'Автор',
  events: 'Оқиға',
  action: 'Әрекет',
  code: 'Код',
  details: 'Қосымша',
  description: 'Сипаттама',
  create: 'Жасау',
  change: 'Өзгерту',
  yes: 'Иә',
  no: 'Жоқ',
  'setting-company': 'Компания баптаулары',
  companies: 'Компаниялар',
  blocking: 'Блоктау',
  'setting-user': 'Настройки пользователя/ Пайдаланушы баптаулары',

  // add-company admin panel
  addCompany: 'Компания қосу',

  // добавление параметров из отчета
  add_parametrs: {
    add_parametrs: 'Параметрлерді қосу',
    no_accepted: 'Қабылданбады',
    accepted: 'Қабылданды',
    protocol: 'Протокол',
    parametrs_available: 'Қол жетімді параметiрлер',
    support: 'Келесі  параметiрлер сәтті қосылды:',
    parametrs_time:
      'Параметры в скором времени будут добавлены (мы оповестим вас при помощи SMS или по Email)',
    send_parametrs: 'Қосу үшін параметрлерді жіберу',
    good: 'Жақсы'
  },
  // попап tools
  tools: {
    admin_panel_v2: 'Админ. панель 2.0',
    admin_panel_beta: 'βeta тест'
  },
  // геозоны
  geozones_lang: {
    geozones_enter: 'Геоаймақтарға кіру',
    geozones_exit: 'Геоаймақтардан шығу'
  },
  // уведомления
  notifications: {
    text: {
      confirmation:
        'Электрондық поштасын растамаған пайдаланушылар осыны сұрайтын электрондық хат алады. Растаудан кейін олар электрондық пошта ақпараттық бюллетеньдерін ала бастайды.',
      events_routes: 'Ұшу оқиғасы'
    }
  },
  // попап быстрый трек
  fast_track: {
    close: 'Жылдам жолды жабу'
  },
  // формула
  formula: {
    add: 'Добавить',
    extra_fields_sensors: 'Қосымша кеңейтілген жолақтар мен сенсорларды қосу',
    errors: {
      name: 'Атауын енгізіңіз',
      formula: 'Формуланы енгізіңіз'
    }
  },
  // Подсказки
  notification_alert: {
    success: 'Оқиға сәтті сақталды',
    error_points: 'Ұпайлар жоқ!'
  },
  // Иконки в мониторинге
  content: {
    object_stay: 'Нысан құнды',
    object_move: 'Қозғалыстағы зат',
    object_last_state_move: 'Соңғы күй: қозғалыстағы объект',
    object_last_state_stay: 'Соңғы күй: нысан тұр'
  },
  // Помощник
  helper: {
    description_text:
      'Көрсетілім режимінде сіз басқару тақтасына және нысандарды (нысандар, геоқоршаулар, т.б.) жасау/өңдеу мүмкіндігіне қол жеткізе алмайсыз.',
    objects:
      'Нысандарды жасау және өңдеу. Датчиктерді, техникалық қызмет көрсетуді, ауысымдарды, тіркемелерді және т.б. қосу',
    video: 'Бейне: «Нысан жасау»',
    content_text: 'Сенсорлармен жұмыс істеу үшін:',
    content_text_list: {
      one: '1. «Объектілер» модулін ашыңыз',
      two: '2. Тізімнен атауды басу арқылы нысанды таңдаңыз',
      three: '3. Жоғарғы сол жақ бұрышта «Сенсорлар» тармағын таңдаңыз.',
      four: 'Сіз нысандағы сенсорлардың тізімін көресіз. Тізім бос болса, түймешігін басу арқылы сенсорларды өзіңіз қосуға болады',
      five: 'Сенсорда сіз параметрлерді таңдап, олардың калибрлеуін реттей аласыз, бұл үшін басыңыз',
      six: 'Параметрлерге арналған стандартты формулаларға қоса, өңделмеген сенсор деректеріне қол жеткізу мүмкіндігімен өзіңіздің өрнектеріңізді жасай аласыз',
      seven: 'Ол үшін «Формула» өрісінде «Өрнек» соңғы элементін таңдаңыз'
    },
    video_add_sensor: 'Бейне: "Сенсорларды қосу',
    reports: {
      text: 'Нысан немесе нысандар тобы туралы есеп жасаңыз. 25 есептің ішінен таңдаңыз немесе есеп үлгісі өңдегішін пайдаланып өз есептерді жасаңыз.',
      video_base_reports: 'Бейне: Негізгі есептер',
      video_create_template: 'Бейне: "Үлгілерді жасау"'
    },
    monitoring: {
      text_one: 'Көліктің ағымдағы күйін қадағалаңыз.',
      text_two:
        'Нысандағы деректер қанша уақыт бұрын келгенін жылдам түсіну үшін біз түс көрсеткішін қостық:',
      colors: {
        one: '— Деректер 15 минуттан аз уақыт бұрын келді',
        two: '- Деректер 30 минут бұрын келді',
        three: '- Деректер 30 минуттан астам уақыт бұрын келді'
      },
      statuses: {
        text: 'Нысанның 4 күйінің біреуі болуы мүмкін:',
        one: '— Нысан стендтері',
        two: '— Нысан тұтану қосылып тұр',
        three: '- Объект қозғалыста',
        four: '- Нысан қозғалыста'
      },
      text_three:
        'Сонымен қатар бақылауда сіз нысанға қатысты жылдам әрекеттерге қол жеткізе аласыз. Мысалы, сіз «Жылдам трек» (бір күнге, аптаға немесе айға арналған трек) құра аласыз.'
    },
    admin: {
      text: 'Әкімші панеліне кіру үшін интегратор ретінде тіркелу керек немесе біздің маманнан платформаның демонстрациясын сұрау керек.'
    },
    footer: {
      account: 'Тіркелгіні тіркеу',
      get_free: '(30 күндік тегін сынақ нұсқасын алыңыз)'
    },
    list: {
      one: {
        title: 'Нысандар',
        content:
          'Нысандарды жасау және өңдеу. Датчиктерді, техникалық қызмет көрсетуді, ауысымдарды, тіркемелерді және т.б. қосу',
        link: 'Бейне: "Нысан жасау"'
      },
      two: {
        title: 'Сенсорлар',
        content:
          'Сенсорлармен жұмыс істеу үшін: 1. «Объектілер» модулін ашыңыз. 2. Тізімнен атауды басу арқылы нысанды таңдаңыз. 3. Жоғарғы сол жақ бұрыштағы "Сенсорлар" тармағын таңдаңыз.'
      },
      three: {
        title: 'Есептер'
      },
      four: {
        title: 'Мониторинг'
      },
      five: {
        title: 'Әкімші тақтасы'
      }
    }
  },
  // кабинет дилера
  lkDiler: {
    create_company: 'Компанияларды құру',
    title: 'Дилердің жеке шоты',
    change_tariff: 'Тарифтік өзгерістер',
    create_terminal: 'Терминалдарды орнату',
    template_command: 'Пәрмен үлгілері',
    admin_panel: {
      step_1:
        'Әкімші панелінде компания құру үшін «Компаниялар» модулінде «Компания қосу» түймесін басыңыз:',
      step_2:
        'Компанияны құрудың ашылған пішінінде сіз компанияның атын толтырып, уақыт белдеуін таңдап, қажет болған жағдайда 1 немесе одан да көп терминалдарды қосып, «Сақтау» түймесін басуыңыз керек. Таңдалған компанияға ауысу опциясын таңдалған қалдырыңыз. Сіз автоматты түрде жаңадан құрылған компанияға өтесіз',
      step_3:
        'Таңдалған компанияға ауысу таңдалған күйде қалдырсаңыз, сізді автоматты түрде жаңадан құрылған компанияға апарады.'
    },
    register_link: {
      step_1:
        'Шақыру сілтемесін жіберу үшін компанияларыңыздың тізімінде «Шақыру» түймесін басуыңыз керек',
      step_2:
        'Ашылған өріске хаттағы сілтемені басу арқылы шақыру алатын Компания қызметкерінің электрондық пошта мекенжайын енгізу керек, ол жаңа Компанияның тіркеу бетіне өтеді. Сілтемені алған қызметкер құрылған компанияның әкімшісі болады'
    },
    change_step: {
      step_1:
        'Әдепкі бойынша, SKIF.PRO жаңадан құрылған компанияларда бір нысанға 250 рубль тарифін және клиенттің SKIF EPP процессингтік орталығы арқылы қызмет ақысын төлеу мүмкіндігін пайдаланады. Процессингтік орталықты өшіру және өзіңіздің тарифтік схемаңызды пайдалану үшін әкімші панелінде осы компания үшін тарифті 0 рубльге орнату жеткілікті. 0 нысан үшін. Тарифтерге қол жеткізу үшін Әкімші панелінің модульдер тізімінде «Тарифтер» тармағын таңдаңыз',
      step_2: 'Тізімнен қажетті компанияны таңдап, «Көрсету» түймесін басыңыз',
      step_3:
        'Тарифі бар жолды нұқыңыз, объектілердің санын 0 және бір нысанның құнын 0 таңдаңыз, растауды басыңыз'
    },
    create_company_step: {
      title: 'Құрылыс компаниялары',
      create: 'Әкімші панелінде компания құру',
      register: 'Шақыру сілтемесі арқылы тіркелу'
    },
    start_terminal: {
      create_terminal: 'Терминалдарды орнату',
      step_1:
        'Терминалдар қосу үшін Әкімші панеліндегі терминалдар модуліне өтіп, «+» түймесін басу керек',
      step_2: 'Одан кейін сізге қажет:',
      step_3:
        '1. Терминалдарды пайдалануға рұқсат бергіңіз келетін компаниялар тізімінен компанияны таңдаңыз.',
      step_4:
        '2. Қосқыңыз келетін бір немесе бірнеше imei терминалдарын енгізіңіз. Жаңа жолмен бөлінген imei тізімін дереу енгізу ыңғайлы.',
      step_5:
        '3. Терминалдар құпия сөз арқылы қосылған болса, оны осында көрсетуге болады.',
      step_6: '4. Сақтау түймесін басыңыз.',
      template: {
        title: 'Терминал пәрмен үлгілері',
        step_1:
          'Дилердің жеке кабинетінде сіз терминалдар үшін пәрмен үлгілерін жасай аласыз, ол үшін Басқару тақтасына өтіп, «Командалық анықтама» модулін таңдаңыз',
        step_2:
          'Пәрменді қосу үшін «+» түймесін басыңыз, ашылған терезеде терминал түрін таңдаңыз, пәрменнің атын таңдаңыз немесе толтырыңыз және «Пәрмен мәтіні» өрісіне пәрменнің өзін енгізіңіз, сақтау түймесін басыңыз'
      }
    }
  },
  // кабинет компании
  work_company: {
    title: 'Компанияның жұмыс орны',
    data: 'Терминалдан алынған деректер',
    create_object: 'Нысандарды жасау',
    short_list: {
      action_one:
        'Нысандарды жасау үшін компанияларыңыздың біріне бару керек. Ол үшін пайдаланушы аты мен компания көрсетілген түймені басыңыз, содан кейін тізімнен қажетті компанияны таңдаңыз',
      action_two:
        'Кез келген компаниядан Басқару тақтасына өту үшін пайдаланушы аты бар түймені басыңыз, содан кейін «Әкімші тақтасы»Таңдалған компанияда нысандар каталогына өтіңіз',
      action_three: 'Қосу түймесін басыңыз',
      action_four:
        'Ашылған пішінде барлық өрістерді толтырып, нысан үшін суретті таңдаңыз',
      action_five:
        'Ескерту: Қажетті терминал түрін таңдағаннан кейін серверге деректерді жіберу үшін терминалдарды конфигурациялау қажет сервер мен портты көрсететін кеңеске назар аударыңыз',
      action_six:
        'Нысанға сенсор қосу үшін жоғарғы ашылмалы тізімнен «Сенсорлар» тармағын таңдаңыз',
      action_seven:
        'Содан кейін «+» түймесін басу керек, сенсорды қосу терезесі ашылады, онда барлық өрістерді толтыру, параметрлер мен формулаларды таңдау керек',
      action_eight:
        'Параметрлерді таңдаған кезде оларды конфигурациялауға болады, бұл үшін қажетті параметрдің жанындағы берілістерді басып, қажетті мәндерді енгізіп, сақтау түймесін басыңыз',
      action_nine:
        'Сенсорды қосқаннан және сақтағаннан кейін нысанды жасау түймесін басыңыз'
    },
    data_terminal: {
      step_1:
        'Терминалдардағы өңделмеген деректерді Журнал есебінде көруге болады. «Журнал» есебін алу үшін Есептер модуліне өтіңіз',
      step_2: 'Одан кейін ашылған терезеде нысанды, нүктені таңдау керек',
      ste_3:
        'Журнал есебінде параметрлерді және басқа сенсорларды қосуға болады. Ескерту: Журнал нысандар тобы үшін қолжетімді емес'
    }
  },
  // измерение площади библиотека
  measure: {
    title: 'Ауданды өлшеу',
    square: 'Шаршы',
    perimetr: 'Периметр',
    clear: 'Анық'
  },
  // Админ панель - добавление компании
  addCompanyTransalter: {
    description: 'Жаңа жолға орау кезінде бірнеше терминалдарды қосу.',
    selectCompany: 'Таңдалған компанияға ауысу  ',
    addUser: 'ПАЙДАЛАНУШЫ ҚОСУ',
    cancel: 'CANCEL',
    save: 'Сохранить',
    password: 'Пароль:',
    repeatPassword: 'Құпия сөзді растау:',
    error: {
      name: 'Компания атауы" өрісін толтырыңыз',
      time: 'Уақыт белдеуі» өрісін толтырыңыз',
      password: 'Құпия сөздер сәйкес келмейді',
      enterName: 'Пайдаланушы атын енгізіңіз',
      enterEmail: 'Жарамды электрондық пошта мекенжайын енгізіңіз',
      enterPassword: 'Құпия сөзді енгізіңіз',
      repeatPassword: 'Құпия сөздер сәйкес болуы керек'
    }
  },
  templateCommandTranslater: {
    add: 'Пәрмен үлгісін қосу',
    edit: 'Пәрмен үлгісін өңдеу',
    typeTermial: 'Терминал түрі',
    selectTypeTerminal: 'Терминал түрін таңдау',
    nameCommand: 'Пәрмен аты',
    selectCommand: 'ПАРМАНЫ ТАҢДАУ',
    enterNameCommand: 'Пәрмен атауын енгізіңіз',
    textCommand: 'Пәрмен мәтіні',
    enterTextCommand: 'Пәрмен мәтінін енгізіңіз',
    parametrsCommand: 'Пәрмен опциялары'
  },
  companiesListTranslater: {
    copyObject: 'Нысанды көшіру',
    selectCompany: 'Қосылғыңыз келетін компанияны таңдаңыз',
    copiedObject: 'объектіні көшіру',
    cancel: 'Болдырмау',
    save: 'Сақтау'
  },
  createPayment: {
    title: 'Төлем жасау',
    company: 'Компания',
    amount: 'Төлем сомасы',
    numberPayment: '№ нөмірі',
    createPayment: 'Төлем жасау',
    upBalance: 'Бұл операция таңдалған компанияның балансын арттырады',
    error: {
      createPayment: 'Төлем жасалды',
      error: 'Қате',
      enterCompany: 'Компания» өрісін толтырыңыз',
      enterAmount: 'Төлем сомасы өрісін толтырыңыз'
    }
  },
  editTarifTranslater: {
    dateStart: 'Тарифтің басталу күні:',
    type: 'Тіркелгі түрі',
    info: 'Төлем кезеңіндегі төлем сомасы формула бойынша қалыптастырылады: Макс. объектілер саны 1 объектінің құнына көбейтілген.',
    info_2:
      'Төлем кезеңі үшін төлем сомасы мына формула бойынша қалыптасады: Кезеңге деректерді жіберген объектілер саны 1 объектінің құнына көбейтілген',
    periodStart: 'период басы',
    max: 'Макс. объектілер саны',
    amountPer1Object: '1 нысанның құны',
    nextPayment: 'Келесі тарифтік төлем:',
    amount: 'Сома:',
    dateNextPayment:
      'Келесі төлем күні өткен болуы мүмкін емес. Төлемнің басталу күнін өзгертіңіз немесе төлем мерзімін арттырыңыз.',
    changePeriod:
      'Сіз төлем мерзімін өзгерттіңіз, сондықтан келесі төлем күні өзгерді',
    setStartTarif:
      'Сіз тарифтің басталуын болашақ күнге белгіледіңіз, осыған байланысты компания сынақ режимінде болады',
    setBeforeDate:
      'Сіз тарифтің басталуын өткен күнге белгіледіңіз, осыған байланысты келесі төлем күні өзгерді'
  },
  feedbackTranslater: {
    addSomeTerminals: 'Жаңа жолға орау кезінде бірнеше терминалдарды қосу.',
    selectCompany: 'Таңдалған компанияға ауысу',
    save: 'Сақтау',
    error: {
      name: 'Компания атауы өрісін толтырыңыз',
      time: 'Уақыт белдеуі өрісін толтырыңыз'
    }
  },
  copyReportTemplateTranslater: {
    title: 'Үлгі көшірмесі',
    selectCompany: 'Қосылғыңыз келетін компанияны таңдаңыз',
    copy: ' үлгіні көшіру',
    cancel: 'Болдырмау',
    save: 'Сақтау'
  },
  importWialonTranslater: {
    title: 'Виалоннан импорт',
    fileObject: 'Walon нысандары бар файлды таңдаңыз',
    inCompany: 'Компанияға',
    selectFile: 'Файлды таңдау',
    typeFiles: '.wlp, .xml немесе осындай файлдардың zip мұрағаты',
    next: 'Келесі',
    findEquipment:
      'Келесі құрылғылар анықталды, олар үшін протоколдарды таңдаңыз:',
    selectProtocol: 'Протоколды таңдау',
    back: 'Артқа',
    findedParametrs:
      'Сенсорларда таңдалған келесі параметрлерді таптыңыз, олардың сәйкестігін тексеріңіз. Сіз оларды болашақта өзгерте аласыз.',
    import: 'Импорт'
  },
  integratorsTranslater: {
    addParameter: 'Интеграторды қосу ',
    editParameter: 'Интеграторды өңдеу',
    name: 'Аты',
    enterName: 'Атын енгізіңіз',
    INN: 'ИНН',
    fieldMiss: 'Өрісті толтырыңыз',
    numberCallCenter: 'Байланыс орталығының нөмірі',
    contactPerson: 'Байланыстағы тұлға',
    email: 'Электрондық пошта',
    typeOrganization: 'Ұйым түрі',
    password: 'Пароль',
    smsTariff: 'SMS тарифі',
    paymentInforamion: 'Төлем туралы ақпарат',
    paySystem: 'Төлем жүйесі',
    idShop: 'Дүкен идентификаторы',
    secretKey: 'құпия кілт',
    change: 'Өзгерту',
    delete: 'жою',
    ofertaLoaded: 'Ұсыныс жүктелді',
    moveOferta: 'Ұсынысты осында орналастырыңыз немесе таңдау үшін басыңыз',
    documentMobileApp:
      'Ұялы қолданбаны пайдалану келісімі тұтынушыларға көрсетіледі',
    tariffDefault: 'Жаңа компаниялар үшін әдепкі тариф',
    typeAccount: 'Тіркелгі түрі',
    priceObject: 'Нысан үшін баға',
    periodPayment: 'Төлем мерзімі (күндер)',
    tryPeriod: 'Сынақ кезеңі (күндер)',
    tariffDefaultQR: 'QR тіркеулері үшін әдепкі тариф',
    cancel: 'Болдырмау',
    fileSize: 'Файл өлшемі 10 МБ-тан үлкен болмауы керек',
    address: 'Укажите адрес',
    services: 'Укажите описание услуг',
    tariffDescription: 'Укажите писание тарифа',
    enter: 'Введите'
  },
  inviteUserTranslater: {
    invite: 'Шақыру',
    next: 'Келесі',
    enterNumber:
      'Телефон нөмірін +XXXXXXXXXX немесе XXXXXXXXXX пішімінде енгізіңіз',
    back: 'Артқа',
    register: 'Тіркелу',
    passwordMustBe: 'Құпия сөз 5 таңбадан көп болуы керек',
    wrongNumber: 'Телефон нөмірі жарамсыз'
  },
  objecrRecoveryTranslater: {
    copyObject: 'Нысанды көшіру',
    restore: 'Сіз шынымен қалпына келтіргіңіз келе ме?',
    objectCompany: ' компаниядағы нысан',
    restoring: 'Қалпына келтіру',
    success: 'нысан сәтті қалпына келтірілді'
  },
  qrAdminTranslater: {
    qrCreate: 'QR кодын жасау',
    register: 'Тіркелу және ',
    addGps: 'GPS трекерін қосу',
    print: 'Басып шығару',
    scanCode: `GPS трекерін тіркеу үшін кодты сканерлеңіз немесе ${currentUrl}c сайтында кодты енгізіңіз`
  },
  retranslatorTranlsater: {
    changeIMEI: 'IMEI-ге өзгерту',
    protocol: 'Протокол',
    serverRetranslation: 'Relay Server',
    participant: 'Программа қатысушының белгісі',
    route_identifier: 'Маршрут белгісі',
    gps_signal_category: 'GPS сигналының категориясы',
    hint: 'Жолаушылар қоғамдық транспорттың көлемі үшін қолданылады',
    type_vehicle: 'Қоғамдық транспорттың түрі',
    port: 'Релелік порт',
    retranslationFrom: 'қайта аудару',
    retranslationTo: 'қайта аудару'
  },
  adminPanelTranlsater: {
    importWialon: 'Виалоннан импорттау',
    importKML: 'KML ішінен импорттау',
    invite: 'Шақыру',
    object: 'Нысан',
    name: 'Аты',
    phone: 'Телефон',
    typeTerminal: 'Терминал түрі',
    command: 'Бұйрық',
    user: 'User',
    grade: 'Баға',
    type: 'Түрі',
    message: 'хабарлама',
    company: 'Компания',
    total: 'сома',
    date: 'Күні',
    numberType: '№ Түр',
    period: 'кезең',
    protocol: 'Протокол',
    addCompany: 'Компания қосу',
    copyObject: 'Нысанды көшіру',
    copyTemplate: 'Үлгіні көшіру',
    recoveryObject: 'Нысанды қалпына келтіру',
    cashType: {
      deposit: 'қолмен',
      card: 'картадан',
      withdrawal: 'тарифтік төлем',
      trial: 'сот'
    },
    enterEmail: 'электрондық поштаны енгізіңіз',
    wrongEmail: 'Қате электрондық пошта',
    notData: 'Дерек жоқ',
    acceptDeleteTemplate: 'Үлгіні шынымен жойғыңыз келе ме ?',
    delete: 'жою',
    cancel: 'Болдырмау'
  },
  demoTourTranslater: {
    title: 'Сіздің негізгі құралыңыз – карта',
    text: 'Біз пайдаланушыларға нысандардың анық және толық көрінісін беруге тырысамыз, сондықтан біздің қолданба картамен өзара әрекеттесуге үлкен мән береді, өйткені ол негізгі визуализация құралы болып табылады.',
    next: 'Келесі',
    video: 'Интерфейс бейне',
    watchVideoYouTube: 'YouTube кіріспе бейнесін қараңыз',
    interface: 'интерфейстік бейнеге шолу',
    modules: 'Модульдер',
    search: 'Іздеу, хабарландырулар және параметрлер',
    workMap: 'Картамен жұмыс',
    link: 'Әзірлеушілермен байланысу'
  },
  helperTranslater: {
    helper: 'Көмекші'
  },
  addLocatorTranslate: {
    selectObject: 'Select object',
    selectDateFrom: 'Select start date',
    selectDateTo: 'Select end date'
  },
  dealerTranslater: {
    register: 'Жаңа дилерлік тіркеу',
    typeOrganization: 'Ұйым түрі',
    nameCompany: 'Компанияның атауы*',
    enterNameCompany: 'Компанияның атын енгізіңіз',
    INN: 'INN*',
    enterINN: 'INN енгізіңіз',
    enterPhone: 'Телефон нөміріңізді енгізіңіз',
    next: 'Келесі',
    registerNewDealer: 'Жаңа дилерлік тіркеу',
    email: 'Электрондық пошта*',
    enterEmail: 'Электрондық поштаңызды енгізіңіз',
    surname: 'Толық аты*',
    enterSurname: 'Атыңызды толық аты',
    password: 'Пароль*',
    enterPassword: 'Құпия сөзді енгізіңіз',
    rewritePassword: 'Құпия сөзді растау*',
    enterCodeFromImage: 'Тіркеуді аяқтау үшін суреттегі кодты енгізіңіз:',
    back: 'Артқа',
    goRegister: 'Тіркелу',
    address: 'Адрес',
    services: 'Услуги',
    tariff_description: 'Tariff description',
    confirmHeader: 'Жаңа интеграторды тіркеу аяқталды',
    confirmMsg: 'Жақын арада сізге қолдау телеграмм чатына сілтемесі бар электрондық хат келеді; чатта олар сіздің барлық сұрақтарыңызға жауап береді және басқа платформадан иммиграцияға көмектеседі.',
    confirmMaterials: 'Қосымша материалдар:',
    confirmFastStart: 'Жылдам бастау',
    confirmDescBill: 'Тарифтің сипаттамасы',
    confirmDescAdmin: 'Басқару панелінің сипаттамасы',
    confirmDescUi: 'Интерфейс сипаттамасы',
    confirmContacts: 'Байланыстар:',
    confirmGoToApp: 'Қолданбаға өтіңіз',
    confirmApp: 'қолдану',
    confirmDownload: 'Жүктеп алу'
  },
  unitsVisiblityTranslater: {
    filterFormMonitoring: 'Мониторингтен сүзу қолданылды.',
    notShowAllObject: 'Барлық нысандар көрсетілмейді.',
    showAllObject: 'Барлық нысандарды көрсету'
  },
  monitoringListTranslater: {
    withoutgroup: 'Топсыз'
  },
  annotationCameraTranslater: {
    video: 'Бейне',
    object: 'Нысан:',
    videoRegister: 'Бейне жазу құрылғысы:',
    grid: 'Тор',
    ribbon: 'Лента',
    see: 'Көру'
  },
  sensorNotificationTranslater: {
    inFrame: 'Жақтауда',
    outFrame: 'Жақтаудан тыс'
  },
  new_units: {
    objects: {
      fields: {
        sensors: {
          value_to_sensor: 'Мәндерді мәтінге түрлендіру:',
          valut_to_sensor_text:
            'Сенсордың сандық мәнін мәтінге түрлендіру. Мұны істеу үшін олар үшін мәндер мен мәтіндік пернелерді көрсетіңіз. Мәндер ауқымымен жұмыс істеу үшін кодтаушының сызықтық түрлендіруін пайдаланыңыз.',
          value: 'Мағынасы',
          output_text: 'Mәтiндi шығару',
          multiple_deletion_mode: 'Сіз датчиктіктік жойылу режимінде боласыз',
          fields: {
            technical_params: {
              title: 'Техникалық сипаттамалар',
              add_params: 'Технологияны қосыңыз. параметр',
              show_annotation: 'Нысанның аннотациясында параметрді көрсету.'
            },
            parameters: {
              tabs: {
                filter_data: 'Фильтрация данных'
              },
              switch: {
                hint: 'Мониторингте көрсету',
                show_annotation: 'Аннотацияны көрсету',
                not_show_annotation: 'Аннотацияны көрсетпеңіз'
              }
            }
          }
        }
      }
    }
  },
  reportEdit: {
    sensor: {
      addTotalValue: 'Есепке жиынтықты қосыңыз:',
      hintText:
        'Жасалған есепте осы бағанның жалпы жолына нәтиже мәнін қосыңыз.'
    }
  },
  reportsTranslater: {
    noData: 'Есепті құру үшін деректер жоқ',
    rewrite: 'Қайта сұрау'
  },
  settings: {
    main: {
      exit: 'Шығу',
      company: 'Компания:',
      go_admin_panel: 'Әкімшіге өтіңіз. Панель',
      go_back: 'Оралу',
      go_admin_panel_beta: 'Басқару панелі',
      company_settings: 'Компания параметрлері',
      finance_and_balance: 'Қаржы және баланс:',
      company_tariff: 'Компания тарифі',
      add_balance: 'Балансты толтыру',
      payments_history: 'Төлемдер тарихы',
      financical_report: 'Қаржылық есеп',
      work_with_company: 'Компаниямен жұмыс:',
      invite_user: 'Пайдаланушыны шақыру',
      display_settings: 'Дисплей параметрлері',
      sessions: 'Сессиялар',
      additional: 'Қосымша:',
      news_and_updates: 'Жаңалықтар мен жаңартулар',
      reference: 'Анықтама (Wiki)',
      support: 'Қолдау'
    },
    user: {
      edit: 'Өңдеу',
      user: 'Пайдаланушы:',
      access_level: 'Қол жеткізу деңгейі:',
      name: 'Аты:',
      email: 'Email:',
      language: 'Тіл:',
      phone: 'Телефон:',
      code: 'Код:',
      mailing_id: 'Telegram үшін жеке куәлік:',
      note: {
        first: '1) Кіріңіз телеграм-бот:t.me/skif2_bot',
        second:
          '2) Бастау түймесін басыңыз, бот Telegram үшін тарату идентификаторын қайтарады'
      },
      description: 'Сипаттама:',
      set_password: 'Құпия сөзді орнату',
      change_password: 'Құпия сөзді өзгерту',
      change_access_level: 'Кіру деңгейін өзгерту',
      success: {
        update_info: 'Ақпарат сәтті жаңартылды',
        avatar_change: 'Аватар сәтті жаңартылды',
        change_password: 'Құпия сөз сәтті өзгертілді'
      },
      errors: {
        name: 'Атын енгізіңіз',
        email: 'Email енгізіңіз',
        phone_confirmation: 'Телефон расталмаған.',
        admin_strong_pass:
          "Құпия сөзде 15-25 таңба болуы керек және кем дегенде бір бас ағылшын әрпінен, кем дегенде бір кіші ағылшын әрпінен және кем дегенде бір арнайы таңбадан (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' (\") , < . > / ?)",
        user_strong_pass:
          "Құпия сөзде 8-ден 25-ке дейін таңба болуы керек және кем дегенде бір бас ағылшын әрпінен, кем дегенде бір кіші ағылшын әрпінен және кем дегенде бір арнайы таңбадан (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' (\") , < . > / ?)",
        simple_pass:
          'Құпия сөз 5-тен 25 таңбаға дейін болуы керек және кем дегенде бір ағылшын әрпінен және кем дегенде бір саннан тұруы керек'
      }
    },
    display: {
      title: 'Дисплей параметрлері',
      map: 'Карта',
      hide_events: 'Картадағы оқиғаларды жасыру:',
      monitoring: 'Бақылау',
      group_by_dots: 'Егер қашықтық аз болса, топтау (экран нүктелері):',
      object_grouping: 'Картадағы объектілерді топтастыру:',
      show_annotation:
        'Мониторинг тізіміндегі нысанды басқан кезде аннотацияны көрсету:',
      notification: 'Хабарландырулар',
      hide_notification: 'Картадағы хабарландыруларды жасыру:',
      geozones: 'ГЕОҚОРШАУ',
      show_geozones: 'Картада геоқоршауды көрсету:',
      meter: 'м',
      kilometer: 'км',
      hectar: 'га',
      area_measurement: 'Аудан бірліктері:',
      track_weight: 'Картада тректің биігі:'
    },
    company: {
      title: 'Компания параметрлері',
      name: 'Атауы:',
      time_zone: 'Уақыт белдеуі:',
      integrator: 'Интегратор:',
      exit_time: 'N минуттық әрекетсіздіктен',
      exit_time_tooltip: 'шығу (0 немесе бос өріс-әрекетсіздік)',
      high_security_mode: 'Жоғары қауіпсіздік режимі',
      high_security_mode_hint: {
        first:
          'Қауіпсіздікті жақсарту үшін, осы режимді іске қосқан кезде жаңа пайдаланушыларға күшті парольдер жасау қажет болады. Мұндай парольде кемінде 8 таңба болуы керек (әкімшілер үшін кемінде 15 таңба), бас және кіші әріптер, сандар және арнайы таңбалар болуы керек.',
        second:
          'Құпия сөзді өзгерткен кезде пайдаланушы әкімшінің құпия сөзін пайдаланып кіріп, содан кейін тіркелгі үшін жеке құпия сөзді орнатуы керек. Осылайша, әкімші пайдаланушы тіркелгісінен құпия сөзді білмейді.'
      },
      retransmit_efis: 'ЭФИСКЕ қайта жіберу',
      data_format: 'Күн пішімі:',
      time_format: 'Уақыт форматы:',
      last_widget_time: 'Виджеттегі соңғы күн уақыты:',
      note: 'Ескерту: Күндер аралығындағы соңғы күнді таңдағанда қандай уақыт форматы қолданылатынын көрсетіңіз (күндерді таңдау виджетінде).',
      success: {
        update_company: 'Компания параметрлері сәтті жаңартылды'
      }
    },
    tariff: {
      title: 'Тариф туралы ақпарат',
      on_balance: 'Шотта (баланс):',
      accounting_type: 'Есеп түрі:',
      object_count: 'Макс. объектілер саны:',
      object_price: '1 нысанның құны:',
      period_start: 'Ағымдағы кезеңнің басталуы:',
      payment_period: 'Төлем мерзімі:',
      autopay: 'Автотөлем:',
      card: 'Карта:',
      no_card_added: 'Карта қосылмаған',
      turn_on_autopay: 'Автотөлемді қосу',
      next_write_off: 'Келесі есептен шығару:',
      last_period_sum: 'Өткен кезеңдегі сома',
      active_autopay: 'Автоматты төлем іске қосылды',
      by_number_objects: 'Нысандар саны бойынша',
      by_number_objects_sent_data:
        'Деректерді жіберген объектілер саны бойынша',
      card_section: {
        add: 'Карта қосу',
        change: 'Автотөлем картасын өзгерту',
        add_note:
          'Автотөлем картасын қосу үшін біз сізден 1 рубльді есептен шығаруымыз керек. Төлем кезінде карта деректемелерін көрсетіңіз.',
        select_system: '*Төлем жүйесін таңдаңыз:',
        visa_master: 'Visa немесе Mastercard',
        mir_system: 'Жүйе әлемі',
        temporarily_unavailable: 'Уақытша қол жетімді емес',
        close: 'Жабу'
      },
      errors: {
        mir_error: 'Автотөлем әлем карталарымен уақытша қол жетімді емес'
      },
      success: {
        update_autopay: 'Автотөлем сәтті жаңартылды',
        delete_card: 'Карта сәтті жойылды'
      }
    },
    balance: {
      top_up_title: 'Толтыру',
      company: 'Компания:',
      top_up_amount: 'Толтыру сомасы',
      min_amount: 'Ең төменгі төлем сомасы',
      currency: 'рубль',
      save_card: 'Картаны сақтау',
      turn_on_autopay: 'Автотөлемді қосу',
      top_up: 'Толтыру',
      mir_unavailable: 'Әлем карталарын сақтау үшін уақытша қол жетімді емес',
      errors: {
        amount: 'Толтыру сомасын енгізіңіз'
      }
    },
    invite: {
      title: 'Пайдаланушыны шақыру',
      user_email: 'Пайдаланушы электрондық поштасы:',
      user_email_placeholder: 'Электрондық поштаны көрсетіңіз',
      access_level: 'Қол жеткізу деңгейі:',
      submit_button: 'Шақыру',
      errors: {
        user_email: 'Email енгізіңіз',
        access_level: 'Кіру деңгейін таңдаңыз'
      },
      success: {
        invite: 'Пайдаланушы сәтті шақырылды'
      }
    },
    support: {
      title: 'Қолдау',
      description:
        'Қайырлы күн! Қызметкерлер Мониторинг плюс (Мәскеу), сіздің сұрақтарыңызға жауап беруге қуанышты болады. Бізбен байланысу үшін төмендегі контактілердің біріне хабарласыңыз:',
      contacts: {
        telegram: 'Telegram: @Monitoring_plus',
        email: 'E-mail: monitoringplus@support.ru',
        phone:
          'Телефон (тек жұмыс күндері 9:00-ден 18:00-ге дейін): 89008887712'
      }
    }
  },
  // Редактирование шаблона отчета - фильтрация интервалов
  reportsFilter: {
    title: 'Сүзу',
    filterByTime: 'Уақыт аралықтары бойынша сүзу:',
    filterHintSelectInterval:
      'Есептегі деректер сүзілетін уақыт аралығын таңдаңыз.',
    addInterval: 'Аралықты қосу',
    daysWeek: 'Аптаның күндері:',
    trimmerInterval: 'Трим аралықтары:',
    filterHintFunction:
      'Функция қосылса, аралық ішінде басталып, бірақ оның сыртында аяқталатын күй (мысалы, сапар) қысқартылады. Есепте тек көрсетілген аралықтағы ұзақтық ескеріледі, қалғаны жойылады.',
    zeroInterval: 'Нөлдік интервал!'
  }
}

export default translation

import { commandsApi } from '@/api'

const state = {
  commandsHistory: [],
  commandsTemplate: [],
  closeCommand:false,
}

const actions = {
  
  async getCommandsHistory({ commit }, params) {
    console.log('getting commands...!')
    const data = await commandsApi.getCommandsHistory(params)
    commit('setCommandsHistory', data.data)
  },

  async getCommandTemplate({ commit }, params = {}) {
    const data = await commandsApi.getCommandsTemplate(params)
    commit('setCommandsTemplate', data.data)
  },

  async sendCommand({ commit }, params = {}) {
    const data = await commandsApi.sendCommand(params)
    commit('addCommandToHistory', data.data)
  },
}

const mutations = {
  addCommandToHistory(state, payload) {
    state.commandsHistory.list.unshift(payload[0])
  },

  setCommandsHistory(state, payload) {
    state.commandsHistory = payload
  },

  setCommandsTemplate(state, payload) {
    state.commandsTemplate = payload
  },

  setClearCommands(state, payload) {
    state.commandsHistory = []
    state.closeCommand = !state.closeCommand
  },
  async updateCommand(state, payload) {
    let params = {
      conditions: [{ field: 'imei', value: payload.imei }],
    }
    const data = await commandsApi.getCommandsHistory(params)
    state.commandsHistory = data.data
    //   setTimeout(() => {
    //     const commandIndex = state.commandsHistory.list.findIndex(
    //       (el) => el.id === payload.id
    //     )
    //     state.commandsHistory.list[commandIndex] = payload
    //     state.commandsHistory.list.length = 2
    //     console.log('index', commandIndex)
    //     console.log('command', state.commandsHistory.list)
    //   }, 2000)
  },
}

const getters = {
  commandsHistory: (state) => state.commandsHistory.list,
  commandsTemplate: (state) => state.commandsTemplate.list,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

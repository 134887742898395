import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      reportTables: [],
      reportsForms: {},
      reportStyles: {},
      reportZIndex: 20,
      height: null,
      openedReports: [],
      isLoadingReport: {},
      isSwitchingObject: false
    }
  },
  // paginatedReports = reportsForms - формы из отчета
  // arrayClickedBtn = reportTables - джейсон таблицы
  computed: {
    reportTablesIds() {
      return this.reportTables.map((x) => x.reportId)
    },
    ...mapState('reports', {
      isTableRemoving: (state) => state.removeTable,
      report: (state) => state.report
    }),
    ...mapGetters('reports', ['tabledata', 'chartTable'])
  },
  watch: {
    isTableRemoving(val, old) {
      console.log('isTableRemoving', val, old)
      // this.resetReports()
    },
    report() {
      // close empty reports with error status
      this.closeReportByStatus()
    }
  },

  methods: {
    changeTablePosition({ id, left, bottom }) {
      this.reportStyles[id] = {
        ...this.reportStyles[id],
        left,
        bottom
      }
    },
    resetReports() {
      this.reportTables = []
      this.reportsForms = {}
      this.reportZIndex = 20
      this.$store.commit('reports/SET_REMOVETRACK')
      this.$store.state.reports.loading = {}
      this.$store.state.reports.report = {}
      this.$store.state.reports.tabledata = {}
      this.$store.state.reports.columndata = {}
      this.$store.state.reports.maxrows = {}
    },

    // close reports with error by status
    closeReportByStatus() {
      const WAIT_FOR_PREVIOUS_REPORT_COMPLETE_STATUS = 429
      const closeReportStatuses = [WAIT_FOR_PREVIOUS_REPORT_COMPLETE_STATUS]

      const allReportsObj = this.$store.state.reports.report

      Object.keys(allReportsObj).forEach((reportKey) => {
        const currentReport = allReportsObj[reportKey]

        if (
          currentReport.error &&
          closeReportStatuses.includes(currentReport.error.status)
        ) {
          this.closeTab(
            this.reportTables.find((table) => table.reportId === reportKey)
          )
          this.$store.commit('reports/REMOVE_REPORT', reportKey)
        }
      })
    },

    showReport({ reportFormData, tableInfo, isZoomed, isFinance }) {
      this.isReport = false
      this.isMonitoring = false
      this.isAdminPanel = false
      this.isLoadingReport[tableInfo.reportId] = true
      this.reportTables.push(tableInfo)

      if (reportFormData) {
        this.reportsForms[tableInfo.reportId] = reportFormData
      }
      if (isFinance) {
        this.$store
          .dispatch('reports/GET_REPORT', {
            isFinance: true,
            searchString: ''
          })
          .finally(() => {
            this.isLoadingReport = {}
          })
      }
      // eslint-disable-next-line no-unused-expressions
      if (tableInfo.key === 'chart' && !isZoomed) {
        this.$store
          .dispatch('reports/GET_REPORT', reportFormData)
          .finally(() => {
            this.$store.commit('reports/showReport')
            this.isLoadingReport = {}
          })
      } else {
        this.$store
          .dispatch('reports/GET_REPORT', {
            ...reportFormData,
            max: 100,
            offset: 0
          })
          .finally(() => {
            this.$store.commit('reports/showReport')
            this.isLoadingReport = {}
          })
      }
    },
    reloadReport() {
      this.isReport = true
      // if (this.reportTables.length) {
      //   this.reportTables = []
      // }
    },
    switchObject(item) {
      this.$store.commit('reports/SET_REMOVETRACK')
      this.$store.commit('reports/REMOVE_CURRENT_TRACK')
      this.$store.commit('map/SET_ISALLSTOPS', false)
    },
    closeTab(item) {
      let openedReports =
        JSON.parse(localStorage.getItem('openedReports')) || []
      const index = openedReports.findIndex((report) => report.id === item.id)
      if (index !== -1) {
        openedReports = openedReports.filter((report) => report.id !== item.id)

        localStorage.setItem('openedReports', JSON.stringify(openedReports))
      }
      // проверяем if no opened tables, we remove the track from the map
      if (!openedReports.length) {
        this.$store.commit('reports/REMOVE_CURRENT_TRACK')
      }
      this.isShowReportTable = false
      this.reportZIndex -= 1
      // after closing the reports table remove showAll events in report , so next time we open the table it works correctly (isAllStops = false)
      this.$store.commit('map/SET_ISALLSTOPS', false)
      delete this.reportsForms[item.reportId]
      delete this.reportStyles[item.reportId]
      this.reportTables = this.reportTables.filter(
        (arrayItem) => arrayItem.reportId !== item.reportId
      )

      this.$store.commit('map/REMOVE_MOVINGMARKER', item.reportId)
      this.$store.commit('reports/SET_REMOVETRACK')
    },
    setHeight(height, tabId) {
      if (!this.height) this.height = height
      const offset = 0
      const index = this.reportTablesIds.indexOf(tabId)
      const bottom = this.reportStyles[tabId]
        ? this.reportStyles[tabId].bottom
        : `${index * (this.height + offset) + 5}px`
      this.reportZIndex += 1
      this.reportStyles = {
        ...this.reportStyles,
        [tabId]: {
          bottom,
          'z-index': this.reportZIndex,
          left: '1vw',
          height: `${height}px`
        }
      }
    }
  }
}

import { reportsApi, adminPanel } from '@/api'
import moment from 'moment'
import eventBus from '@/eventBus.js'
import Vue from 'vue'

const defaultState = {
  chartBase64: '',
  chartLocations: '',
  chartZoom: [],
  closeTrek: false,
  colorCompany: [],
  columndata: {},
  currentReport: null,
  directiveZoom: false,
  drawenArrows: null,
  drawenMakers: null,
  imageToPrint: false,
  loading: {},
  mapToUse: null,
  maxrows: {},
  moreReportData: [],
  newParamFetched: null,
  openedReports: [],
  possibleColumns: {},
  removeChartLocation: false,
  removeTable: false,
  removeTrack: false,
  report: {},
  showChart: false,
  showPolyline: null,
  stopsToShow: '',
  tableCurrentId: null,
  tabledata: {},
  templates: [],
  templatesSensors: [],
  track: [],
  trackByDate: [],
  tracksToDrawCall: false, // true
  racePoints: null,
  groupTrackReport: [],
  pointToDraw: null,
  circleTrack: null,
  magazineDepend: null,
  chartIndex: null,
  listDeletionsMessage: [],
  tableType: '',
  barChartData: null,
  formulasList: {},
  recoveryId: null,
  isRemoveTrack: false,
  reportIdToSwap: null,
  isSwitchingObject: false,
  isPeriodSet: [],
  isTrackByRowClosed: false,
  isShowAnnotation: false,
  groupOrNormalReport: 'normal',
  tablesKeys: [],
  closeToChange: false,
  showReport: false,
  chartIndexes: 0
}

const getters = {
  listTableKeys: (state) => state.tablesKeys,
  tableKeys: (state) => {
    return state.tablesKeys
  },
  isShowAnnotation: (state) => {
    return state.isShowAnnotation
  },
  formulasList: (state) => {
    return state.formulasList
  },
  groupTrackReport: (state) => {
    return state.groupTrackReport
  },
  report: (state) => (reportId) => {
    return state.report[reportId]
  },
  reportTableData: (state) => {
    return state.report
  },
  tabledata: (state) => (reportId) => {
    return state.tabledata[reportId]
  },
  columndata: (state) => (reportId) => {
    console.log('store', state.columndata)
    return state.columndata[reportId]
  },
  pssblCols: (state) => (reportId) => {
    return state.possibleColumns[reportId]
  },
  maxrows: (state) => (reportId) => {
    return state.maxrows[reportId]
  },
  loading: (state) => (reportId) => {
    return state.loading[reportId]
  },
  chartTable: (state) => {
    const zeng = state.templates
      .find((x) => x.is_default)
      .template_tables.find((table) => table.key === 'chart')
    return zeng
  },
  journalReportId: (state) => {
    return state.templates
      .find((temp) => temp.is_default)
      .template_tables.find((table) => table.key === 'journal').id
  },
  tripsTable: (state) => {
    return state.templates
      .find((temp) => temp.is_default)
      .template_tables.find((table) => table.key === 'trips')
  },
  templates: (state) => {
    return state.templates
      .find((x) => x.is_default)
      .template_tables.find((table) => table.key === 'trips')
  },
  defaultReportTemplate: (state) => {
    return state.templates.find((item) => item.is_default)
  },

  templatesForAdding: (state) => {
    return state.templates.filter(
      (template) => template.template_tables.length !== 0
    )
  },
  getChartIndex: (state) => {
    return state.chartIndex
  },
  getMagazineDepend: (state) => {
    return state.magazineDepend
  },

  listDeletionsMessage: (state) => {
    return state.listDeletionsMessage
  },
  getTableTypes: (state) => {
    return state.tableTypes
  },
  getBarChartData: (state) => {
    return state.barChartData
  }
}

const actions = {
  async getTableKeys({ commit }, id) {
    const { data } = await reportsApi.getTableKeys(id)
    const tableKeys = data.reduce((acc, obj, index) => {
      const { type_name, key, ...rest } = obj
      const keyName = `{$${key}}`
      const count = `${index + 1 < 10 ? '0' : ''}${index + 1}`
      const isModal = false

      if (!acc[type_name]) {
        acc[type_name] = []
      }
      acc[type_name].push({
        keyName,
        count,
        isModal,
        ...rest
      })
      return acc
    }, {})
    commit('setTableKeys', tableKeys)
  },
  add_extrafield({ commit }, name) {
    return new Promise((resolve, reject) => {
      try {
        const displayedValue = `Доп. Колонки/${name}`
        const backendValue = `computable_columns.${name}`

        commit('add_extrafield', { displayedValue, backendValue })
        resolve({ displayedValue, backendValue })
      } catch (error) {
        reject(error)
      }
    })
  },
  RECOVER_TEMPLATE({ commit }, data) {
    return new Promise((resolve, reject) => {
      reportsApi.recover(
        data.id,
        (response) => {
          commit('RECOVER', data.template)
          resolve()
        },
        (error) => {
          console.log(error)
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  edit_sensor_column({ commit }, sensor) {
    commit('edit_sensor_column', sensor)
  },
  edit_extrafield({ commit }, name) {
    return new Promise((resolve, reject) => {
      try {
        const displayedValue = `Доп. Колонки/${name}`
        const backendValue = `computable_columns.${name}`

        commit('edit_extrafield', { displayedValue, backendValue })
        resolve({ displayedValue, backendValue })
      } catch (error) {
        reject(error)
      }
    })
  },
  set_sensor_column({ commit }, sensor) {
    commit('add_sensor_column', sensor)
  },
  get_formulas_list({ commit }, tableKey) {
    return new Promise((resolve, reject) => {
      reportsApi.getListKeys(
        tableKey,
        (response) => {
          commit('handleFormulasList', response.data)
          resolve()
        },
        (err) => {
          console.log('error get_formulas_list for report', err)
          reject(err)
        }
      )
    })
  },
  HANDLE_CIRCLEGROUPTRACK({ commit }, circle) {
    commit('CIRCLEGROUPTRACK', circle)
  },
  SHOW_TRIP_REPORT_POINT({ commit }, point) {
    reportsApi.getRacePoints(
      point.id,
      (res) => {
        commit('SHOW_RACE_POINT', res.data.points[point.index])
      },
      (err) => {
        console.log('error race points for report', err)
      }
    )
  },
  SHOW_TRIP_REPORT({ commit }, id) {
    reportsApi.getRacePoints(
      id,
      (res) => {
        commit('SHOW_RACE_POINTS', res.data.points)
      },
      (err) => {
        console.log('error race points for report', err)
      }
    )
  },
  SAVE_CURRENTREPORT({ commit }, report) {
    commit('SAVE_CURRENTREPORT', report)
  },

  showStops({ commit }, markers) {
    commit('SHOWSTOPS', markers)
  },

  removechartLocations({ commit }, locations) {
    commit('REMOVE_CHART_LOCATIONS', locations)
  },

  chartLocations({ commit }, locations) {
    commit('CHART_LOCATIONS', locations)
  },

  imageReady({ commit }, map) {
    commit('IMAGE_READY', map)
  },

  PRINT_IMAGE({ commit }, status) {
    commit('PRINT_IMAGE', status)
  },

  COLOR_COMPANY({ commit }, status) {
    commit('SET_COLOR_COMPANY', status)
  },

  COLOR_COMPANY_CLEAR({ commit }, status) {
    commit('CLEAR_COLOR_COMPANY', status)
  },

  SAVE_TEMPLATES_SENSOR({ commit }, data) {
    commit('SAVE_SENSOR', data)
  },

  GET_TEMPLATES({ commit }) {
    return new Promise(function (resolve, reject) {
      reportsApi.getReportTemplate(
        (response) => {
          commit('SET_TEMPLATE', response.data)
          resolve(response.data)
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error)
        }
      )
    })
  },

  WEBSOCKET_CHANGE({ commit }, { id, operation }) {
    if (operation === 'DELETE') {
      commit('DELETE_TEMPLATE', { id })
    } else {
      reportsApi.getReportTemplateId(
        id,
        (response) => {
          if (operation === 'POST') {
            commit('ADD_TEMPLATE', response.data)
          } else {
            commit('UPDATE_TEMPLATE', response.data)
          }
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },

  DOWNLOAD_TEMPLATE({ commit }, formData) {
    reportsApi.downloadReport(
      ({ formData },
      function (response) {
        commit('ADD_TEMPLATE', response.data)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      })
    )
  },

  CREATE_TEMPLATE({ commit }, formData) {
    reportsApi.createNewReportTemplate(
      formData,
      function (response) {
        commit('ADD_TEMPLATE', response.data)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  GETDATANEWCHART({ commit }, formData) {
    reportsApi.getReport(
      { formData },
      function (response) {
        commit('UPDATECHART', response.data.report)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  GETNEWPARAM({ commit }, formData) {
    reportsApi.getReport(
      { formData },
      function (response) {
        commit('ADDNEWPARAM', response.data.report)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  DELETE_TEMPLATE({ commit }, template) {
    const promiseList = []
    promiseList.push(
      new Promise(function (resolve, reject) {
        reportsApi.deleteReportTemplates(template.id, resolve, reject)
      })
    )
    Promise.all(promiseList)
      .then((res) => {
        commit('DELETE_TEMPLATE', template)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch(function (error) {
        console.log(error)
        eventBus.$showError(error.response.data.message)
      })
    // reportsApi.deleteReportTemplates(
    //   template.id,
    //   function (response) {
    //     commit('DELETE_TEMPLATE', template)
    //   },
    //   function (error) {
    //     eventBus.$showError(error.response.data.message)
    //   }
    // )
  },
  UPDATE_TEMPLATE_TABLES({ commit }, template) {
    commit('UPDATE_TEMPLATE_TABLES', template)
  },

  UPDATE_TEMPLATE({ commit }, template) {
    reportsApi.updateReport(
      template.id,
      template,
      function (response) {
        commit('UPDATE_TEMPLATE', response.data)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  GET_MOREREPORTS({ commit }, formData) {
    reportsApi.getReport(
      { formData },
      function (response) {
        commit('GETMOREREPORTS', response.data.report)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  HANDLE_OPNEDREPORTS({ commit }, openedReports) {
    commit('SET_OPENEDREPORTS', openedReports)
  },

  GET_REPORT({ commit }, formData) {
    let res = null
    if (formData.isFinance) {
      const reportId = 'finance'
      commit('SET_LOADING', [reportId, true])
      adminPanel.getFinance(
        formData.searchString,
        (response) => {
          const { columns } = response.data.report

          const { rows } = response.data.report
          commit('SET_REPORT', [reportId, response.data])
          commit('SET_MAXROWS', [reportId, response.data.report.max_rows])
          commit('SET_TABLEDATA', [reportId, rows])
          commit('SET_COLUMNDATA', [reportId, columns])
          commit('SET_LOADING', [reportId, false])
        },
        (error) => {
          const { message, status } = error.response.data

          commit('SET_LOADING', [reportId, false])
          commit('SET_REPORT', [
            reportId,
            { columns: [], rows: [], max_rows: 0, error: { status } }
          ])
          console.log('error', error)
          eventBus.$showError(message)
        }
      )
    } else {
      const info = {
        from: formData.from,
        template_tables: formData.template_tables,
        to: formData.to,
        unit_id: formData.units ? formData.units[0].id : formData.groups[0].id
      }
      commit('SET_CURRENT_TABLE_DATA', info)
      const unitOrGroupId = formData.units
        ? formData.units[0].id
        : formData.groups[0].id

      // if (formData.objectsToSwitch) {
      //   reportId = formData.objectsToSwitch.oldId
      // } else {
      //   reportId =
      //     formData.template_tables[0].id +
      //     unitOrGroupId +
      //     formData.from +
      //     formData.to
      // }

      let reportId =
        formData.template_tables[0].id +
        unitOrGroupId +
        formData.from +
        formData.to

      if (formData.objectsToSwitch) {
        commit('SWAP_OBJECT', reportId)
      }
      commit('SET_LOADING', [reportId, true])
      if (formData.isAll === true) {
        delete formData.groups
        delete formData.isAll

        res = reportsApi.getReport(
          { formData },
          function (response) {
            if (response.data) {
              if (response.data.report.key === 'chart') {
                reportId = formData.template_tables[0].id
              }
              const { columns } = response.data.report
              const { rows } = response.data.report
              commit('SET_REPORT', [reportId, response.data])
              commit('SET_MAXROWS', [reportId, response.data.report.max_rows])
              if (response.data.report.key !== 'chart') {
                commit('SET_TABLEDATA', [reportId, rows])
                commit('SET_COLUMNDATA', [reportId, columns])
                response.data.report.key === 'journal' &&
                  commit('SET_POSSIBLE_COLUMNS', [
                    reportId,
                    response.data.report.possible_columns
                  ])
                commit('SET_LOADING', [reportId, false])
              } else {
                const arr = ['datepoint', 'trips_intervals', 'is_trip']
                const trips = columns
                  .filter((col) => col.key === 'trips_intervals')[0]
                  .data.map((t, index) => {
                    return {
                      drawTime: 'beforeDatasetsDraw',
                      id: `a-box-${index}`,
                      xScaleID: 'x-axis-0',
                      yScaleID: 'y-axis-0',
                      type: 'box',
                      xMin: t[0],
                      xMax: t[1],
                      backgroundColor: 'rgba(231, 238, 245, 0.7)'
                    }
                  })
                const colors = [
                  '#EB5757',
                  '#2F80ED',
                  '#ff8417',
                  '#219653',
                  '#9B51E0',
                  '#7ac701',
                  '#2db281',
                  '#4058d3',
                  '#be0000'
                ]
                const columnData = columns.filter(
                  (col) => col.key === 'datepoint'
                )[0].data
                const rowData = columns
                  .filter(
                    (col) => !arr.includes(col.key) && col.key.charAt(0) !== '_'
                  )
                  .map((arr, index) => {
                    return { ...arr, '#': index, color: colors[index] }
                  })
                commit('SET_TABLEDATA', [reportId, rowData])
                commit('SET_COLUMNDATA', [
                  reportId,
                  {
                    labels: columnData,
                    trips
                  }
                ])
                commit('SET_POSSIBLE_COLUMNS', [
                  reportId,
                  response.data.report.possible_columns
                ])
                console.log('after update graphic !!! ')
                commit('SET_LOADING', [reportId, false])
              }
            }
          },
          function (error) {
            const { message, status } = error.response.data

            console.log(1, error)
            commit('SET_LOADING', [reportId, false])
            commit('SET_REPORT', [
              reportId,
              { columns: [], rows: [], max_rows: 0, error: { status } }
            ])
            eventBus.$showError(message)
          }
        )
      } else {
        console.log('xx')
        res = reportsApi.getReport(
          { formData },
          function (response) {
            if (response.data) {
              if (response.data.report.key === 'analytics_units_events') {
                commit('SHOW_POLYLINE', false)
              } else {
                commit('SHOW_POLYLINE', true)
              }
              if (response.data.report.key === 'chart') {
                reportId = formData.template_tables[0].id
              }

              const { columns } = response.data.report
              const { rows } = response.data.report
              commit('SET_REPORT', [reportId, response.data])
              commit('SET_MAXROWS', [reportId, response.data.report.max_rows])
              if (response.data.report.key !== 'chart') {
                commit('SET_TABLEDATA', [reportId, rows])
                commit('SET_COLUMNDATA', [reportId, columns])
                response.data.report.key === 'journal' &&
                  commit('SET_POSSIBLE_COLUMNS', [
                    reportId,
                    response.data.report.possible_columns
                  ])
                commit('SET_LOADING', [reportId, false])
              } else {
                const arr = ['datepoint', 'trips_intervals', 'is_trip']

                const trips = columns
                  .filter((col) => col.key === 'trips_intervals')[0]
                  .data.map((t, index) => {
                    return {
                      drawTime: 'beforeDatasetsDraw',
                      id: `a-box-${index}`,
                      xScaleID: 'x-axis-0',
                      yScaleID: 'y-axis-0',
                      type: 'box',
                      xMin: t[0],
                      xMax: t[1],
                      backgroundColor: 'rgba(231, 238, 245, 0.7)'
                    }
                  })
                const colors = [
                  '#EB5757',
                  '#2F80ED',
                  '#ff8417',
                  '#219653',
                  '#9B51E0',
                  '#7ac701',
                  '#2db281',
                  '#4058d3',
                  '#be0000'
                ]
                const columnData = columns.filter(
                  (col) => col.key === 'datepoint'
                )[0].data
                const rowData = columns
                  .filter(
                    (col) => !arr.includes(col.key) && col.key.charAt(0) !== '_'
                  )
                  .map((arr, index) => {
                    return { ...arr, '#': index, color: colors[index] }
                  })
                commit('SET_TABLEDATA', [reportId, rowData])
                commit('SET_COLUMNDATA', [
                  reportId,
                  {
                    labels: columnData,
                    trips
                  }
                ])
                commit('SET_POSSIBLE_COLUMNS', [
                  reportId,
                  response.data.report.possible_columns
                ])
                commit('SET_LOADING', [reportId, false])
              }
            }
          },

          function (error) {
            const { message, status } = error.response.data

            commit('SHOW_POLYLINE', false)
            commit('SET_LOADING', [reportId, false])
            commit('SET_REPORT', [
              reportId,
              { columns: [], rows: [], max_rows: 0, error: { status } }
            ])
            eventBus.$showError(message)
          }
        )
      }
    }
    return res
  },
  GET_TRACK({ commit }, formData) {
    reportsApi.getTrack(
      { formData },
      function (response) {
        if (response.data.values.length === 0) {
          eventBus.$showError(this.$t('notification_alert.error_points'))
          return
        }
        const unitId = formData.units[0].id
        const uniqueTrackId = `[${unitId}][${formData.from}-${formData.to}]`

        commit('SET_TRACK', [unitId, response.data.values, uniqueTrackId])
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  GET_TRACK_BYDATE({ commit }, [formData, reportId]) {
    // console.log('get track by date', formData)
    reportsApi.getTrack(
      { formData },
      function (response) {
        const data = response.data.values.map((k) => k.points).flat(1)
        const annData = data.map((h) => [
          moment(h[0] * 1000)
            .utc()
            .format('HH:mm:ss'),
          h[3]
        ])
        const date = moment(new Date(formData.from)).format('DD.MM')
        commit('SET_TRACK_BYDATE', {
          [reportId]: data,
          annData,
          date,
          track: response.data.values
        })
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  removeTheTable({ commit }, table) {
    commit('REMOVETABLE', table)
  },

  async getFastTrackGroupsReport({ commit }, formData) {
    console.log(formData, 'formData');
    // GET_TRACK({ commit }, formData) {
    //   reportsApi.getTrack(
    //     { formData },
    //     function (response) {
    //       if (response.data.values.length === 0) {
    //         eventBus.$showError(this.$t('notification_alert.error_points'))
    //         return
    //       }
    //       const unitId = formData.units[0].id
    //       const uniqueTrackId = `[${unitId}][${formData.from}-${formData.to}]`
    //       commit('SET_TRACK', [unitId, response.data.values, uniqueTrackId])
    //     },
    //     function (error) {
    //       eventBus.$showError(error.response.data.message)
    //     }
    //   )
    // },
    try {
      // const unitId = formData.groups[0].id
      // const uniqueTrackId = `[${unitId}][${formData.from}-${formData.to}]`
      // const { data } = await reportsApi.getFastTrackGroupReport(formData)
      // const allTrack = data.reduce((acc, curr) => acc.concat(curr.values), [])
      // commit('SET_TRACK', [unitId, allTrack, uniqueTrackId])
      return await reportsApi.getFastTrackGroupReport(formData)
      // console.log(data.data, 'data fastom group track');
      // commit('setGroupTrackReport', data.data)
    } catch (err) {
      eventBus.$showError(err.response.data.error.message)
    }
  },

  GET_CHART_INDEX({ commit }, data) {
    reportsApi.postDatePoint(
      { ...data },
      (response) => {
        commit('SET_CHART_INDEX', response.data.index)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  async deleteIntervalMessage({ commit }, formData) {
    reportsApi
      .deleteIntervalMessage(formData)
      .then((resp) => {
        commit('setDeleteIntervalMessage', resp.data)
      })
      .catch((error) => {
        eventBus.$showError(error.response.data.message)
      })
  },

  async getListDeletionsMessage({ commit }, formData) {
    reportsApi
      .listDeletionsMessage(formData)
      .then((resp) => {
        commit('setDeletionsList', resp.data)
      })
      .catch((error) => {
        eventBus.$showError(error.response.data.message)
      })
  },

  async deleteMessage({ commit }, id) {
    reportsApi.deleteMessage(id)
    commit('setDeleteMessage', id)
  },

  async GET_BAR_CHART_REPORT({ commit }, formData) {
    try {
      const { data } = await reportsApi.getReportBarChart(formData)

      const { columns } = data.report

      const { rows } = data.report

      const chartData = {
        columns,
        rows
      }

      commit('SET_BAR_CHART_DATA', chartData)
    } catch (err) {
      throw new Error(err)
    }
  },
  async REPORT_TABLE_VALIDATE(_, formData) {
    try {
      const data = await reportsApi.checkReportTable(formData)

      return data
    } catch (err) {
      eventBus.$showError(err.response.data.message)
      throw new Error(err)
    }
  }
}

const mutations = {
  SET_CLOSE_TO_CHANGE(state, payload) {
    state.closeToChange = payload
  },
  setTableKeys(state, payload) {
    state.tablesKeys = payload
  },
  setGroupOrNormalReport(state, payload) {
    state.groupOrNormalReport = payload
  },
  closeTrackByRow(state) {
    state.isTrackByRowClosed = !state.isTrackByRowClosed
  },
  REMOVE_CURRENT_TRACK(state) {
    state.isRemoveTrack = !state.isRemoveTrack
  },
  RECOVER(state, template) {
    state.templates.push(template)
  },
  edit_sensor_column(state, payload) {
    const { oldCol, savedValues } = payload

    const oldValKey = Object.keys(oldCol)[0]
    const newValKey = Object.keys(savedValues)[0]
    const newValValue = Object.values(savedValues)[0]

    if (state.formulasList.hasOwnProperty(oldValKey)) {
      delete state.formulasList[oldValKey]
      const formData = {}
      formData[newValKey] = newValValue

      Object.assign(state.formulasList, formData)
    }
  },
  edit_extrafield(state, payload) {
    const { oldCol, savedValues } = payload
    const oldValKey = Object.keys(oldCol)[0]
    const newValKey = Object.keys(savedValues)[0]
    const newValValue = Object.values(savedValues)[0]

    if (state.formulasList.hasOwnProperty(oldValKey)) {
      Vue.delete(state.formulasList, oldValKey)
      Vue.set(state.formulasList, newValKey, newValValue)
    }
  },
  remove_extrafield(state, name) {
    const backendValue = `computable_columns.${name}`
    Vue.delete(state.formulasList, backendValue)
  },
  remove_sensorCol(state, name) {
    const backendValue = `sensors.${name}`

    Vue.delete(state.formulasList, backendValue)
  },
  add_extrafield(state, payload) {
    const { displayedValue, backendValue } = payload
    Vue.set(state.formulasList, backendValue, displayedValue)
  },
  add_sensor_column(state, payload) {
    Object.assign(state.formulasList, payload)
  },
  handleFormulasList(state, payload) {
    state.formulasList = payload
  },
  CHART_BASE46(state, chart) {
    state.chartBase64 = chart
  },
  CHANGE_MAGAZINE_DEPEND(state, value) {
    state.magazineDepend = value
  },
  SET_REPORT_PERIOD(state, value) {
    state.isPeriodSet = value
  },

  CIRCLEGROUPTRACK(state, circle) {
    state.circleTrack = circle
  },
  switchObject(state, value) {
    state.isSwitchingObject = !state.isSwitchingObject
  },
  SHOW_RACE_POINT(state, point) {
    state.pointToDraw = point
  },
  setGroupTrackReport(state, payload) {
    state.groupTrackReport = payload
  },
  SHOW_RACE_POINTS(state, points) {
    state.racePoints = points
  },
  UPDATECHART(state, formData) {
    state.moreReportData = formData
  },
  ADDNEWPARAM(state, formData) {
    state.newParamFetched = formData
  },
  SAVE_CURRENTREPORT(state, report) {
    state.currentReport = report
  },
  SET_DRAWENARROWS(state, payload) {
    state.drawenArrows = payload
  },
  CLEAR_DRAWENARROWS(state) {
    state.drawenArrows = null
  },
  SET_DRAWENMARKERS(state, payload) {
    state.drawenMakers = payload
  },
  REMOVETABLE(state, payload) {
    const current = state.openedReports.filter((el) => el !== payload)
    state.openedReports = [...new Set(current)]
  },
  RESETTABLE(state) {
    state.openedReports = []
  },
  SET_OPENEDREPORTS(state, payload) {
    state.openedReports = payload
  },
  SET_CURRENT_TABLE_DATA(state, payload) {
    state.tableCurrentId = payload
  },
  CLOSED_CALL_DRAW(state, payload) {
    state.tracksToDrawCall = payload
  },
  SHOW_REPORT_CHART(state) {
    state.showChart = !state.showChart
  },
  CLEAR_TREK(state, payload) {
    state.closeTrek = payload
  },
  DIRECTIVE_ZOOM(state, payload) {
    state.directiveZoom = payload
  },
  SHOW_POLYLINE(state, payload) {
    state.showPolyline = payload
  },
  GETMOREREPORTS(state, formData) {
    state.moreReportData = formData
  },
  SHOWSTOPS(state, markers) {
    state.stopsToShow = markers
  },
  REMOVE_CHART_LOCATIONS(state, locations) {
    state.removeChartLocation = !state.removeChartLocation
  },
  CHART_LOCATIONS(state, locations) {
    state.chartLocations = locations
  },
  IMAGE_READY(state, map) {
    state.mapToUse = map
  },
  PRINT_IMAGE(state, status) {
    state.imageToPrint = status
  },
  SET_TEMPLATE(state, template) {
    state.templates = template
  },
  showReport(state) {
    state.showReport = !state.showReport
  },
  SET_CHART_INDEX(state, index) {
    state.chartIndex = index
  },
  SET_COLOR_COMPANY(state, item) {
    if (state.colorCompany.length) {
      let newValue
      state.colorCompany.forEach((val, key) => {
        if (val.type === item.type) {
          newValue = val
        }
        if (
          val.id === item.id &&
          val.to === item.to &&
          val.from === item.from
        ) {
          if (newValue && state.colorCompany[key]) {
            state.colorCompany[key].color = newValue.color
          }
        }
        if (
          val.id === item.id &&
          val.type === item.type &&
          val.to === item.to &&
          val.from === item.from
        ) {
          state.colorCompany.splice(key, 1, item)
        } else {
          state.colorCompany.push(item)
        }
      })
    } else {
      state.colorCompany.push(item)
    }
    state.colorCompany = Array.from(new Set(state.colorCompany))
  },
  CLEAR_COLOR_COMPANY(state, item) {
    if (state.colorCompany.length) {
      state.colorCompany.forEach((val, key) => {
        if (
          val.id === item.unit_id &&
          val.type === item.report.key &&
          val.to === item.to &&
          val.from === item.from
        ) {
          state.colorCompany.splice(key, 1)
        }
      })
    }
    state.colorCompany = Array.from(new Set(state.colorCompany))
  },
  DOWNLOAD_TEMPLATE(state, template) {
    state.templates = template
  },

  SAVE_SENSOR(state, template) {
    state.templatesSensors = template
  },

  ADD_TEMPLATE(state, template) {
    if (!state.templates.some((x) => x.id === template.id)) {
      state.templates = [...state.templates, template]
    }
  },
  SET_RECOVERYID(state, payload) {
    state.recoveryId = payload.id
  },
  DELETE_TEMPLATE(state, template) {
    state.templates = state.templates.filter((item) => item.id !== template.id)
  },
  UPDATE_TEMPLATE(state, template) {
    const indexOfTemplate = state.templates.findIndex(
      (elem) => elem.id === template.id
    )
    state.templates = [
      ...state.templates.slice(0, indexOfTemplate),
      template,
      ...state.templates.slice(indexOfTemplate + 1)
    ]
  },
  UPDATE_TEMPLATE_TABLES(state, payload) {
    if (payload.id) {
      const tableToUpdate = state.templates.find(
        (table) => table.id === payload.id
      )

      if (!tableToUpdate) {
        console.error('Template not found for ID:', payload.id)
        return
      }
      const { element, newIndex, oldIndex } = payload

      const updatedTables = [...tableToUpdate.template_tables]
      const temp = updatedTables[oldIndex]
      updatedTables[oldIndex] = updatedTables[newIndex]
      updatedTables[newIndex] = temp

      tableToUpdate.template_tables = updatedTables
    }
  },

  SET_REPORT(state, [reportId, report]) {
    state.report = { ...state.report, [reportId]: report }
  },
  REMOVE_REPORT(state, reportId) {
    const newReports = { ...state.report }
    delete newReports[reportId]

    state.report = newReports
  },
  SET_TABLEDATA(state, [reportId, tabledata]) {
    if (state.tabledata.hasOwnProperty(reportId)) {
      state.tabledata[reportId] = tabledata
    } else {
      state.tabledata = { ...state.tabledata, [reportId]: tabledata }
    }
  },
  SET_POSSIBLE_COLUMNS(state, [reportId, possibleColumns]) {
    state.possibleColumns = {
      ...state.possibleColumns,
      [reportId]: possibleColumns
    }
  },
  SET_COLUMNDATA(state, [reportId, columndata]) {
    state.columndata = { ...state.columndata, [reportId]: columndata }
  },
  SWAP_OBJECT(state, reportId) {
    state.reportIdToSwap = reportId
  },
  SET_MAXROWS(state, [reportId, maxrows]) {
    state.maxrows = { ...state.maxrows, [reportId]: maxrows }
  },

  SET_LOADING(state, [reportId, loading]) {
    state.loading = { ...state.loading, [reportId]: loading }
  },
  SET_TRACK(state, [id, track, uniqueTrackId]) {
    state.track = { id, track, uniqueTrackId }
  },
  SET_REMOVETRACK(state) {
    state.removeTrack = !state.removeTrack
  },
  SET_REMOVE_TABLE(state) {
    state.removeTable = !state.removeTable
  },
  SET_TRACK_BYDATE(state, trackByDate) {
    state.trackByDate = trackByDate
  },

  SET_CHART_ZOOM(state, chartZoom) {
    state.chartZoom = chartZoom
  },

  SET_CHART_INDEXES(state, index) {
    state.chartIndex = index
  },
  setDeleteIntervalMessage(state, payload) {
    state.listDeletionsMessage.list.push(payload)
  },
  setDeletionsList(state, payload) {
    state.listDeletionsMessage = payload
  },
  setDeleteMessage(state, payload) {
    state.listDeletionsMessage.list = state.listDeletionsMessage.list.filter(
      (el) => {
        return el.id !== payload
      }
    )
  },
  setTableType(state, payload) {
    state.tableType = payload
  },
  SET_BAR_CHART_DATA(state, payload) {
    state.barChartData = payload
  },
  setIsShowAnnotation(state, payload) {
    state.isShowAnnotation = payload
  },
  updateTableKey(state, payload) {
    const { tableName, key, isModal } = payload
    state.tablesKeys[tableName].forEach((item) => {
      if (item.keyName !== key.keyName && item.isModal === true) {
        item.isModal = false
      }
    })
    const foundItem = state.tablesKeys[tableName].find(
      (item) => item.keyName === key.keyName
    )
    if (foundItem) {
      foundItem.isModal = isModal
    }
  }
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}

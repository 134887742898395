const defaultState = {
  center: [55.75, 37.62],
  zoom: 13,
  radiusValue: 13,
  otechGeozone: null,
  markerClusterNotifyAnxiousMarker: [],
  markerClusterNotifyMarker: [],
  geozones: [],
  latlon: [],
  markerId: { '': false },
  startend: [],
  startendId: { '': false },
  removeTrackId: { '': false },
  removeMovingMarkerId: { '': false },
  eyeTriggered: { isTrigged: false, value: '' },
  isHidingGeo: false,
  isHidingSignatures: false,
  isHidingEvents: '',
  isHidingNotification: '',
  hideMeasure: false,
  currentRow: '',
  columnTable: '',
  isAllStops: 'singular',
  showMarkerFromCluster: '',
  tableType: '',
  removeStopsFromMap: false,
  isCloseMapAnnot: '',
  companyChange: false,
  tableName: null,
  reportsDrawnMarkers: null,
  isLocatorView: false,
  trackWeight: 5,
  isShouldGroupObjects: false
}
const getters = {
  trackWeight: (state) => state.trackWeight,
  getIsGroupingObjs: (state) => state.isShouldGroupObjects,
  geozones: (state) => {
    return state.geozones
  },
  latlon: (state) => {
    return state.latlon
  },

  vuexRadiusValue: (state) => state.radiusValue,
  isHidingGeo: (state) => state.isHidingGeo,
  hideSignatures: (state) => state.isHidingSignatures
}

const actions = {
  updateTrackWeight({ commit }, newWeight) {
    commit('setTrackWeight', newWeight)
  },
  CLOSEANNOMAP({ commit }, status) {
    commit('CLOSE_MAP_ANN', status)
  },
  SET_SHOW_MARKER_FROM_CLUSTER({ commit }, marker) {
    commit('SHOW_MARKER_FROM_CLUSTER', marker)
  },
  SET_OUTECH_GEOZONES({ commit }, row) {
    commit('SET_OUTECH_GEOZONES', row)
  },

  setCenter({ commit }, center) {
    localStorage.setItem('mapCenter', JSON.stringify([center.lat, center.lng]))
    commit('SET_CENTER', center)
  },

  setLocatorView({ commit }, bool) {
    commit('SET_LOCATOR_VIEW', bool)
  },

  setZoom({ commit }, zoom) {
    localStorage.setItem('mapZoom', zoom)
    commit('SET_ZOOM', zoom)
  },
  SET_ISALLSTOPS({ commit }, isAllShown) {
    console.log('isALlShow store', isAllShown)
    commit('SET_ISALLSTOPS', isAllShown)
  },
  REMOVESTOPSFROMMAP({ commit }) {
    commit('REMOVESTOPSFROMMAP')
  }
}

const mutations = {
  SET_GROUPING_OBJECTS(state, bool) {
    state.isShouldGroupObjects = bool
  },
  SETTRACK_WEIGHT(state, newWeight) {
    state.trackWeight = newWeight
  },
  REPORTS_MARKERS(state, payload) {
    state.reportsDrawnMarkers = payload
  },
  COMPANY_CHANGE(state) {
    state.companyChange = !state.companyChange
  },
  CLOSE_MAP_ANN(state) {
    state.isCloseMapAnnot = !state.isCloseMapAnnot
  },
  REMOVESTOPSFROMMAP(state) {
    state.removeStopsFromMap = !state.removeStopsFromMap
    console.log('trigger from store', state.removeStopsFromMap)
  },
  SHOW_MARKER_FROM_CLUSTER(state, marker) {
    state.showMarkerFromCluster = marker
  },
  SET_OUTECH_GEOZONES(state, marker) {
    state.otechGeozone = marker
  },

  SET_ISALLSTOPS(state, isAllShown) {
    state.isAllStops = isAllShown
  },

  SET_LOCATOR_VIEW(state, bool) {
    state.isLocatorView = bool
  },

  SET_MARKER_NOTIFY(state, marker) {
    state.markerClusterNotifyMarker.push(marker)
  },

  CLEAR_MARKER_NOTIFY(state) {
    state.markerClusterNotifyMarker = []
  },
  SET_MARKER_NOTIFY_ANXIOUS(state, marker) {
    state.markerClusterNotifyAnxiousMarker.push(marker)
  },
  SET_RADIUS_VALUE(state, value) {
    state.radiusValue = value
  },
  TOGGLE_GEOZONES(state, isHiding) {
    state.isHidingGeo = isHiding
  },
  TOGGLE_TREK_INFO(state, isHiding) {
    state.isHidingSignatures = isHiding
  },
  TOGGLE_EVENTS(state, isHiding) {
    state.isHidingEvents = isHiding
  },
  TOGGLE_NOTIFICATION(state, isHiding) {
    state.isHidingNotification = isHiding
  },
  SET_CENTER(state, center) {
    state.center = center
  },

  SET_ZOOM(state, zoom) {
    state.zoom = zoom
  },
  EYE_TRIGGER(state, value) {
    state.eyeTriggered.isTrigged = !state.eyeTriggered.isTrigged
    state.eyeTriggered.value = value
  },
  SET_GEOZONES(state, geozones) {
    state.geozones = geozones
  },
  CLEAR_CURRENT_ROW(state) {
    state.currentRow = null
  },
  SET_MARKERDATA(state, tableData) {
    state.latlon = { [tableData.reportId]: tableData.latlng }
    state.currentRow = tableData.sortedRow
    state.columnTable = tableData.columnTable
    state.isStopShowing = tableData.isStopShowing
    state.tableType = tableData.tableType
    state.tableName = tableData.tableName
  },

  SET_STARTENDMARKER(state, [startend, reportId]) {
    state.startend = {
      [reportId]: startend
    }
  },
  REMOVE_TRACKSMARKERS(state, markerId) {
    state.markerId = { [markerId]: !Object.values(state.markerId)[0] }
    state.startendId = { [markerId]: !Object.values(state.startendId)[0] }
    state.removeTrackId = {
      [markerId]: !Object.values(state.removeTrackId)[0]
    }
    state.removeMovingMarkerId = {
      [markerId]: !Object.values(state.removeMovingMarkerId)[0]
    }
  },
  REMOVE_MOVINGMARKER(state, removeMovingMarkerId) {
    state.removeMovingMarkerId = {
      [removeMovingMarkerId]: !Object.values(state.removeMovingMarkerId)[0]
    }
  }
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations
}

import { accessSettingsApi } from '@/api'
import {commit} from "lodash/seq";

const state = {
    getRole: '',
    loader: false,
    currentRole: null,
    allActions: [],
    allRoles: [],
    checkedSamples: [],
    selectedRole: null,
    changeSettings: [],
    modalVisibly: false,
    groupsById: [],
    isLoading: false,
    usersInfoById: [],
    updatedUnits: null,
    isAccessSettings: false,
    accessUsersInModal:false,
    accessUsersInGroups:false,
}

const getters = {
    getAllActions: (state) => state.allActions,
    getAllRoles: (state) => state.allRoles,
    getGroupsById: (state) => state.groupsById,
    getUsersById: (state) => state.usersInfoById,
    getIsAccessSettings: (state)=>state.isAccessSettings,
    getAccessUsersInModal: (state)=>state.accessUsersInModal,
    getAccessUsersInGroups: (state)=>state.accessUsersInGroups
}

const actions = {
  getAllActions({commit}, value) {
      accessSettingsApi.getAllActions().then(res => {
            if(value && (typeof value === 'string')) {
                this.allActions = res.filter((item) => {
                    return item.name.toLowerCase().includes(value.toLowerCase())
                })
                commit('setAllActions', this.allActions)
            } else {
                this.allActions = res
                commit('setAllActions', this.allActions)
            }
        })
    },

    saveRoleActions({ commit }, {form, id}) {
        return accessSettingsApi.saveActions({form, id})
          .then(res => {
              return res;
          })
          .catch(err => {
              console.error(err,)
          })
    },

    getAllRoles({commit}, value = '') {
        return new Promise((resolve) => {
            accessSettingsApi.getAllRoles().then(res => {
              if(value) {
                  this.allRoles = res.data.filter((item) => {
                      return item.value.toLowerCase().includes(value.toLowerCase())
                  })
                  commit('setAllRoles', this.allRoles)
              } else {
                  this.allRoles = res.data
                  commit('setAllRoles', this.allRoles)
              }
                resolve(res.data)
          })

      })

    },

    createNewRole({dispatch}, data) {
        accessSettingsApi.createNewRole(data).then(() => {
            dispatch('getAllRoles')
        })
    },

    deleteRole({dispatch}, {roleId, replaceRoleId}) {
        accessSettingsApi.deleteRole(roleId, replaceRoleId).then(() => {
            dispatch('getAllRoles')
        })
    },

    addChangeSettings({dispatch}, form) {
        accessSettingsApi.addChangeSettings(form).then(() => {
            dispatch('getAllRoles')
        })
    },
    putGroupsByObject({commit},payload) {
        return new Promise((resolve) => {
            accessSettingsApi.putGroupsByObject(payload.id,payload.newUser).then(res => {
                resolve(res)
            })
        })
    },
    getGroupsByObject({commit}, val) {
        return new Promise((resolve) => {
            accessSettingsApi.getGroupsByObject(val).then(res => {
                commit('setGroupsByObject', res)
              resolve(res)
          })
      })
    },
    getUsersInfoById({commit}, id) {
        return new Promise((resolve) => {
          accessSettingsApi.getUsersInfoById(id).then(res => {
              commit('setUsersById', res)
              resolve(res)
          })
      })
    },
    changeUserRole({commit}, payload) {
        return new Promise((resolve) => {
            accessSettingsApi.changeUserRole(payload.id,payload.role).then(res=>{
                resolve(res)
            })
      })
    },
    deleteUnit({commit},payload){
        return new Promise((resolve) => {
            accessSettingsApi.deleteUnit(payload.id).then(res=>{
                resolve(res)
            })
        })
    },
}

const mutations = {
    setAllActions(state, payload) {
        state.allActions = payload
    },
    setAllRoles(state, payload) {
        state.allRoles = payload
    },
    SET_MODAL_VISIBILITY(payload) {
        this.modalVisibly = payload
    },
    setGroupsByObject(state, payload) {
        state.groupsById = payload
    },
    setUsersById(state, payload) {
        state.usersInfoById = payload
    },
    setIsAccessSettings(state, payload){
        state.isAccessSettings = payload
    },
    setAccessUsersInModal(state, payload){
        state.accessUsersInModal=payload
    },
    setAccessUsersInGroups(state, payload){
        state.accessUsersInGroups=payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import moment from 'moment'
export default function defaultValueDate() {
  const endDate = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate = moment(endDate)
      .subtract(7, 'days')
      .format('YYYY-MM-DD HH:mm:ss')
  return [startDate, endDate]
}
function defaultValueDateEndDay(format = '00:00:00') {
  let hour = 23
  let minute = 59
  let second = 59

  if (format === '00:00:00') {
    hour = 0
    minute = 0
    second = 0
  }
  
  const endDate = moment()
      .add(1, 'days')
      .set({ hour, minute, second })
      .format('YYYY-MM-DD HH:mm:ss')

  const startDate = moment()
    .set({ hour, minute, second })
    .subtract(7, 'days')
    .format('YYYY-MM-DD HH:mm:ss')
  
  return [startDate, endDate]
}
export {defaultValueDateEndDay}


/**
 * Метод для копирования строки в буфер обмена
 * @param {string} str - Строка для копирования
 * @returns {void}
 */

export async function copyTextToClipboard(str) {
  try {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);

    const selected = document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
    el.select();

    if (!navigator.clipboard) {
      document.execCommand('copy');
    } else {
      await navigator.clipboard.writeText(str);
    }

    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  } catch (error) {
    console.error(error);
  }
}

import { dictionaryApi } from '@/api'
import eventBus from '@/eventBus.js'

const defaultState = {
  languages: [],
  timezones: [],
  params: [],
  customfield_groups: [],
  notification_type: [],
  color_code: [],
  adminModules: [],
  //  new requests
  weekdays_type: [],
  periodic_type: [],
  report_template_table_grouping: [],
  report_template_table_type: [],
  terminal_type: [],
  unit_type: [],
  unit_icon: [],
  sensor_type: [],
  template_sensor_value_type: [],
  validator_sensor_type: [],
  formula: [],
  geo_icon: [],
  geozone_type: [],
  trip_activation_type: [],
  trip_passage_order_type: [],
  event_type: [],
  notify_sounds: [],
  command_type: [],
  geozones_control: [],
  sensor_values: [],
  trigger_duration_type: [],
  sensor_filter_type: []
}
//  'gps_category_type public_vehicle_type ',
// 'public_vehicle_type'
const getters = {
  gps_category_type: (state) => {
    return state.gps_category_type
  },
  public_vehicle_type: (state) => {
    return state.public_vehicle_type
  },
  unitIcon: (state) => {
    return state.unit_icon
  },
  geozonesControl: (state) => {
    return state.geozones_control
  },
  unit_sounds: (state) => {
    return state.notify_sounds
  },
  tableGrouping: (state) => {
    return state.report_template_table_grouping
  },
  getParamsByKey: (state) => (key) => {
    return state.params[key]
  },
  cfGroupsKeys: (state) => {
    return state.customfield_groups.map((cf) => cf.key)
  },
  groupCustomFieldsByUnit: (state) => (unitGroupsArr) => {
    return state.customfield_groups.filter((cfg) =>
      unitGroupsArr.includes(cfg.key)
    )
  },
  getGeozoneType: (state) => (typeKey) => {
    return state.geozone_type.find((type) => type.key === typeKey).value
  },
  getGeozoneIcon: (state) => (iconKey) => {
    return state.geo_icon.find((icon) => icon.key === iconKey).value
  },
  terminalType: (state) => {
    return state.terminal_type
  },
  params: (state) => {
    return state.params
  },
  protocol: (state) => {
    return state.retranslator_protocols
  },
  adminModules: (state) => {
    return state.adminModules
  },
  unitTypes: (state) => state.unit_type,
  sensorTypes: (state) => state.sensor_type,
  formulaTypes: (state) => state.formula,
  getSensorValues: (state) => state.sensor_values,
  triggerDuration: (state) => state.trigger_duration_type,
  filterTypes: (state) => state.sensor_filter_type
}

const actions = {
  getAllDictionary({ commit }) {
    dictionaryApi.getAllDictionary(
      function (response) {
        commit('SET_ALLDICTIONARIES', response.data)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
        console.log('error getAllDictionary, dictionarty.js')
      }
    )
  },

  getParamsUnitSensor({ commit }) {
    dictionaryApi.getParamsForUnit(
      (response) => {
        const paramsObject = {}
        for (const param of response.data) {
          paramsObject[Object.keys(param)] = Object.values(param)[0]
            .split(', ')
            .map((item) => {
              return { value: item, label: item }
            })
        }
        commit('SET_PARAMS_UNIT_SENSOR', paramsObject)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  GET_ADMIN_MODULES({ commit }) {
    dictionaryApi.getAdminModules(
      (response) => {
        commit('SET_ADMIN_MODULES', response.data)
        console.log('admin moduls', response.data)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  getLangs({ commit }) {
    dictionaryApi.getDictionaryByType(
      'lang',
      (response) => {
        commit('SET_DICTIONARY', {
          dictionaryName: 'lang',
          values: response.data
        })
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  getDictionaryByType({ commit }, type) {
    dictionaryApi.getDictionaryByType(
      type,
      function (response) {
        commit('SET_DICTIONARY', {
          dictionaryName: type,
          values: response.data
        })
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  GET_NOTIFY_TYPES({ commit }) {
    dictionaryApi.getDictionaryByType(
      'notification_type',
      (res) => {
        commit('SET_NOTIFY_TYPE', res.data)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  GET_COLOR_CODES({ commit }) {
    dictionaryApi.getDictionaryByType(
      'color_code',
      (res) => {
        commit('SET_COLOR_CODES', res.data)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  getDictionaryByTypeList({ commit }, list) {
    const arrayList = []
    list.forEach((type) => {
      arrayList.push(actions.getDictionaryByType({ commit }, type))
    })
    return Promise.all(arrayList)
  }
}

const mutations = {
  SET_ALLDICTIONARIES(state, dics) {
    state.geozones_control = dics.filter(
      (el) => el.type === 'controle_geozones'
    )
    state.weekdays_type = dics.filter((el) => el.type === 'weekdays_type')
    state.periodic_type = dics.filter((el) => el.type === 'periodic_type')
    state.report_template_table_grouping = dics.filter(
      (el) => el.type === 'report_template_table_grouping'
    )
    state.report_template_table_type = dics.filter(
      (el) => el.type === 'report_template_table_type'
    )
    state.terminal_type = dics.filter((el) => el.type === 'terminal_type')
    state.command_type = dics.filter((el) => el.type === 'command_type')
    state.command_type = dics.filter((el) => el.type === 'command_type')
    state.unit_type = dics.filter((el) => el.type === 'unit_type')
    state.unit_icon = dics.filter((el) => el.type === 'unit_icon')
    state.sensor_type = dics.filter((el) => el.type === 'sensor_type')
    state.template_sensor_value_type = dics.filter(
      (el) => el.type === 'template_sensor_value_type'
    )
    state.formula = dics.filter((el) => el.type === 'formula')
    state.notify_sounds = dics.filter((el) => el.type === 'notify_sounds')
    state.geo_icon = dics.filter((el) => el.type === 'geo_icon')
    state.geozone_type = dics.filter((el) => el.type === 'geozone_type')
    state.customfield_groups = dics.filter(
      (el) => el.type === 'customfield_groups'
    )
    state.admin_modules = dics.filter((el) => el.type === 'admin_modules')
    state.color_code = dics.filter((el) => el.type === 'color_code')
    state.notification_type = dics.filter(
      (el) => el.type === 'notification_type'
    )
    state.notification_time_control = dics.filter(
      (el) => el.type === 'notification_time_control'
    )
    state.trigger_duration_type = dics.filter(
      (el) => el.type === 'trigger_duration_type'
    )
    state.trip_activation_type = dics.filter(
      (el) => el.type === 'trip_activation_type'
    )
    state.trip_passage_order_type = dics.filter(
      (el) => el.type === 'trip_passage_order_type'
    )
    state.adminModules = dics.filter((el) => el.type === 'admin_modules')
    state.event_type = dics.filter((el) => el.type === 'event_type')
    state.sensor_values = dics.filter(
      (el) => el.type === 'report_column_total_type'
    )
    state.gps_category_type = dics.filter(
      (el) => el.type === 'gps_category_type'
    )
    state.public_vehicle_type = dics.filter(
      (el) => el.type === 'public_vehicle_type'
    )
    state.trigger_duration_type = dics.filter(
      (el) => el.type === 'trigger_duration_type'
    )
    state.sensor_filter_type = dics.filter(
      (el) => el.type === 'sensor_filter_type'
    )
  },
  SET_PARAMS_UNIT_SENSOR(state, params) {
    state.params = params
  },
  SET_ADMIN_MODULES(state, adminModules) {
    state.adminModules = adminModules
  },

  SET_DICTIONARY(state, { dictionaryName, values }) {
    state[dictionaryName] = values
  },
  // КОСТЫЛЬ ИЗ-ЗА ТОГО ЧТО ГРУППЫ МОЖЕТ И НЕ БЫТЬ НУЖНЫх
  ADD_GROUPCUSTOMFIELDS(state, customfieldGroups) {
    state.customfield_groups = [
      ...state.customfield_groups,
      ...customfieldGroups
    ]
  },
  SET_NOTIFY_TYPE(state, notifyTypes) {
    state.notification_type = notifyTypes
  },
  SET_COLOR_CODES(state, colorCodes) {
    state.color_code = colorCodes.map((color) => {
      return color.key === '#1BB934'
        ? { ...color, key: '#4ABC96' }
        : color.key === '#1585D8'
        ? { ...color, key: '#2F80ED' }
        : color
    })
  }
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations
}

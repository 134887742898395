import Vue from 'vue'
import Vuex from 'vuex'
import login from '@/store/modules/login.js'
import dictionary from '@/store/modules/dictionary.js'
import properties from '@/store/modules/properties.js'
import map from '@/store/modules/map.js'
import users from '@/store/modules/users.js'
import units from '@/store/modules/units.js'
import reports from '@/store/modules/reports.js'
import monitoring from '@/store/modules/monitoring.js'
import trackPlayer from '@/store/modules/trackPlayer.js'
import geozones from '@/store/modules/geozones.js'
import notifications from '@/store/modules/notifications.js'
import trailers from '@/store/modules/trailers.js'
import locators from '@/store/modules/locators.js'
import measure from '@/store/modules/measure.js'
import races from '@/store/modules/races.js'
import routes from '@/store/modules/routes.js'
import mailings from '@/store/modules/mailings.js'
import company from '@/store/modules/company.js'
import events from '@/store/modules/events.js'
import billing from '@/store/modules/billing.js'
import dealers from '@/store/modules/dealers.js'
import terminal from '@/store/modules/terminal.js'
import ports from '@/store/modules/ports.js'
import helpTools from '@/store/modules/helpTools.js'
import commands from '@/store/modules/commands.js'
import wialon from '@/store/modules/wialon.js'
import geozonesKml from '@/store/modules/geozonesKml.js'
import monitoringNew from '@/store/modules/monitoringNew.js'
import payments from '@/store/modules/payments.js'
import integrators from '@/store/modules/integrators.js'
import accessSettings from '@/store/modules/accessSettings.js';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    dealers,
    billing,
    login,
    trackPlayer,
    dictionary,
    properties,
    map,
    users,
    units,
    reports,
    monitoring,
    geozones,
    notifications,
    measure,
    trailers,
    locators,
    races,
    routes,
    mailings,
    company,
    events,
    terminal,
    ports,
    helpTools,
    commands,
    wialon,
    geozonesKml,
    monitoringNew,
    payments,
    integrators,
    accessSettings,
  }
})

export default store
export const useStore = () => store

const currentUrl = window.location.href
const translation = {
  'translation.key': 'value',
  'loginPage.login.title': 'Entrance',
  'loginPage.login.submit': 'Enter',
  'loginPage.login.guest': 'Demo version',
  'loginPage.login': 'Login',
  'loginPage.email': 'E-mail',
  'loginPage.pass': 'Password',
  'loginPage.timeZone': 'Time zone',
  'loginPage.lang': 'Language',
  'loginPage.userName': 'User name',
  'loginPage.nameCompany': 'Company name',
  'loginPage.textTermsOfUse':
    'By clicking "Register" You accept the terms and conditions ',
  'loginPage.linkTermsOfUse': '"User agreement"',
  'name.column': 'Column name',
  'valid.text': 'Fill in the field',
  back: 'ago',
  'come.back': 'Come back',
  'pixels.group': 'screens pixels',

  // Registration
  'loginPage.register.title': 'Registration',
  'loginPage.register.submit': 'Register',
  'hide.all.text': 'Hide all elements',
  'hide.all': 'All elements',

  // Password change
  'loginPass.changePass.adminPass':
    'Enter the password you received from the administrator',
  'loginPass.recovery.title': 'Password recovery',
  'loginPass.changePass.title': 'Password change',
  'loginPass.changePass.old_pass': 'Old password',
  'loginPass.changePass.newPass': 'New password',
  'loginPass.changePass.newPass_confirm': 'New password again',
  'loginPass.changePass.inputPass': 'Enter the password',
  'loginPass.changePass.inputNewPass': 'Enter a new password',
  'loginPass.changePass.confirmNewPass': 'Сonfirm password',
  'loginPass.changePass.current': 'Current password',
  'loginPass.changePass.empty':
    "Leave it blank if you don't know the password.",
  'loginPass.changePass.repeatNewPass': 'Repeat password',
  'loginPass.changePass.submit': 'Enter',
  'loginPass.errors.pass': 'Enter the password',
  'loginPass.errors.newPass': 'Enter your password again',
  'loginPass.errors.notMatchPass': "The passwords you entered don't match",
  'loginPass.errors.lengthPass':
    'The password must be between 5-25 characters long',

  // The choice of the company
  'loginPage.company.title': 'The choice of the company',
  'loginPage.company.name': 'Company name',
  'loginPage.company.submit': 'Enter',

  // Confirmation
  'loginPage.confirm.title': 'Confirmation',
  'loginPage.confirm.text1': 'The user is registered.',
  'loginPage.confirm.text2':
    'To confirm your registration, follow the link in the mail sent to the specified mail address. ',
  'loginPage.confirm.submit': 'On the main',

  // Qr code
  'loginPage.passHint': 'Your password is at least 4 characters long.',
  'loginPage.recovery.toMain': 'To Main',
  'loginPass.recovery.hint':
    'An e-mail with a link to recover your password will be sent to the specified e-mail address',
  'loginPass.recovery.content':
    'To recover your account, please follow the link in the email sent to your email',
  'loginPage.haveAccount': 'У меня есть учетная запись ',
  'loginPage.sixCode': 'шестизначный код:',
  'loginPage.carName': 'название машины',

  'loginPage.autoType': 'Тип авто:',
  'loginPage.autoName': 'Имя авто:',
  'loginPage.addObject': 'Добавить объект',
  'loginPage.dontHaveAccount': 'Я новый пользователь SKIF.PRO2',
  'loginPage.enterCode': 'Введи шестизначный код:',
  'loginPage.phoneHolder': 'телефон в формате +XXXXXXXXXXXX',
  'loginPage.mail': 'Ваш email',
  'loginPage.password': 'Пароль:',
  'loginPage.yourpassword': 'Ваш пароль',
  'loginPage.offer': 'оферте',
  'loginPage.accept': ' Я принимаю условия изложенные в ',
  'loginPage.register': 'Зарегистрироваться',
  'loginPage.orgName': 'Название вашей организации:',
  'loginPage.orgName.holder': ' название организации',
  'loginPage.timeZones': 'Часовой пояс:',
  'loginPage.name': 'Ваше имя',
  'loginPage.name.label': 'Имя:',
  'loginPage.back': 'Назад',
  'loginPage.validation.sixCode': 'Вы должны ввести шестизначный код:',
  'loginPage.validation.codeType':
    'шестизначный код должен состоять из заглавных английских букв и цифр',
  'loginPage.validation.choosecartype': 'Выберите тип машины',
  'loginPage.validation.tiemzone': 'Выберите  часовой пояс',
  'loginPage.validation.entercarname': 'Введите имя машины',
  'loginPage.validation.name': ' Введите Ваше имя',
  'loginPage.validation.telephone': 'Введите Ваш телефон',
  'loginPage.validation.email': 'Введите Ваш email',
  'loginPage.validation.password': 'Введите Ваш пароль',
  'loginPage.validation.email.correctform':
    'введите правильный адрес электронной почты',
  'loginPage.validation.enter6code': 'Вы должны ввести шестизначный код',
  'loginPage.validation.acceptTerms': 'пожалуйста, примите условия',

  // Restoration
  'loginPass.recovery.back': 'Back',
  'loginPage.recovery.title': 'Restoration',
  'loginPage.recovery.text1': 'To restore account, go to',
  'loginPage.recovery.text2':
    'follow the link in the mail sent to the specified mail address.',

  // Links
  'loginPage.links.ifHaveNotAccount': 'No account?',
  'loginPage.links.ifHaveAccount': 'Have an account?',
  'loginPage.links.register': 'Registration',
  'loginPage.links.lostPass': 'Forgot password?',
  'loginPage.links.login': 'Enter',

  // Mail errors
  'loginPage.errors.email': 'Enter your username or email address',
  'loginPage.errors.emailError': 'Enter existing email address',
  'loginPage.errors.pass': 'Enter the password',
  'loginPage.errors.name': 'Enter a name',
  'loginPage.errors.nameCompany': 'Enter the company name',
  'loginPage.errors.region': 'Choose a region',

  // Timezone
  'loginPage.hint.timeZone': 'Select time zone',
  'loginPage.hint.lang': 'Select language',
  'loginPage.hint.companyName': 'Select company',

  // Buttons
  'loginPage.btn.send': 'Send',

  // Telegram
  'loginPage.telegramChatId': 'Telegram chat id',
  'loginPage.errors.telegramChatId': 'Enter valid telegram chat id',
  'loginPage.enterTheTelegramBot': 'Enter the telegram bot',
  'loginPage.clickOnStartTheBot-':
    'Click on Start, the bot will return the Telegram mailing ID',

  // Buttons on the map
  logout: 'Logout',

  // Help guide
  'guide.title': 'Guide',
  'guide.short_instruction': 'Brief instructions',
  'guide.quiestion_offer': 'QuestionOffer',
  'guide.instructions': 'Instructions',
  'guide.back': 'Back',
  'guide.personal_account': "Dealer's personal account",
  'guide.company_workplace': 'Workplace of the company',

  // Feedback
  'feedback.question_offer': 'QuestionOffer',
  'feedback.description': 'Share with us your experience of using our product',
  'feedback.yours': 'Your feedback',
  'feedback.fill_area': 'Fill in the field',
  'feedback.send': 'Send',
  'feedback.message_send':
    'Your request has been sent. Thank you for contacting us!',

  // Menu
  'menuSelect.reports': 'Reports',
  'menuSelect.monitoring': 'Monitoring',
  'menuSelect.geozones': 'Geographic zones',
  'menuSelect.notifications': 'Notifications',
  'menuSelect.users': 'Users',
  'menuSelect.routes': 'Routes',
  'menuSelect.objects': 'Units',
  'menuSelect.race': 'Races',

  // Map
  'map.search': 'Finding the exact address',
  'map.adrress_not_found': 'Address not found',
  'map.title': 'Map',

  delete: 'Delete',
  noMatch: 'No matches were found',
  'monitoring.filterMax': 'More than 30 filters can be addefd',
  // Users
  'catalog.user': 'User catalog',
  'btn.select': 'Select',
  'btn.editlist': 'EDIT THE LIST',
  'btn.finisheditlist': 'FINISHED EDIT LIST',
  'btn.delete': 'Delete',
  'btn.cancel': 'Cancel',
  'btn.create': 'Create',
  'btn.update': 'Update',
  'btn.save': 'Save',
  'user.invite_user': 'Invite',
  'user.invite_all': 'Invite to all companies',
  'user.invite_email': 'Email',
  'user.invite_role': 'Role',
  'user.invite_role_error': 'Enter the role',
  'user.enter_language': 'Enter the language',
  'user.history_change': 'History change',
  'user.create_user': 'Create user',
  'user.edit_user': 'Edit user',
  'users.hint.role': 'Select the user role',
  'users.hint.lang': 'Select language',
  'users.subscribe': 'Invite a user',
  'users.approve_close_form':
    'Changes are not saved. Do you really want to close your profile without saving it?',
  'users.close_form': 'Close the profile',
  'users.invite': 'Invite',
  'users.invite_all': 'Invite to all companies',
  'users.name': 'Name',
  'users.email': 'Email',
  'users.change_password_title': 'Change password',
  'users.sessions': 'Sessions',
  'users.enter': 'Login',
  'users.exit': 'Logout',
  'users.change_password': 'Change',
  'users.current_password': 'Current password',
  'users.invite_level_access': 'Access level',
  'users.email_placeholder': 'Enter your email address',
  'users.password_empty': "Leave it blank if you don't know the password.",
  'users.isDriver': 'Driver',
  'users.invalid_email': 'The e-mail is not confirmed.',
  'users.confirm_notification':
    'The user will not receive notifications by e-mail until until you confirm the email.',
  'users.confirm': 'Confirm',
  'users.invalid_phone': 'The phone number is not confirmed.',
  'users.confirm_message':
    'will be sent a letter with a request to confirm consent to receive information messages from the system SKIF.PRO .',
  'users.confirm_link':
    'will be sent a letter with a link. Follow this link to confirm your consent to receive informational SMS from the system SKIF.PRO .',
  'users.success_email_send': 'The email has been sent successfully.',
  'users.success_message_send': 'The message has been sent successfully.',

  name: 'Name',

  // Geozones
  'geozones.square': 'Square:',
  'geozones.library': 'Geozones library',
  'geozones.create': 'Adding geozones',
  'geozones.edit': 'Editing a geozone',
  'geozones.add': 'Add',
  'geozones.color': 'Color',
  'geozones.name': 'Name',
  'geozones.figure': 'Figure',
  'geozones.type': 'Type',
  'geozones.width': 'Width',
  'geozones.editor': 'Editor',
  'geozones.delete': 'Delete',
  'geozones.use_as_address': 'Use as address',
  'geozones.unitArea': 'Units of Measurement of Area:',
  'geozones.meter': 'M',
  'geozones.km': 'Km',
  'geozones.hectar': 'Ha',
  'geozones.red': 'Красный',
  'geozones.orange': 'Оранжевый',
  'geozones.blue': 'Синий',
  'geozones.grey': 'Серый',
  'geozones.green': 'Зеленый',

  geozones: {
    errors: {
      min_dots_polygon: 'You need at least 3 points for the polygon!',
      min_dots_line: 'You need at least 2 points for the line!',
      put_marker: 'Put a marker on the map!',
      draw_circle: 'Draw a circle!',
      name: 'Enter a name!',
      width: 'Enter the correct width value!'
    }
  },
  // Сессии
  'sessions.title': 'Sessions',
  'sessions.day': 'Days',
  'sessions.week': 'Week',
  'sessions.month': 'Month',
  'sessions.time': 'Date/Time',
  'sessions.user': 'User',
  'sessions.event': 'Events',
  'sessions.ip': 'IP Adress',
  // Objects
  'objects.total.group': 'Number of objects',
  'objects.add.objects': 'Add objects',
  'objects.add.objects.in.group': 'Add objects in group',
  objects: 'Object',
  object: 'Object',
  'objects.showmonitoring': 'Show in monitoring',
  'objects.name': 'Enter a name!',
  'objects.objects': 'Objects',
  'objects.groups': 'Groups',
  'objects.groups.objects-in-group': 'Objects in a group',
  'objects.groups.access': 'Group access rights',
  'objects.groups.access-title': 'Group rights',
  'objects.groups.close-all': 'Fold all',
  'objects.trailers': 'Trailers',
  'objects.locators': 'Locators',
  'objects.locators-date-start': 'Date and time access starts',
  'objects.locators-date-end': 'Date and time access ends',
  'objects.locators-date-select': 'Select date',
  'objects.locators-link': 'Link',
  objects_locator_create: 'Create an access link',
  objects_locator_change: 'Link editing',
  'objects.locator.copy-link': 'Link copied',
  'objects.locator.status': 'Status',
  'objects.locator.access-object': 'Access object',
  'objects.users': 'Users',
  'objects.delete': 'Delete',
  'objects.create.new.group': 'Group creation',
  'objects.create.edit.group': 'Editing a group',
  'objects.add.units.to.group': 'Add objects to a group',
  'objects.name.group': 'Group name',
  'objects.title': 'Object reference',
  'objects.titleTrailers': 'Trailer reference',
  'objects.titleLocators': 'Locator reference',
  'objects.titlegroups': 'Object group reference',
  'objects.name.number': 'Name/Number',
  'objects.type': 'Object type',
  'objects.unit': 'Unit',
  'objects.device': 'Device type',
  'objects.imei': 'Unique IMEI',
  'objects.editImei': 'Edit the selected IMEI',
  'units.create': 'Creation unit',
  'units.update': 'Update unit',
  'objects_tabs.main': 'Main',
  'objects_tabs.extra': 'Extra',
  'objects_tabs.sensor': 'Sensors',
  'objects_tabs.TO': 'Maintenance',
  'objects_tabs.main.analyst': 'Analyst',
  'objects_tabs.main.name.error': 'Enter Name/Number',
  'objects_tabs.main.type.error': 'Select the object type',
  'objects_tabs.main.device.error': 'Enter the device type',
  'objects_tabs.main.id.error': 'Enter a unique ID',
  'objects_tabs.main.phone.error': 'Enter phone',
  'objects_tabs.main.analyst.error': 'Select analytics',
  'objects_tabs.extra.motohours': 'Motohours',
  'objects_tabs.extra.trips': 'Trips',
  'objects_tabs.extra.fuel': 'Fuel',
  'objects_tabs.extra.etc': 'Extra',
  'objects_tabs.extra.custom': 'Custom',
  'objects_tabs.extra.name': 'Name',
  'objects_tabs.extra.value': 'Value',
  'objects_tabs.extra.addNewField': 'Add new field',
  'objects_tabs.extra.changeField': 'Change field',
  'objects_tabs.extra.update_field': 'Update field',
  'objects_tabs.sensors.units': 'Unit',
  'objects_tabs.sensors.hide_in_monitoring': 'Hide in monitoring',
  'objects_tabs.sensors.sensors_create': 'Creating a sensor',
  'objects_tabs.sensors.sensors_edit': 'Editing a sensor',
  'objects_tabs.sensors.type_sensor': 'Sensor type',
  'objects_tabs.sensors.error_type_sensor': 'Choose sensor type!',
  'objects_tabs.sensors.error_formula': 'Choose formula!',
  'objects_tabs.sensors.error_param12': 'Choose param1 or param2!',
  'objects_tabs.sensors.option1': 'Option 1',
  'objects_tabs.sensors.option2': 'Option 2',
  'objects_tabs.sensors.formula': 'Formula',
  'objects_tabs.sensors.formula.columns': 'Column formula',
  'objects_tabs.sensors.hide_in_reports': 'Hide in reports',
  'objects_tabs.sensors.description': 'Description',
  'objects_tabs.sensors.border_bottom': 'Border bottom',
  'objects_tabs.sensors.coefficientA': 'Coefficient А',
  'objects_tabs.sensors.coefficientB': 'Coefficient B',
  'objects_tabs.sensors.valueX': 'Value X',
  'objects_tabs.sensors.valueY': 'Value Y',
  'objects_tabs.sensors.enterValue': 'Enter a value!',
  'objects_tabs.sensors.keyExist': 'This key already exists!',
  'objects_tabs.sensors.upperBorder': 'Upper border X',
  'objects_tabs.sensors.lowerBorder': 'Lower border X',
  'objects_tabs.sensors.smoothing': 'Smoothing',
  'objects_tabs.sensors.coefficient': 'Coefficient',
  'objects_tabs.sensors.selectValueX': 'Select the value of x!',
  'objects_tabs.sensors.selectValueY': 'Select a value !',
  'objects_tabs.sensors.selectFromValue': 'Select fromValue!',
  'objects_tabs.sensors.selectA': 'Select the value a!',
  'objects_tabs.sensors.selectB': 'Select the value b!',
  'objects_tabs.sensors.actions': 'Actions',
  'objects_tabs.sensors.show_graph': 'Show graph',
  'objects_tabs.sensors.show_table': 'Show table',
  'objects_tabs.drivers.driver': 'Driver',
  'objects_tabs.drivers.from': 'From',
  'objects_tabs.drivers.to': 'To',
  'objects_tabs.drivers.add': 'Add driver',
  'objects_tabs.drivers.change': 'Change driver',
  'objects_tabs.trailer': 'Trailer',
  'objects_tabs.trailers': 'Trailers',
  'objects_tabs.trailer.add': '"Add trailer',
  'objects_tabs.trailer.change': 'Change trailer',
  'trailer.width': 'Trailer width (meter)',

  'objects_tabs.services.create_form': 'Create TO',
  'objects_tabs.services.edit_form': 'Edit TO',
  'objects_tabs.services.name_service': 'Service',
  'objects_tabs.services.description': 'Description',
  'objects_tabs.services.active_on_interval_mileage':
    'To activate the interval by mileage',
  'objects_tabs.services.active_on_interval_days':
    'To activate the interval in days',
  'objects_tabs.services.interval_days_numb_day':
    'The interval in days, the number/days',
  'objects_tabs.services.last_one_date': 'Last time, date',
  'objects_tabs.services.interval_mileage_numb_km':
    'Interval according to mileage, number/km',
  'objects_tabs.services.last_one_numb_km': 'Last time, number/km',
  'objects_tabs.services.interval_mileage': 'Mileage',
  'objects_tabs.services.interval_days': 'Days',
  'objects_tabs.services.warning.name': 'Enter service name!',
  'objects_tabs.services.warning.description': 'Enter a description!',
  'objects_tabs.services.warning.date': 'Enter the date!',
  'objects_tabs.services.date.placeholder': 'Enter the date!',

  'objects_tabs.services.mileageInterval': 'Enter the mileage interval!',
  'objects_tabs.services.lastTime': 'Enter the last time!',
  'objects_tabs.services.intervalInDays': 'Enter interval in days!',
  'objects_tabs.services.intervalInMotoHours':
    'Activate motorcycle hourly interval',
  'objects_tabs.services.intervalInMotoHoursDate':
    'Motorcycle hourly interval, date/time',
  'objects_tabs.services.lastTimeDate': 'Last time, date/time',
  'objects_tabs.services.lastTimeDate.warning': 'Enter the last time!',
  objects_trailer_create: 'Create trailer',
  objects_trailer_change: 'Edit trailer',
  objects_trailer_moreFields: 'Additional fields',
  objects_trailer_key: 'Key',
  objects_phone1: 'Phone 1',
  objects_phone2: 'Phone 2',
  objects_server: 'Data receiving server: ',
  objects_port: 'Port: ',
  objects_noPicture: 'Image is not selected',
  'objects.fillfield':
    'It is necessary to fill in the fields in the Main section',

  // events
  'events.create': 'Create event',
  'events.update': 'Update event',
  'events.datetime': 'Date/Time',
  'events.deviation_minutes': 'Deviation, min.',
  'events.filled': 'Fueled with fuel, l.',
  'events.consumed': 'Fuel consumption, l.',
  'events.price': 'The cost',
  'events.description': 'Description',
  'events.stolen': 'Fuel drained, l.',
  'events.service_name': 'Service',
  'events.motohours': 'Motohours h.',
  'events.millage': 'Mileage km.',
  'events.duration_hours': 'Duration h.',
  'events.guaranteed': 'Guaranteed',
  'events.start_date': 'Start',
  'events.finish_date': 'Finish',
  'events.user_id': 'Driver',
  'events.type_name': 'Type name',
  'events.duration_minutes': 'Duration minutes',
  'events.type_duration_hours': 'Duration hrs',
  'events.requiredFields': 'Required fields',

  'events.validation.required': 'Required field',
  'events.validation.datetime': 'Enter date time',
  'events.validation.number': 'Must be a number more 0',

  // Reports
  reports: 'Reports',
  'reports.chart.intervals': 'Intervals',
  'reports.chart.thefts_markers': 'Drain Markers',
  'reports.chart.fillings_markers': 'Fillings Markers',
  'reports.chart.thefts': 'Thefts',
  'reports.chart.fillings': 'Fillings',
  'reports.build-report': 'Build',
  'reports.base-table': 'Base table',
  'reports.group-table': 'Table group',
  'reports.select-table': 'Select table',
  'reports.select-template': 'Template selection tables',
  'reports.select-object': 'Select vehicle',
  'reports.select-group-object': 'Selecting a group of objects',
  'reports.not-select': 'Not selected',
  'reports.object': 'Object',
  'reports.group-object': 'Object group',
  'reports.period': 'Period',
  'reports.reports-visit-geozone': 'Report on visiting geozones',
  'reports.reports-size-screen.minimum': '1/4',
  'reports.reports-size-screen.thirdScreen': '1/3',
  'reports.reports-size-screen.medium': '1/2',
  'reports.reports-size-screen.maximum': 'Full screen',
  'reports.was-deleted': 'was deleted ',
  'reports.add-group-report': 'Add to template',
  'reports.edit-report': 'Edit',
  'reports.load-report': 'Download',
  'reports.print-report': 'Print',
  'reports.show-color': 'Track color',
  'reports.сomposite_report': 'Composite report',
  'reports.map_snapshot': 'Map snapshot',
  'reports.deleted_messaged': 'Deleted messages',
  'reports.map_note': 'Click to see a point on the map',
  'reports.show-chart': 'Chart',
  'reports.action': 'Actions',
  'reports.edit-reports': 'Edit report settings',
  'reports.table.type': 'Тип Таблицы',
  'reports.add-table-to-reports': 'Add table from template',
  'reports.reports-template': 'Edit template',
  'reports.tab-main': 'General',
  'reports.tab-columns': 'Columns',
  'reports.tab-geozones': 'Geozones',
  'reports.tab-main.group': 'Grouping',
  'reports.tab-main.group-field': 'Grouping field',
  'reports.tab-main.show-total': 'Show summary',
  'reports.tab-main.interval-priority': 'Report interval priority',
  'reports.tab-main.interval-priority-tooltip': `If this setting is active and the interval is cut by the beginning or end of the Shift, then it will be included in the report completely.
    <br>
    If this setting is not active, then the exact Shift interval will be included in the report.
    <br>
    For example, if the shift starts at 08:00 and the trip starts at 06:00,
    <br>
    then in case of active setting “Priority of report intervals”, the interval in the Trip report will start at 06:00,
    <br>
    and if the “Priority of report intervals” setting is not active, then at 08:00.`,
  'reports.editTemplate.nameTemplate': 'Template name',
  'reports.add-new-template': 'Add new template',
  'reports.name-template': 'Template name',
  'reports.add-in-template': 'Add to template',
  'reports.is-already-template': 'Existing',
  'reports.new-temlate': 'New',
  'reports.templateWarning': 'Enter a template name!',
  'reports.tables': 'Tables',
  'reports.onlyResult': 'Show only totals',
  'reports.connect': 'To associate with the Schedule',
  'reports.attached': 'File attached',
  'reports.tables.report': 'Report',
  'reports.column': 'Column',
  'reports.operation': 'Operation',
  'reports.value': 'Value',
  'reports.contains': 'Contains',
  'reports.success_copy_template': 'The template has been copied successfully.',
  'reports.create_template': 'Create template',
  'reports.edit_template': 'Edit template',
  'reports.enter_name': 'Enter name!',
  'reports.template': 'Template',
  'reports.without_grouping': 'Without grouping',
  'reports.form_for_unloading': 'Form for uploading (excel template)',
  'reports.show_key': 'View the list of keys for the Excel template',
  'reports.tables.tabs.general': 'General',
  'reports.tables.tabs.columns': 'Columns',
  'reports.tables.tabs.sensors': 'Sensors',
  'reports.tables.tabs.additional_columns': 'Additional Columns',
  'reports.tables.tabs.data': 'Data',
  'reports.tables.tabs.hint':
    'In closed reports, the user cannot change the sensors and parameters by which it is built.',
  'reports.tables.tabs.mask_sensor': 'Sensor masks',
  'reports.tables.tabs.mask_sensor_hint':
    'To generate a report, those object sensors are selected that will satisfy at least one of the specified masks (read more ',
  'reports.tables.tabs.type_table': 'Table type:',
  'reports.tables.tabs.table_name': 'Name:',
  'reports.tables.tabs.all_protocol': 'All protocols',
  'reports.tables.tabs.parameters': 'Select parameters',
  'report.tables.tabs.name_mask': 'Sensor name mask',
  'report.tables.tabs.enter_value': 'Enter value',
  'report.tables.tabs.add_mask': 'Add a mask by sensor name',
  'report.tables.tabs.parameters': 'Parameters',
  'report.tables.tabs/parameter_hint':
    'Select the names of the parameters that will be involved in generating the report.',
  'report.tables.tabs.parameter': 'Parameter',
  'report.tables.tabs.add_parameter': 'Add parameter',
  'reports.tables.tabs.make_close_report': 'Make the report private',
  'reports.tables.tabs.geozones': 'Geozones',
  'reports.tables.fields.name': {
    label: 'Name',
    error: 'Enter name'
  },
  'reports.tables.fields.grouping': 'Grouping',
  'reports.tables.fields.grouping_by_field': 'Grouping by field',
  'reports.tables.fields.sensor_name_mask': 'Sensor name mask',
  'reports.tables.fields.template_upload': 'Upload',
  'reports.tables.fields.files_warn':
    'You can only upload files in xlsx  xlsm  xlsb formats',
  'reports.tables.fields.excel_template_name': 'Excel Template Name',
  'reports.tables.fields.geozone_warn':
    'Требуется выбрать хотя бы одну геозону входа и одну геозону выхода.',
  'reports.tables.fields.showfillings': 'Show fillings',
  'reports.tables.fields.showThefts': 'Show thefts',
  'reports,tables.fields.mask_name_sensor': 'Fuel sensor name mask',
  'reports.tables.fields.add_mask': 'Add a mask by sensor name',
  'reports.tables.geozones.title': 'Geozones',
  'reports.tables.geozones.enter': 'Enter',
  'reports.tables.geozones.exit': 'Exit',
  'reports.tables.columns.fields.column': 'Column',
  'reports.tables.columns.fields.visibility': 'Visibility',
  'reports.tables.columns.fields.filter': 'Filter',
  'reports.tables.columns.fields.min_value': 'Min. value',
  'reports.tables.columns.fields.max_value': 'Max. value',
  'reports.tables.columns.fields.name.label': 'Column name',
  'reports.tables.columns.fields.name.error': 'Enter name',
  'reports.download_selected': 'Download selected',
  'reports.from_monitoring': 'from Monitoring',
  'reports.applied_filter': 'Filtering from Monitoring has been applied.',
  'reports.not_all_objects_displayed': 'Not all objects are displayed.',
  'reports.show_all_objects': 'Show all objects',
  'reports.select_object': 'Select object!',
  'reports.select_objects_group': 'Select a group of objects!',
  'reports.template_deletion': 'Deleting a template',
  'reports.template_deletion_sure':
    'Do you really want to delete the report template',
  'reports.increase': 'Increase',
  'reports.reduce': 'Reduce',
  'reports.moving': 'Moving',
  'reports.reset_magnification': 'Reset magnification',
  'reports.select_with_magnification': 'Selection with magnification',
  'reports.select': 'Select',
  'reports.menu': 'Menu',
  'reports.link_to_magazine': 'Link to a magazine',
  'reports.display_all_events': 'Display all events',
  'reports.deleted_intervals_messages': 'Deleted intervals of messages',
  'reports.interval': 'Interval',
  'reports.exclude_interval': 'Exclude Interval',
  'reports.interval_info':
    'Deleted message intervals are not displayed in the log and do not participate in the generation of reports.',
  'reports.interval_for_deletion': 'Interval for deleting messages',
  'reports.show_annotation_on_click': 'Annotation by clicking on a line',
  'reports.show_annotation_on_click_hint':
    'If activated, when you click on a report line, an annotation on the event is displayed.',
  'reports.list_key': 'List of keys for Excel template',
  'reports.copy_key':
    'Copy the key into a cell in the Excel template, and then upload the template to SKIF.PRO',
  'reports.key_hint':
    'Create a report template in Excel, then place data into it by copying the appropriate keys. Save the template and load it into SKIF.PRO, the system itself will find and substitute values ​​instead of keys at the time the report is generated.',
  'reports.sensor_placeholder': 'Sensor mask',
  'reports.mini_hint':
    'After copying, select the Excel template cell and press CTRL+V.',
  'reports.copy': 'Copy',
  'reports.tables.add_mask_sensor': 'Add a mask by sensor name',

  // Legend
  'tracking.speed.legend.text': 'The gradation of speed',

  // Monitoring
  monitoring: 'Monitoring',
  'monitoring.datepoint': 'Was in touch',
  'monitoring.add-objects': 'Add objects',
  'monitoring.filter-journal-events': 'Event journal filter',
  'monitoring.view-events': 'View event',
  'monitoring.objects': 'Objects',
  'monitoring.send-command': 'Send command',
  'monitoring.change-object': 'Edit object',
  'monitoring.registration-event': 'Event registration',
  'monitoring.fast-track': 'Fast track',
  'monitoring.fast-report': 'Quick report',
  'monitoring.create-event': 'Create event',
  'monitoring.type-event': 'Event type',
  'monitoring.position': 'Position',
  'monitoring.violation': 'The abuse',
  'monitoring.date-and-time': 'Date and time',
  'monitoring.sendCommand-title': 'Send a command',
  'monitoring.center-description': 'Enter a description',
  'monitoring.trips': 'Trips',
  'monitoring.graph': 'Graph',
  'monitoring.editObject': 'Edit object',
  'monitoring.activeFilters': 'Active filters',
  'monitoring.choose': 'Choose',
  'monitoring.searchHolder': 'ignition, fuel',
  'monitoring.addFilter': 'Add filter',
  'monitoring.accept': 'Accept',
  'monitoring.filters': 'Filters',
  'monitoring.maxUnits':
    'It is impossible to show more than 300 units at once. Use filters to reduce the number of units.',
  'monitoring.filter.name': 'Name',
  'monitoring.filter.imei': 'IMEI',
  'monitoring.filter.phoneNumber': 'Phone number',
  'monitoring.filter.customFields': 'Custom fields',
  'monitoring.filter.sensorName': 'Sensor name',
  'monitoring.filter.sensorType': 'Sensor type',
  'monitoring.filter.sensorParam': 'Sensor parameter',
  'monitoring.filter.event': 'Event',
  'monitoring.filter.driver': 'Driver',
  'monitoring.filter.fieldsEmpty': 'All fields must be filled',

  'monitoring.map_work_settings': 'Settings for working with the map',
  'monitoring.objects_on_map': 'Objects on the map:',
  'monitoring.distance_group':
    'Group if the distance is less than (screen points):',
  'monitoring.objects_list': 'List of objects:',
  'monitoring.show_annotation_on_click':
    'Show annotation when clicking on an object in the monitoring list',
  'monitoring.show_address': 'Display address',
  'monitoring.apply_filter': 'Apply filters',
  'monitoring.objects_grouping': 'Grouping objects',
  'monitoring.display_all': 'Display all',
  'monitoring.track_all': 'Track everything',
  'monitoring.columns.name': 'Name',
  'monitoring.fast_tracks': 'Fast tracks',
  'monitoring.fast_tracks.today': 'For today',
  'monitoring.fast_tracks.yesterday': 'For yesterday',
  'monitoring.fast_tracks.week': 'In a week',
  'monitoring.fast_tracks.month': 'Per month',
  'monitoring.to_reports': 'To reports',
  'monitoring.commands': 'Commands',
  'monitoring.send_command': 'Send command',
  'monitoring.command_response': 'Response',
  'monitoring.commands.columns.data': 'Date/Time',
  'monitoring.commands.columns.command': 'Command',
  'monitoring.commands.columns.status': 'Status',
  'monitoring.commands.exit_number': 'Exit number',
  'monitoring.commands.error_message':
    'Fill in the "Select object" and "Command" fields',
  'monitoring.commands.object': 'Object',
  'monitoring.groups.edit': 'Edit',
  'monitoring.displayed_objects': 'Displayed objects:',
  'monitoring.shape_size': 'Shape size:',
  'monitoring.grouping_on_map': 'Grouping on the map',
  'monitoring.object_search': 'Search by object name',
  'monitoring.search.name': 'Name',
  'monitoring.search.imei': 'IMEI',
  'monitoring.search.phonenumber': 'Phones',
  'monitoring.search.custom_fields': 'Additional fields',
  'monitoring.search.sensors_name': 'Sensor names',
  'monitoring.search.sensors_types': 'Sensor types',
  'monitoring.search.sensors_param': 'Sensors parameter',
  'monitoring.search.events': 'Events',
  'monitoring.search.driver': 'Driver',
  'monitoring.search.groups': 'Groups',
  'monitoring.terminal_on': 'Terminal connected',

  // Annotation
  'annotation.data_date': 'Date of data receipt:',
  'annotation.reports': 'Reports:',
  'annotation.gps_data': 'GPS Data:',
  'annotation.sensors': 'Sensors:',
  'annotation.additional': 'Additionally:',
  'annotation.last_params': 'Last data:',

  // Event journal
  'journal-events': 'Event journal',
  'journal-events.filter': 'Filter',

  // Notifications
  'notifications.motohours': 'By engine hours',
  'notifications.notificationName': 'Notification name',
  'notifications.responsetime': 'Min. notification response time',
  'notifications.periodcontrol': 'Control period relative to the current time',
  'notifications.notification': 'Notifications',
  'notifications.name': 'Name',
  'notifications.view': 'View',
  'notifications.objects': 'Objects',
  'notifications.enterName': 'Enter a name!',
  'notifications.change': 'Change notification',
  'notifications.add': 'Add notification',
  'notifications.main': 'Main',
  'notifications.more': 'Advanced',
  'notifications.delivery': 'Delivery',
  'notifications.text': 'Text',
  'notifications.control': 'Control',
  'notifications.types': 'Notification types',
  'notifications.markAsRead': 'Mark as read',
  'notifications.template': 'Notification template',
  'notifications.textDescription':
    'Enter the notification text using the special parameters listed below. When the notification is triggered, these parameters will be replaced with real values.',
  'notifications.enterNotifyText': 'Enter the notification text!',
  'notifications.paramTab': 'Parameter table:',
  'notifications.latitude': 'Latitude',
  'notifications.longitude': 'Longitude',
  'notifications.driver': 'Driver',
  'notifications.speed': 'Speed',
  'notifications.objectName': 'Object name',
  'notifications.sensors': 'Sensors',
  'notifications.sensorValue': 'Sensor value',
  'notifications.address': 'Address',
  'notifications.time': 'Time trigger',
  'notifications.fill': 'Refueling',
  'notifications.drain': 'Drain',
  'notifications.final_fuel': 'Final fuel',
  'notifications.geozone_name': 'Geofence name',
  'notifications.tripName': 'Trip name',
  'notifications.workType': 'Type of work',
  'notifications.pointName': 'Point name',
  'notifications.deviation': 'Deviation',
  'notifications.adress': 'Adress',

  'notifications.deliveryMethod': 'Delivery method',
  'notifications.chooseEmail': 'Choose at least 1 email!',
  'notifications.choosePhone': 'Select at least 1 phone number!',
  'notifications.chooseUser': 'Please select at least one user',
  'notifications.chooseDeliveryType': 'Choose delivery type',

  'notifications.asEvent': 'Register as of the event',
  'notifications.asViolation': 'Register as a violation of',
  'notifications.temporaryValueMonitoring': 'Temporary control',
  'notifications.from': 'From',
  'notifications.to': 'To',
  'notifications.use_speed_limit': 'Limit the ability to accelerate above ',
  km_hour: 'km/h',
  'notification.use_speed_description':
    'Activating this checkbox will prevent the driver from accelerating the object above the specified maximum speed by stepping on the gas pedal when entering the geofences selected below.',
  'notifications.monday': 'Monday',
  'notifications.tuesday': 'Tuesday',
  'notifications.wednesday': 'Wednesday',
  'notifications.thursday': 'Thursday',
  'notifications.friday': 'Friday',
  'notifications.saturday': 'Saturday',
  'notifications.sunday': 'Sunday',
  'notifications.geozones.control': 'Geozones control',
  'notifications.geozones.verificationType': 'Verification type',
  'notifications.geozones.chooseVerficationType':
    'Select the verification type!',
  'notifications.geozones.geozones': 'Geozones',
  'notifications.simpleTransport': 'Simple transport',
  'notifications.maxSpeed': 'Speed max',
  'notifications.kmph': 'km/h',
  'notifications.allowedDowntime': 'Acceptable downtime',
  'notifications.min': 'Min',
  'notifications.warning':
    'Choose the duration of downtime or the maximum speed!',
  'notifications.connexionLost': 'loss of connection',
  'notifications.lossDuration': 'Duration of loss',
  'notifications.lostMsg':
    'Select the mileage interval or the interval by day!',
  'notifications.sensor.control': 'Monitoring the sensor value',
  'notifications.sensor.controleGeo': 'Controle Geozone',
  'notifications.sensor.type': 'Sensor type',
  'notifications.sensor.maskByName': 'Mask by name',
  'notifications.sensor.meaning': 'Meaning',
  'notifications.sensor.warning': 'Select the sensor type or mask by name!',
  'notifications.sensor.meaningFrom': 'Value from',
  'notifications.sensor.minimalTime': 'Min. response time:',
  'notifications.sensor.writeNumber': 'Enter number',
  'notifications.sensor.meaningTo': 'Value before',
  'notifications.sensor.warningFromTo': 'Select from or to!',
  'notifications.sensor.work': 'Trigger',
  'notifications.sensor.selectFrame': 'Select frames!',
  'notifications.service.maintenance':
    'Notify of the approaching date scheduled',
  'notifications.service.days': 'Days',
  'notifications.service.for': 'For',
  'notifications.service.byMilage': 'mileage',
  'notifications.service.km': 'km',
  'notifications.service.warning':
    'Select the mileage interval or the interval in days!',
  'notifications.trips.mask': 'Trips mask',
  'notifications.trips.validationOne': 'Enter the service names correctly!',
  'notifications.trips.alertEvent': 'notification Events:',
  'notifications.trips.start': 'Trips start',
  'notifications.trips.end': 'Trips end',
  'notifications.trips.departure': 'Departure from checkpoints',
  'notifications.trips.onlyViolation': 'Only in case of violation:',
  'notifications.trips.arrCheckPoint': 'Arriving at checkpoints',
  'notifications.trips.pass': 'Skipping checkpoints (Strict order)',
  'notifications.velocity.speed': 'Speed control',
  'notifications.velocity.min': 'At least',
  'notifications.velocity.max': 'No more than',
  'notifications.velocity.warning': 'Enter a speed of no more or less!',
  'notifications.velocity.geozones': 'Geozones',
  'notifications.online': 'Online',
  'notifications.byEmail': 'By E-mail',
  'notifications.bySms': 'By Sms',
  'notifications.byPush': 'Push notifications',
  'notifications.byTelegram': 'By Telegram',
  'notifications.email.placeHolder': 'Enter email',
  'notifications.phone.placeHolder': 'Enter phone number',
  'notifications.push.placeHolder': 'Enter name or email',
  'notifications.telegram.placeHolder': 'Enter name or telegram',
  'notifications.period': 'Period',
  'notifications.no_sound': 'Mute',

  // notifications texts
  'notifications.text.arrival':
    'Notify about the arrival or delay of service for %UNIT%',
  'notifications.text.exit':
    '%UNIT% in %TIME% go into / exit from the geofence at a speed of %SPEED% near %ADDRESS%',
  'notifications.text.sensor':
    '%UNIT% in %TIME% the sensor was turned on with speed %SPEED% near %ADDRESS%',
  'notifications.text.trip': '% UNIT% in %TIME% there was in/out point %POINT%',
  'notifications.text.speed':
    '%UNIT% in %TIME% has exceeded the maximum speed limit with %SPEED% near %ADDRESS%',
  'notifications.text.connection':
    'Lost connection %UNIT% %TIME% near %ADDRESS%',
  'notifications.text.idle':
    'The %UNIT% %TIME% has exceeded the allowed idle time near %ADDRESS%',
  'notifications.text.filling':
    '%UNIT% in %TIME% fueled %FILL% to %LAST_FUEL% near %ADDRESS%',
  'notifications.text.drain':
    '%UNIT% at %TIME% drained %DRAIN% to %LAST_FUEL% near %ADDRESS%',
  'notifications.text.geozone.inside':
    '%UNIT% entered the geofence %GEOZONE% at %TIME% at a speed of %SPEED% near %ADDRESS%.',
  'notifications.text.geozone.outside':
    '%UNIT% recorded an exit from the geofence %GEOZONE% at %TIME% at a speed of %SPEED% near %ADDRESS%.',
  // trips & routes
  'routesTrips.title': 'Trips and routes',
  'routesTrips.routes': 'Routes',
  'routesTrips.trips': 'Trips',
  'routesTrips.name': 'Name',
  'routesTrips.addTrip': 'Add trip',
  'routesTrips.editTrip': 'Edit trip',
  'routesTrips.trip.name': 'Name*',
  'routesTrips.trip.date.placeholder': 'Select a date',
  'routesTrips.trip.date.set_days_period': 'Filter days of the period',
  'routesTrips.trip.date.reset_days_period': 'Restore period days',
  'routesTrips.trip.date.filter_days': 'Filter days',
  'routesTrips.trip.date.cancel_days_period': 'Exclude holidays',
  'routesTrips.trip.date.total_days_period': 'Total days of the period',
  'routesTrips.trip.date.week_days.monday': 'Mo',
  'routesTrips.trip.date.week_days.tuesday': 'Tu',
  'routesTrips.trip.date.week_days.wednesday': 'We',
  'routesTrips.trip.date.week_days.thursday': 'Th',
  'routesTrips.trip.date.week_days.friday': 'Fr',
  'routesTrips.trip.date.week_days.saturday': 'Sa',
  'routesTrips.trip.date.week_days.sunday': 'Su',
  'routesTrips.trip.date.week_days.all_days': 'All days',
  'routesTrips.trip.date.week_days.even': 'Even',
  'routesTrips.trip.date.week_days.odd': 'Odd',
  'routesTrips.trip.name.placeholder': 'The name of the trip...',
  'routesTrips.trip.validation': 'Required field',
  'routesTrips.trip.validation.total_period':
    'You must add at least one total day',
  'routesTrips.trip.object': 'Object*',
  'routesTrips.trip.object.placeholder': 'Object',
  'routesTrips.trip.activationType': 'Activation type',
  'routesTrips.trip.activationType. tooltip':
    'If “by time of the first point" is selected, the start of the flight is fixed strictly according to the time specified in the first control point. If you selected “input the first point” the beginning of the flight is fixed at the time of entrance to the region of the first point and all subsequent times are shifted by the time difference between the time of “Arrival” referred to in the first checkpoint and the time of entrance into the first point. If you selected “output from the first point,” the beginning Rasta fixed output time of the first point and all subsequent control time shift time difference-time “Shipments” specified at the first control point and the time of exit from the area of the first point.',

  'routesTrips.trip.firstPoint': 'By the time of the first point',
  'routesTrips.trip.entranceToFirstPoint': 'By entering the first point',
  'routesTrips.trip.leavingToFirstPoint': 'By leaving the first point',
  'routesTrips.trip.order': 'Passing order',
  'routesTrips.trip.order.strict': 'Strict',
  'routesTrips.trip.order.arbitrary': 'Arbitrary',
  'routesTrips.trip.period': 'Period',
  'routesTrips.trip.driver': 'Driver',
  'routesTrips.trip.workType': 'Type of work',
  'routesTrips.trip.trailer': 'Trailer',
  'routesTrips.trip.point': 'Point',
  'routesTrips.trip.arrival': 'Arrival',
  'routesTrips.trip.arrivalDev': 'Deviation of arrival',
  'routesTrips.trip.departure': 'Departure',
  'routesTrips.trip.departureDev': 'Deviation of departure',
  'routesTrips.trip.clearDays': 'Clear EXT. day',
  'routesTrips.trip.addDays': 'Add day to the subsequent points',
  'routesTrips.trip.removeDays': 'To remove the day to subsequent dots',
  'routesTrips.trip.pointsValidation': 'Select at least one point',
  'routesTrips.trip.addFromRoutes': 'Add route points',
  'routesTrips.trip.addFromMap': 'Add a point from the map',
  'routesTrips.trip.addFromGeozones': 'ADD GEOZONE',
  'routesTrips.trip.editGeozone.title': 'Edit geozone',
  'routesTrips.trip.editGeozone.figure': 'Figure',
  'routesTrips.trip.editGeozone.name': 'Name',
  'routesTrips.trip.createGeozone.title': 'Add point from map',
  'routesTrips.trip.createGeozone.figure': 'Figure',
  'routesTrips.trip.createGeozone.name': 'Name',
  'routesTrips.trip.addFromRoutes.title': 'ADD waypoint',
  'routesTrips.trip.addFromGeozones.title': 'Add geozone',
  'routesTrips.route.create': 'Create route',
  'routesTrips.route.name.placeholder': 'Route name',
  'routesTrips.route.edit': 'Edit route',
  'routesTrips.route.addFromMap': 'Add point from map',
  'routesTrips.route.addFromGeozones': 'ADD GEOZONE',
  'routesTrips.route.point': 'Point',
  'routesTrips.route.createGeozone.title': 'Add point from map',
  'routesTrips.route.createGeozone.figure': 'Figure',
  'routesTrips.route.createGeozone.name': 'Name',
  'routesTrips.route.createGeozone.width': 'Width',
  'routesTrips.route.addFromGeozones.title': 'Add from geozone',
  'routesTrips.trip.work_type_one': 'Work type 1',
  'routesTrips.trip.work_type_two': 'Work type 2',
  'routesTrips.trip.work_type_three': 'Work type 3',

  // Guide
  'mailing.title': 'Mailing list directory',
  'mailing.name': 'Name',
  'mailing.date': 'Date',
  'mailing.isActive': 'Activity',
  'mailing.edit': 'Change',
  'mailing.create': 'Create a mailing list',
  'mailing.enterName': 'Enter a name!',
  'mailing.template': 'Selecting a template',
  'mailing.vehicle': 'Select a vehicle',
  'mailing.chooseObject': 'Select an object!',
  'mailing.chooseGroup': 'Select a group!',
  'mailing.object': 'Object',
  'mailing.objectGroup': 'Group of objects',
  'mailing.periodicity': 'Frequency',
  'mailing.periodicity. warning': 'Enter the periodicity!',
  'mailing.daysweek': 'Days of the week',
  'mailing.daysweek.warning': 'Enter the days of the week!',
  'mailing.adresses': 'Recipients',
  'mailing.adresses.warning': 'Enter the mailing list recipients!',
  'mailing.subject': 'Subject of the message',
  'mailing.subject.warning': 'Enter a message!',
  'mailing.timeDateSent': 'last sent date/time',
  'mailing.mailing': 'Mailing',

  // History of changes

  'history.title': 'History of changes',
  'history.date': 'Date/Time',
  'history.author': 'Author',
  'history.operation': 'Operation',
  'history.event': 'Event',
  'history.edit': 'Edit',
  'history.create': 'Create',
  'history.delete': 'Delete',

  // ADMIN
  'admin-panel': 'Admin panel',
  'admin-panel.title': 'Admin panel',
  'admin-panel.company': 'Company',
  'admin-panel.timezone': 'TimeZone',
  'admin-panel.is-company': 'Enter company!',
  'admin-panel.show': 'Show',
  'admin-panel.module': 'Module',
  'admin-panel.period': 'Period',
  'admin-panel.finance': 'Finance report',
  'admin-panel.download-finance': 'Download report',

  // Terminal
  'edit-terminal': 'Terminal editing',
  'add-terminal': 'Adding terminals',
  'send-terminal': 'Send',
  'status-terminal': 'Status',
  'command-terminal': 'Command',
  'text-command-terminal': 'Command text',
  'response-terminal': 'Response',

  // retranslators
  'edit-retranslators': 'Edit Retranslator',
  'add-retranslators': 'Add retranslator',

  // navigation

  'nav.users': 'Users',
  'nav.objects': 'Objects',
  'nav.geozones': 'Geozones',
  'nav.notifications': 'Notifications',
  'nav.trips': 'Trips',
  'nav.mailing': 'Mailing List',
  'nav.reports': 'Reports',
  'nav.monitoring': 'Monitoring',
  'nav.admin': 'Admin-panel',

  // MEASURE

  'measure.title': 'Distance and area measurement',
  'measure.lastPoint': 'Last point',
  'measure.distance': 'Distance',
  'measure.region': 'Region',
  'measure.cancel': 'Cancel',
  'measure.finish': 'Finish measurements',
  'measure.description': 'You can change the dimension by moving the point',
  'measure.line': 'Line',
  'measure.polygon': 'Polygon',

  // Formula
  'formula.tooltip':
    'In the formula, you can use the arithmetic operations +, -, /, *, brackets) (,\n' +
    'any digits',
  // CompanySETTINGS
  'company.name': 'Name',
  'company.passwordLabel': 'Enhanced security mode',
  'company.passwordLabelText1':
    'To enhance security, activating this mode will require new users to create strong passwords. This password must be at least 15 characters long and include upper and lower case letters, numbers and special characters.',
  'company.passwordLabelText2':
    'When changing the password, the user must log in using the administrator password and then set their own password for the account. Thus, the administrator will not know the password for the user account.',
  'company.timeZone': 'Time zone',
  'company.integrator': 'Dealer ',
  'company.exitTime': 'Exit after n minutes of inactivity',
  'company.tooltip':
    '0 or empty field - there will be no exit after inactivity',

  // 500 Page

  'mainPage. title': 'Welcome to SKIF!',
  'mainPage.sorry':
    'Unfortunately, your link is no longer working. Try the operation again.',
  'mainPage.back': 'To the main page!',
  help: 'Help',
  // Map Layers
  'map.style': 'Map Style',
  'map.yandex': 'Yandex Map',
  'map.googleSatelite': 'Google Sattelite',
  'map.googleRoadMap': 'Google Roadmap',
  'map.googleTerrain': 'Google Terrain',
  'map.googleHybrid': 'Google Hybrid',
  'map.osm': 'OSM',
  'map.here': 'Here',
  'map.bing': 'Bing',
  'map.bingSatellite': 'Bing Satellite',

  // Hide from  Map
  // errors
  'error.502': 'Sorry, the server is temporarily unavailable. Try again later.',
  'error.429': 'Too many attempts, please try again later.',
  hide: 'Hide',
  'hide.title': 'Visibility on the map',
  'hide.objects': 'Units',
  'hide.objects.text': 'Hide units on the map',
  'hide.tracks': 'Tracks',
  'hide.tracks.text': 'Hide tracks on the map',
  'hide.tracks.signatures': 'Hide signatures on tracks',
  'hide.tracks.direction_of_movement': 'Direction of movement',
  'hide.tracks.group': 'Group if distance less than',
  'hide.notifications': 'Notifications',
  'hide.notifications.text': 'Hide notifications on the map',
  'hide.geozones': 'Geofences',
  'hide.geozones.text': 'Hide geofences on the map',
  'hide.trips': 'Routes & Trips ',
  'hide.trips.text': 'Hide routes & trips on the map',

  'hide.events': 'Events',
  'hide.events.text': 'Hide events on the map',
  // billing
  'tariff.information': 'TARIFF INFORMATION',
  'tariff.current': 'Your current tariff:',
  'tariff.price': 'The cost of tariff:',
  'tariff.payement-date': 'Payment:',
  'tariff.balance': 'On the account:',
  'tariff.units': 'objects',
  'tariff.rouble': 'RUB',
  'tariff.kz': 'KZT',
  'tariff.one_day': 'day',
  'tariff.days': 'days',

  'tariff.notselected': 'Not selected',
  'tariff.currency-month': 'currency/month',
  'tariff.currency': 'currency',
  'tariff.company-blocked': 'the company is blocked',
  'tariff.trial': 'Trial Period:',
  'tariff.paid': 'Paid period',

  'tariff.remaining': 'Remaining:',
  'tariff.next-payment': 'Next payment:',
  'tariff.payment-history': 'HISTORY OF CHARGES AND PAYMENTS',
  'tariff.pay': 'PAY',
  'tariff.choose-pay': 'PAY TARIFF ',

  'tariff.payments-history': 'Payment history',
  'tariff.date-time': 'Date/Time',
  'tariff.operation': 'Operation ',
  'tariff.amount': 'Amount',
  'tariff.currency-type': 'currencies',
  'tariff.objects': 'Objects',

  'tariff.choose': 'Choice of tariff plan',
  'tariff.txt-one':
    'The use of the SKIF PRO monitoring system is subject to a fee.',
  'tariff.txt-two': 'You can choose the tariff necessary for your company.',
  'tariff.txt-three': 'The first 30 days are free.',
  'tariff.choose-units': 'Select the desired number of objects:',
  'tariff.info-one': 'The cost of 1 object in the SKIF PRO system is',
  'tariff.info-two--part-one': 'for',
  'tariff.info-two--part-two': 'calendar days.',
  'tariff.tariffprice': 'The cost of tariff:',
  'admintariff.refill': 'Refill',
  'tariff.amounttopay': 'Amount',
  'admintariff.newtariff': 'New tariff',
  'admintariff.oldtariff': 'Old tariff',
  'admintariff.historyobjects': 'objects',

  'tariff.payableto': 'payable to:',
  'tariff.txt-four': 'If you want to reduce the tariff',
  'tariff.txt-five': 'you need to contact support.',
  'tariff.txt-six': ' By clicking "I agree" you agree with the tariff',
  'tariff.txt-seven': 'payment rules and ',
  'tariff.offers': 'offers.',
  'tariff.accept': 'Accept',
  'tariff.blocked-info':
    "There are not enough funds on the company's account. Access to the company is blocked.",
  copy: 'IMEI copied!',
  // tariff ADMINKA

  'admintariff.company': 'Company',
  'admintariff.tariff': 'Tariff',
  'admintariff.date': 'date of next payment',
  'admintariff.for': 'for ',
  'admintariff.objects': 'objects every',
  'admintariff.days': 'days',

  'admintariff.changetitle': 'Changing the tariff',
  'admintariff.cancel': 'Cancel',
  'admintariff.accept': 'Confirm',
  'admintariff.object': 'objects',
  'admintariff.objectsnumber': 'Number of objects',
  'admintariff.price': 'Cost',
  'admintariff.period': 'Payment period',
  'admintariff.paymentdate': 'Date of payment',

  // Other
  'company.retransmit': 'Retransmit to the UFIS',
  'company.date-format': 'Date format',
  'company.time-format': 'Time format',
  'company.last_date_widget': 'The time of the last date in the widget',
  'company.last_date_widget_note':
    'Specify which time format will be used when selecting the last date in the date range (in the date selection widget).',
  'company.download_offer': 'Download the offer',
  'company.accounting_type': 'Type of accounting:',
  'company.by_number_objects': 'By the number of objects',
  'company.by_number_objects_sent_data':
    'By the number of objects that sent data',
  'company.max_number_objects': 'Max. number of objects:',
  'company.cost_one_object': 'Cost of 1 object:',
  'company.start_current_period': 'Beginning of the current period:',
  'company.payment_period': 'Payment period:',
  'company.previous_period_amount': 'Amount for the previous period',
  'company.minimum_payment_amount': 'The minimum payment amount is 1 rub.',
  'company.use_card_for_autopayments': 'Use the card for autopayments',
  'company.select_payment_system': 'Select a payment system:',
  'company.temporarily_unavailable': 'Temporarily unavailable',
  'company.choose_payment_system': 'Choose a payment system',
  'company.autopayment_unavailable_with_mir':
    'Autopayment is temporarily unavailable with MIR cards',
  'company.autopayment': 'Autopayment',
  'company.autopayment_on': 'Enable autopayments',
  'company.no': 'No',
  'company.change_card_autopayment': 'Changing the card for autopayment',
  'company.change_card_cost_note':
    'To change the autopayment card, we will need to charge you 1 ruble. When paying, specify the details of the new card.',
  'company.cancel': 'Cancel',
  'company.continue': 'Continue',
  'company.card_add_for_autopayments': 'Adding a card for autopayment',
  'company.add_card_cost_note':
    'To add an autopayment card, we will need to debit from you . When paying, specify the card details.',
  'company.visa_or_mastercard': 'Visa or Mastercard',
  company_system_mir: 'The MIR system',

  search: 'Search',
  error: 'Error',
  attention: 'Attention',
  notification: 'Notification',
  change: 'Change',
  success: 'Success',
  loading: 'Loading...',
  close: 'Close',
  send: 'Send',
  phone: 'Phone',
  type: 'Type',
  add: 'Add',
  cancel: 'Cancel',
  value: 'Value',
  role: 'Role',
  email: 'Mail',
  time: 'Time',
  author: 'Author',
  events: 'Event',
  action: 'Action',
  code: 'Code',
  details: 'Advanced',
  description: 'Description',
  create: 'Create',
  companies: 'Companies',
  blocking: 'Blocking',
  yes: 'Yes',
  no: 'No',
  to: 'To',
  in: 'in',
  phone_confirmation: 'Phone confirmation',
  email_confirmation: 'Email Confirmation',
  select: 'Select',
  select_all: 'Select all',
  outdated_version: 'The version is outdated',
  outdated_version_message: 'Refresh the page',
  one_element_deleted: '1 item removed',
  already_deleted_elements: ' items were removed deleted',
  save: 'Save',
  excel: 'Excel',
  word: 'Word',
  pdf: 'PDF',
  no_data: 'No data',
  confirm: 'Confirm',

  'setting-company': 'Company Settings',
  'setting-user': 'User settings',

  // add-company admin panel
  addCompany: 'Add company',
  new_units: {
    tabs: {
      objects: 'Objects',
      groups: 'Groups',
      trailers: 'Trailers',
      links: 'Access links'
    },
    titles: {
      objects: 'Objects directory',
      groups: 'Group Directory',
      trailers: 'Trailer Directory',
      links: 'Access link directory',
      create: 'Create an object',
      edit: 'Editing an object'
    },
    add: {
      objects: 'Add object',
      groups: 'Add a group',
      trailers: 'Add trailer',
      links: 'Add link',
      couplings: 'Add',
      shifts: 'Add',
      add_field: 'Add field',
      service: 'Add MOT',
      parameters: 'Add parameters'
    },
    trailers: {
      title: 'Trailer:',
      additionalFields: 'Additional fields:',
      save: 'Save',
      search: 'Trailer Search',
      fields: {
        name: 'Name:',
        code: 'Code:',
        length: 'length:',
        description: 'Description:',
        value: 'Value:'
      },
      no_trailers: 'No trailer created',
      success: {
        approved: 'The trailer has been successfully created!',
        updated: 'The trailer has been successfully updated!',
        delete: 'The trailers has been successfully deleted!'
      },
      errors: {
        name: 'Insert a name!',
        approved: 'Please save the current trailer to create a new one!',
        already_deleted:
          'You have already deleted this trailer, if you want to return it, click on the cancel button below.',
        validation: 'Fill in all required fields to move to another trailer!'
      },
      search_options: {
        name: 'Name',
        code: 'Code'
      },
      table_columns: {
        name: 'Name',
        width: 'Width',
        code: 'Code'
      }
    },
    links: {
      create: 'Create an access link',
      edit: 'Edit the link',
      object: 'Object',
      empty: 'Not selected',
      accessStart: 'Access start date',
      accessEnd: ' Access end date',
      description: 'Description',
      placeholder: 'Long description ....',
      link: 'link',
      search: 'Link search',
      no_links: 'The list of access links is empty',
      status: {
        planned: 'Scheduled',
        active_off: 'Active (off)',
        active_on: 'Active (on)',
        inactive: 'Inactive'
      },
      success: {
        create: 'Access link created successfully!',
        edit: 'Access link edited successfully!',
        delete: 'Access links deleted successfully!',
        create_imei: 'Imei code created successfully!'
      },
      errors: {
        unit: 'Select an object!',
        access_start: 'Select start date!',
        access_end: 'Select an end date!',
        create: 'An error occurred while creating the access link!',
        edit: 'An error occurred while editing the access link!',
        delete: 'An error occurred while deleting access links!'
      },
      search_options: {
        object: 'Object',
        status: 'Status'
      },
      table_columns: {
        object: 'Object',
        active_from: 'Active from',
        active_to: 'Active to',
        status: 'Status'
      }
    },
    groups: {
      no_groups: 'No object groups created',
      search: 'Search by groups',
      errors: {
        name: 'Enter a name!',
        create: 'Oops... There was a problem creating',
        edit: 'Oops... There was a problem editing',
        validation: 'Fill in the required fields!',
        delete: 'An error occurred while deleting groups!'
      },
      success: {
        create: 'Group successfully created!',
        edit: 'Group edited successfully!',
        delete: 'Groups deleted successfully!'
      },
      search_options: {
        name: 'Name',
        object: 'Object'
      },
      table_columns: {
        group_name: 'Group name',
        users_count: 'Users',
        objects: 'Objects'
      }
    },
    objects: {
      object: 'Object:',
      create: 'Creating an object',
      edit: 'Editing an object',
      saved: 'Saved',
      approved: 'APPROVED',
      fill: 'Fill',
      no_objects: 'No object has been added to the system',
      search: 'Search by objects',
      success: {
        delete: 'Objects deleted successfully!'
      },
      errors: {
        delete: 'An error occurred while deleting objects!'
      },
      search_options: {
        name: 'Name',
        imei: 'IMEI',
        phonenumber: 'Phone numbers',
        custom_fields: 'Additional fields',
        sensors_name: 'Sensor names',
        sensors_type: 'Types of sensors',
        sensors_param: 'Sensor parameter',
        events: 'Events'
      },
      table_columns: {
        name: 'Name',
        protocol: 'Protocol',
        imei: 'IMEI',
        sim_1_2: 'Sim 1,2',
        sensors: 'Sensors'
      },
      tabs: {
        basic: 'Basic',
        sensors: 'Sensors',
        couplings: 'Couplings',
        shifts: 'Shifts',
        service: ' Inspection',
        parameters: 'Tech parameters',
        blocked_text:
          'Shifts, inspection and Coupling will be available after saving the object'
      },
      fields: {
        basic: {
          name: 'Object name:',
          type: 'Object type:',
          gadget: 'Device (terminal):',
          protocol: 'Data Exchange protocol:',
          imei: 'IMEI:',
          server: 'Data receiving server:',
          port: 'Port:',
          sim_1: 'Sim1:',
          sim_2: 'Sim2:',
          note: 'Yoy can don`t indicated device name. To work correctly, it`s enough to specify the protocol.',
          groups: 'Groups',
          errors: {
            name: 'Insert Name',
            type: 'Choose your object type!',
            terminal_type: 'Choose your device type!',
            imei: 'Choose a unique ID!',
            phonenumber: 'Enter your phone number!!',
            custom_imei: 'IMEI is blocked!'
          }
        },
        sensors: {
          title: 'Sensor:',
          fields: {
            name: 'Name:',
            new_sensor: 'New sensor',
            sensor_type: 'Sensor type:',
            hide_in_report: 'Hide in Reports',
            hidden_in_report: 'Hidden in reports',
            hide_in_monitoring: 'Hide in monitoring',
            hidden_in_monitoring: 'Hidden in monitoring',
            hidden_in_both: 'Hidden in reports and monitoring',
            formula: 'Formula',
            expression: 'Expression',
            help_info:
              'By clicking the mouse, select the format for working with sensor parameters.',
            multiple_deletion_mode: 'You are in sensor removal mode',
            hint: {
              title: 'Hint',
              hide: 'Hide',
              show: 'Show'
            },
            parameters: {
              param_title_1: 'Parameter 1',
              param_title_2: 'Parameter 2',
              param_title_s_1: 'S1',
              param_title_s_2: 'S2',
              param_option_1: 'Parameter 1:',
              param_option_2: 'Parameter 2:',
              lower_bound_x: 'Lower bound X:',
              upper_bound_x: 'Upper bound X:',
              smoothing_coefficient: 'Smoothing coefficient',
              smoothing_coefficient_range: '(from 0 to 999)',
              type_filter: 'Type filtering',
              download_from_csv: 'Upload X and Y from a CSV file',
              download_as_csv: 'Download X,Y as a CSV file',
              related_tables: 'Related tables',
              show_graph: 'Show graph',
              hide_graph: 'Hide the graph',
              x: 'X',
              y: 'Y',
              a: 'А',
              b: 'Б',
              lower_bound_short: '(low. border)',
              sensor_calibration: 'Sensor Calibration',
              exist: 'Exist',
              helpers: {
                param: 'Parameter',
                param_minus_1: 'Parameter[-1]',
                sensor_name: 'S-sor name',
                param1: 'param1',
                param1_minus_1: 'param1[-1]',
                param2: 'param2',
                param2_minus_1: 'param2[-1]',
                degree: 'degree'
              },
              helpers_description: {
                param: 'access to the values of any parameter from raw data',
                param_minus_1:
                  'access to the previous value of the parameter from raw data',
                param1:
                  'access to the first parameter (after linear transformation) of the current sensor',
                param1_minus_1:
                  'access to the previous value of the first parameter (after linear transformation) of the current sensor',
                param2:
                  'access to the second parameter (after linear transformation) of the current sensor',
                param2_minus_1:
                  'access to the previous value of the second parameter (after linear transformation) of the current sensor',
                sensor_name: 'access to the values of other sensors',
                byte_control:
                  'bitwise control of a parameter (e.g., param101:3 - the third bit of parameter param101)'
              },
              clear: 'Clear table'
            },
            technical_params: {
              title: 'Technical specifications',
              add_params: 'Add tech. parameter',
              show_annotation:
                'Reject the last parameter in the annotation of the object.',
              errors: {
                wrong_value: 'Неверное значение!'
              }
            },
            connected_sensors: {
              title: 'Sensor Connections',
              connected: 'Connected',
              cancel: 'Cancel',
              bundle_with_ignition: 'Bundle with Ignition Sensor'
            },
            errors: {
              name: 'Enter the name of the sensor!',
              type: 'Select the sensor type!',
              formula: 'Choose a formula!',
              smoothing: 'Enter correct value!',
              parameter_1: 'Choose parameter 1!',
              parameter_2: 'Choose parameter 2!',
              validation:
                'Fix the error in the settings of the current sensor in order to switch to another one!',
              save_validation:
                'Fix the error in the settings of the current sensor in order to save the settings!',
              duplicate_x: 'The values of X cannot be repeated!',
              invalid_value:
                'You cannot add a new row because there is an invalid value in the table.',
              duplicate_lower_bound:
                'The values of the lower bounds cannot be repeated!'
            }
          },
          no_sensors: 'There are no sensors in the object',
          value_to_sensor: 'Convert values ​​to text:',
          value_to_sensor_text:
            'Convert the sensors numeric value to text. To do this, specify the values ​​and text keys for them. Use the encoders linear transformation to work with ranges of values',
          value: 'Value',
          output_text: 'Output text'
        },
        couplings: {
          title: 'Planning:',
          date_filter: 'Filter by date:',
          start: 'From',
          end: 'For',
          trailer: 'Trailer',
          start_date: 'Start date:',
          end_date: 'Completion date:',
          note: 'The interval of using the trailer intersects with another interval.',
          work_type: 'Type of works:',
          min_speed: 'Min. speed (km/h):',
          max_speed: 'Max. speed (km/h):',
          sensor_mask: 'Sensor name mask:',
          no_couplings: 'Work with trailers is not planned',
          success: {
            approved: 'The coupling has been successfully confirmed!',
            updated: 'The hitch has been successfully updated'
          },
          errors: {
            trailer: 'Select a trailer!',
            couplig_start: 'Choose a start date!',
            couplig_end: 'Choose a completion date!',
            work_type: 'Choose the type of work!',
            already_deleted:
              'You have already deleted this hitch, if you want to return it, click on the cancel button below.',
            approved:
              'Please confirm the current trailer to create a new hitch!'
          },
          table_columns: {
            from: 'From',
            to: 'To',
            trailer: 'Trailer'
          }
        },
        shifts: {
          title: 'Shifts:',
          start: 'From',
          end: 'For',
          driver: 'Driver',
          shift_start: 'Start of shift:',
          shift_end: 'End of shift:',
          note: 'The shift usage interval intersects with another interval.',
          hide_old_shifts: 'Hide past shifts',
          additionalFields: 'Additional fields:',
          name: 'Name:',
          value: 'Value:',
          no_shifts: 'The shift list is empty',
          success: {
            approved: 'The shift was successfully confirmed!',
            updated: 'The shift was successfully updated!'
          },
          errors: {
            driver: 'Choose a driver!',
            shift_start: 'Select the start of the shift!',
            shift_end: 'Choose the end of the shift!',
            already_deleted:
              'You have already deleted this shift, if you want to return it, click on the cancel button below.',
            approved: 'Please confirm the current shift to create a new one!'
          },
          table_columns: {
            from: 'From',
            to: 'To',
            driver: 'Driver'
          }
        },
        service: {
          title: 'Maintenance:',
          service: 'Service',
          mileage: 'Mileage/ m.h.',
          days: 'Days',
          no_service: 'The list is empty',
          fields: {
            service: 'Service:',
            description: 'Description:',
            last_serviced: 'Date of last maintenance:',
            mileage_period: 'Activate mileage interval',
            current_mileage: 'Current mileage (km):',
            interval: 'Interval (km):',
            days_period: 'Activate the interval by days',
            interval_days: 'Interval in days:',
            ativate_engine_hours: 'Activate the interval by engine hours',
            last_times: 'Last time:',
            engine_hours: 'Interval (M/h):'
          },
          errors: {
            name: 'Enter a name!',
            description: 'Enter a description!',
            lastDays: 'Select the date of the last maintenance!',
            lastKm: 'Enter the current mileage (km)!',
            intervalKm: 'Enter the current mileage (km)!',
            intervalDays: 'Enter the interval (days)!',
            lastMotohours: 'Enter the last (times)!',
            intervalMotohours: 'Enter the interval (hours)!',
            checkbox: 'Select 1 of the interval types!',
            already_deleted:
              'You have already deleted this shift, if you want to return it, click on the cancel button below.',
            validation: 'Please fill in all required fields!'
          },
          table_columns: {
            service: 'Service',
            mileage: 'Mileage/ m.h.',
            days: 'Days'
          }
        },
        parameters: {
          tabs: {
            trips: 'Trips',
            fuel: 'Fuel',
            engine_hours: 'Engine hours',
            video: 'Video',
            additional: 'Additional',
            parameters: 'Your parameters',
            filter_data: 'Data filter'
          },
          video: {
            hint: 'Enter channel numbers separated by commas to display video.'
          },
          switch: {
            hint: 'Show in monitoring',
            show_annotation: 'Show annotation',
            not_show_annotation: 'Not show annotation'
          },
          errors: {
            wrong_value: 'Incorrect value!',
            correct_values: 'Fill in the correct values to go to the section!'
          },
          name: 'Name:',
          value: 'Value:',
          no_parameters: 'No additional options added'
        }
      }
    },
    apply_changes: 'Apply сhanges',
    cancel: 'Undo changes',
    approve: 'Confirm',
    save_modal: {
      message: 'Object and data saved successfully',
      continue: 'Continue',
      close: 'Сlose form'
    },
    recover: {
      button: 'Cancel deletion',
      removed: {
        sensors: 'Abort removal:',
        couplings: 'Removed coupling:',
        shifts: 'Removed shifts:',
        service: 'Removed maintenance:',
        parameters: 'Removed parameters:',
        trailers: 'Removed trailers:'
      }
    },
    footer: {
      cancel: 'Cancel',
      save: 'Save'
    },
    placeholder: {
      select: 'Choose'
    },
    delete: 'Delete',
    create: 'Create',
    edit: 'Edit',
    unspecified: 'not specified',
    not_selected: 'not selected'
  },
  // добавление параметров из отчета
  add_parametrs: {
    add_parametrs: 'Add parameters',
    no_accepted: 'Not accepted',
    accepted: 'Accepted',
    protocol: 'Protocol',
    parametrs_available: 'Parameters available',
    support:
      'Contact support to add the following options (because they cant be added)',
    parametrs_time: 'Successfully added possible options:',
    send_parametrs: 'Send parameters for addition',
    good: 'Good'
  },

  // попап tools
  tools: {
    admin_panel: 'Admin. panel',
    admin_panel_v2: 'Admin. panel 2.0',
    admin_panel_beta: 'βeta тест'
  },
  // геозоны
  geozones_lang: {
    geozones_enter: 'Enter geozones',
    geozones_exit: 'Exit geozones'
  },
  // уведомления
  notifications: {
    text: {
      confirmation:
        'Users who have not confirmed their e-mail will receive an email asking them to do so. After confirmation, they will start receiving e-mail newsletters.',
      events_routes: 'Flight event'
    }
  },
  // попап быстрый трек
  fast_track: {
    close: 'Close fast track'
  },
  // формула
  formula: {
    add: 'Add',
    extra_fields_sensors: 'Add additional fields and sensors',
    errors: {
      name: 'Enter the title',
      formula: 'Enter the formule'
    }
  },
  // Подсказки
  notification_alert: {
    success: 'Event saved successfully',
    error_points: 'Track points missing!'
  },
  // Иконки в мониторинге
  content: {
    object_stay: 'The object is worth',
    object_move: 'Object in motion',
    object_last_state_move: 'Last state: object in motion',
    object_last_state_stay: 'Last state: object is standing'
  },
  // Помощник
  helper: {
    description_text:
      'In demo mode, you cannot access the admin panel and the ability to create/edit entities (objects, geofences, etc.)',
    objects:
      'Create and edit objects. Add sensors, maintenance, shifts, trailers, etc.',
    video: 'Video: "Creating an object"',
    content_text: 'To work with sensors:',
    content_text_list: {
      one: '1. Open the "Objects" module',
      two: '2. Select an object by clicking on the name from the list',
      three: '3. In the upper left corner select "Sensors"',
      four: 'You will see a list of sensors in the object. If the list is empty, you can add sensors yourself by clicking on the button',
      five: 'In the sensor you can select the parameters and adjust their calibration, for this click on',
      six: 'In addition to the standard formulas for parameters, you can create your own expressions with the ability to access raw sensor data.',
      seven:
        'To do this, in the "Formula" field, select the last item "Expression"'
    },
    video_add_sensor: 'Video: "Adding sensors"',
    reports: {
      text: 'Generate a report on an object or group of objects. Choose from 25 reports or create your own using the report template editor.',
      video_base_reports: 'Video: Base Reports',
      video_create_template: 'Video: "Creating Templates"'
    },
    monitoring: {
      text_one: 'Track the current state of the vehicle.',
      text_two:
        'For you to quickly understand how long ago the data on the object came, we added a color indication:',
      colors: {
        one: '— Data arrived less than 15 minutes ago',
        two: '- Data arrived 30 minutes ago',
        three: '- Data arrived more than 30 minutes ago'
      },
      statuses: {
        text: 'An object can have one of 4 statuses:',
        one: '— Object stands',
        two: '— The object is standing with the ignition on',
        three: '- Object is in motion',
        four: '- Object is in motion'
      },
      text_three:
        'Also in monitoring you have access to quick actions related to the object. For example, you can build a “Quick track” (a track for a day, a week or a month).'
    },
    admin: {
      text: 'To access the admin panel, you need to register as an integrator or request a demo of the platform by our specialist.'
    },
    footer: {
      account: 'Register an account',
      get_free: '(Get 30 days free trial)'
    },
    list: {
      one: {
        title: 'Objects',
        content:
          'Create and edit objects. Add sensors, maintenance, shifts, trailers, etc.',
        link: 'Video: "Creating an Object"'
      },
      two: {
        title: 'Sensors',
        content:
          'To work with sensors: 1. Open the "Objects" module. 2. Select an object by clicking on the name from the list. 3. Select "Sensors" in the upper left corner.'
      },
      three: {
        title: 'Reports'
      },
      four: {
        title: 'Monitoring'
      },
      five: {
        title: 'Admin Panel'
      }
    }
  },
  // кабинет дилера
  lkDiler: {
    create_company: 'Create companies',
    title: 'Dealers Personal Account',
    change_tariff: 'Tariff changes',
    create_terminal: 'Setting up terminals',
    template_command: 'Command Templates',
    admin_panel: {
      step_1:
        'To create a company in the Admin panel, click on "Add a company" in the "Companies" module:',
      step_2:
        'In the opened form of creating a company, you must fill in the name of the company, select the time zone and, if necessary, add 1 or more terminals and click "Save". Leave "Switch to the selected company" selected. You will automatically be taken to the newly created company.',
      step_3:
        'Leaving "Switch to the selected company" selected will automatically take you to the newly created company.'
    },
    register_link: {
      step_1:
        'To send an invitation link, you need to click “Invite” in the list of your companies',
      step_2:
        'In the field that opens, you must enter the email address of the employee of the Company, who will receive an invitation, by clicking on the link in the letter, he will be taken to the registration page of the new Company. The employee who receives the link will become the Administrator of the created Company'
    },
    change_step: {
      step_1:
        'By default, SKIF.PRO in newly created companies uses a tariff of 250 rubles per object and the clients ability to pay for the service through the processing center of SKIF EPP. In order to disable the processing center and use your own tariff scheme, simply set the Tariff for this Company to 0 rubles in the Admin Panel. for 0 objects. To access the tariffs, select “Tariffs” in the list of modules of the Admin panel',
      step_2:
        'Select the required company from the list and click on the “Show button"',
      step_3:
        'Click on the line with the rate, select the number of objects 0 and the cost of one object 0, click confirm'
    },
    create_company_step: {
      title: 'Building Companies',
      create: 'Creating a company in the admin panel',
      register: 'Register via invite link'
    },
    start_terminal: {
      create_terminal: 'Setting up terminals',
      step_1:
        'To add terminals, you need to go to the terminals module in your Admin panel and click on "+"',
      step_2: 'Next you need:',
      step_3:
        '1. Select a company from the list of your companies where you want to allow the use of terminals.',
      step_4:
        '2. Enter one or more imei terminals you want to add. It is convenient to immediately insert a list of imei separated by a new line.',
      step_5:
        '3. If the terminals are connected using a password, you can specify it here.',
      step_6: '4. Press the save button.',
      template: {
        title: 'Terminal Command Templates',
        step_1:
          'In the Dealers personal account, you can create command templates for terminals, to do this, go to the Admin Panel and select the “Command Reference” module',
        step_2:
          'Click on the “+” to add a command, in the window that opens, select the type of terminal, select or fill in the name of the command and enter the command itself in the “Command text” field, click save'
      }
    }
  },
  // кабинет компании
  work_company: {
    title: 'Company Workplace',
    data: 'Data from terminal',
    create_object: 'Create objects',
    short_list: {
      action_one:
        'To create objects you will need to go to one of your companies. To do this, click on the button with the username and Company, and then select the desired company in the list',
      action_two:
        'To go to the Admin Panel from any Company, click on the button with the username, and then on the item “Admin. Panel”In the selected company, go to the directory of objects',
      action_three: 'Press add',
      action_four:
        'In the opened form, fill in all the fields and select an image for the object',
      action_five:
        'Note: After selecting the desired terminal type, pay attention to the hint indicating the server and port where you need to configure the terminals to send data to the server',
      action_six:
        'To add a sensor to an object, select “Sensors” from the top drop-down list',
      action_seven:
        'Next, you need to click on the “+”, a window for adding a sensor will open, in which you need to fill in all the fields, select parameters and formulas',
      action_eight:
        'When selecting parameters, you can configure them, to do this, click on the gear next to the desired parameter, enter the required values and click save',
      action_nine: 'After adding and saving the sensor, click create object'
    },
    data_terminal: {
      step_1:
        'Raw data from terminals you can view in the Log report. To get the “Journal” report, go to the Reports module',
      step_2:
        'Then, in the window that opens, select the object, period and click on the log in the list of reports',
      ste_3:
        'In the Log report, you can also add parameters and other sensors. Note: The log is not available for the object group'
    }
  },
  // измерение площади библиотека
  measure: {
    title: 'Area measurement',
    square: 'Square',
    perimeter: 'Perimeter',
    clear: 'Clear'
  },
  // Админ панель - добавление компании
  addCompanyTransalter: {
    description: 'Adding multiple terminals when wrapping to a new line.',
    selectCompany: 'Switch to selected company',
    addUser: 'ADD USER',
    cancel: 'CANCEL',
    save: 'SAVE',
    password: 'Password:',
    repeatPassword: 'Confirm password:',
    error: {
      name: 'Fill in the "Company Name" field',
      time: 'Fill in the "Timezone" field',
      password: 'Passwords don"t match',
      enterName: 'Enter username',
      enterEmail: 'Please enter a valid email address',
      enterPassword: 'Enter password',
      repeatPassword: 'Passwords must match'
    }
  },
  templateCommandTranslater: {
    add: 'Add command template',
    edit: 'Edit command template',
    typeTermial: 'Terminal type',
    selectTypeTerminal: 'Select terminal type',
    nameCommand: 'Command name',
    selectCommand: 'CHOOSE COMMAND',
    enterNameCommand: 'Enter command name',
    textCommand: 'Command text',
    enterTextCommand: 'Enter command text',
    parametrsCommand: 'Command options'
  },
  companiesListTranslater: {
    copyObject: 'Copy object',
    selectCompany: 'Select the company you want to join',
    copiedObject: 'Copy object.',
    cancel: 'Cancel',
    save: 'Save'
  },
  createPayment: {
    title: 'Create Payment',
    company: 'Company',
    amount: 'Payment amount',
    numberPayment: '№ number',
    createPayment: 'Create Payment',
    upBalance:
      'This operation will increase the balance of the selected company',
    error: {
      createPayment: 'Payment created',
      error: 'Error',
      enterCompany: 'Fill in the "Company" field',
      enterAmount: 'Fill in the Payment Amount field'
    }
  },
  editTarifTranslater: {
    dateStart: 'Tariff start date:',
    type: 'Account Type',
    info: 'The payment amount for the payment period is formed according to the formula: Max. the number of objects multiplied by the cost of 1 object.',
    info_2:
      'The amount of payment for the payment period is formed according to the formula: Number of objects that sent data for the period, multiplied by the cost of 1 object',
    periodStart: 'Start of period',
    max: 'Max. number of objects',
    amountPer1Object: 'Cost per 1 object',
    nextPayment: 'Next tariff payment:',
    amount: 'Amount:',
    dateNextPayment:
      'The date of the next payment cannot be in the past. Change the payment start date or increase the payment period.',
    changePeriod:
      'You changed the payment period, so the next payment date has changed from',
    setStartTarif:
      'You have set the start of the tariff to a future date, because of this, the company will be in trial mode from',
    setBeforeDate:
      'You have set the start of the tariff on the past date, because of this, the date of the next payment has changed from'
  },
  feedbackTranslater: {
    addSomeTerminals: 'Adding multiple terminals when wrapping to a new line.',
    selectCompany: 'Switch to selected company',
    save: 'Save',
    error: {
      name: 'Fill in the "Company Name" field',
      time: 'Fill in the "Timezone" field'
    }
  },
  copyReportTemplateTranslater: {
    title: 'Pattern Copy',
    selectCompany: 'Select the company you want to join',
    copy: ' copy template.',
    cancel: 'Cancel',
    save: 'Save'
  },
  importWialonTranslater: {
    title: 'Import from Wialon',
    fileObject: 'Select a file with objects from Wialon',
    inCompany: 'To the company',
    selectFile: 'Select file',
    typeFiles: '.wlp, .xml or zip archive of such files',
    next: 'Next',
    findEquipment: 'Following devices detected, select protocols for them:',
    selectProtocol: 'Select protocol',
    back: 'Back',
    findedParametrs:
      'Found the following parameters selected in the sensors, check if they match. You can change them in the future.',
    import: 'Import'
  },
  integratorsTranslater: {
    addParameter: 'Integrator addition ',
    editParameter: 'Edit Integrator',
    name: 'Name',
    enterName: 'Enter Name',
    INN: 'INN',
    fieldMiss: 'Fill in the field',
    numberCallCenter: 'Contact Center Number',
    contactPerson: 'Contact person',
    email: 'Email',
    typeOrganization: 'Organization type',
    password: 'Password',
    smsTariff: 'SMS Tariff',
    paymentInforamion: 'Payment Information',
    paySystem: 'Payment System',
    idShop: 'Shop ID',
    secretKey: 'Secret key',
    change: 'Change',
    delete: 'Delete',
    ofertaLoaded: 'Offer loaded',
    moveOferta: 'Place offer here or click to selectа',
    documentMobileApp:
      'Mobile app usage agreement will be displayed to your customers',
    tariffDefault: 'Default tariff for new companies',
    typeAccount: 'Account Type',
    priceObject: 'Price per object',
    periodPayment: 'Payment period (days)',
    tryPeriod: 'Trial period (days)',
    tariffDefaultQR: 'Default tariff for QR registrations',
    cancel: 'Cancel',
    fileSize: 'File size must not be larger than 10MB',
    address: 'Address',
    services: 'Services',
    tariffDescription: 'Tariff description'
  },
  inviteUserTranslater: {
    invite: 'Invite',
    next: 'Next',
    enterNumber: 'Enter a phone number in the format +XXXXXXXXXX or XXXXXXXXXX',
    back: 'Back',
    register: 'Register',
    passwordMustBe: 'Password must be greater than 5 characters',
    wrongNumber: 'Invalid phone number'
  },
  objecrRecoveryTranslater: {
    copyObject: 'Copy object',
    restore: 'Are you sure you want to restore?',
    objectCompany: ' object in the company',
    restoring: 'Restore',
    success: 'object restored successfully'
  },
  qrAdminTranslater: {
    qrCreate: 'Create QR code',
    register: 'Register and',
    addGps: 'Add GPS tracker',
    print: 'Print',
    scanCode: `Scan the code to register a GPS tracker or enter the code at ${currentUrl}c`
  },
  retranslatorTranlsater: {
    changeIMEI: 'Change to IMEI',
    protocol: 'Protocol',
    serverRetranslation: 'Relay Serve',
    participant: 'Identifier of the program participant',
    route_identifier: 'Route identifier',
    gps_signal_category: 'GPS signal category',
    hint: 'Slow is used for public transport trackers.',
    type_vehicle: 'Type of public transport vehicle',
    port: 'Relay port',
    retranslationFrom: 'Retranslate from',
    retranslationTo: 'Retranslate to'
  },
  adminPanelTranlsater: {
    importWialon: 'Import from Wialon',
    importKML: 'Import from KML',
    invite: 'Invite',
    object: 'Object',
    name: 'Name',
    phone: 'Phone',
    typeTerminal: 'Terminal type',
    command: 'Command',
    user: 'User',
    grade: 'Grade',
    type: 'Type',
    message: 'message',
    company: 'Company',
    total: 'Amount',
    date: 'Date',
    numberType: '№ Type',
    period: 'Period',
    protocol: 'Protocol',
    addCompany: 'Add company',
    copyObject: 'Copy object',
    copyTemplate: 'Copy Template',
    recoveryObject: 'Restore object',
    cashType: {
      deposit: 'Manual',
      card: 'From card',
      withdrawal: 'Tariff payment',
      trial: 'Trial'
    },
    enterEmail: 'Enter email',
    wrongEmail: 'Wrong email',
    notData: 'No data',
    acceptDeleteTemplate: 'Are you sure you want to delete Template ?',
    delete: 'Delete',
    cancel: 'Cancel'
  },
  settings: {
    main: {
      exit: 'Logout',
      company: 'Company:',
      go_back: 'Come back',
      go_admin_panel: 'Go to the admin. panel',
      go_admin_panel_beta: 'Admin. panel',
      company_settings: 'Company settings',
      finance_and_balance: 'Finance and balance:',
      company_tariff: "The company's tariff",
      add_balance: 'Top up your balance',
      payments_history: 'Payment history',
      financical_report: 'Financial report',
      work_with_company: 'Working with the company:',
      invite_user: 'Invite a user',
      display_settings: 'Display settings',
      sessions: 'Sessions',
      additional: 'Additionally:',
      news_and_updates: 'News and updates',
      reference: 'Help (Wiki)',
      support: 'Support'
    },
    user: {
      edit: 'Editing',
      user: 'User:',
      access_level: 'Access level:',
      name: 'Name:',
      email: 'Email:',
      language: 'Language:',
      phone: 'Phone number:',
      code: 'Code:',
      mailing_id: 'Id mailing lists for Telegram:',
      note: {
        first: '1) Log in to the telegram bot: t.me/skif2_bot',
        second: '2) Click on Start, the bot returns the mailing ID for Telegram'
      },
      description: 'Description:',
      set_password: 'Set a password',
      change_password: 'Change password',
      change_access_level: 'Change access level',
      success: {
        update_info: 'The information has been successfully updated',
        avatar_change: 'The avatar has been successfully updated',
        change_password: 'The password has been successfully changed'
      },
      errors: {
        name: 'Enter name',
        email: 'Enter Email',
        phone_confirmation: 'Phone number not confirmed.',
        admin_strong_pass:
          "The password must have from 8 to 25 characters and consist of at least one uppercase English letters, al least one lowercase English letters and at least one special character (~  ! @ # $ % ^ & * ( ) - _ = + [ '{ ] '} | ; : ' (\") , < . > / ?)",
        user_strong_pass:
          "The password must have from 15 to 25 characters and consist of at least one uppercase English letters, at least one lowercase English letters and at least one special character (~  ! @ # $ % ^ & * ( ) - _ = + [ '{ ] '} | ; : ' (\") , < . > / ?)",
        simple_pass:
          'The password must have from 5 to 25 characters and consist of at least one English letter and at least one digit'
      }
    },
    display: {
      title: 'Display Settings',
      map: 'Map',
      hide_events: 'Hide events on the map:',
      monitoring: 'Monitoring',
      group_by_dots: 'Group if distance is less than (screen dots):',
      object_grouping: 'Group objects on the map:',
      show_annotation:
        'Show annotation when clicking on an object in the monitoring list:',
      notification: 'Notifications',
      hide_notification: 'Hide notifications on the map:',
      geozones: 'GEOZONES',
      show_geozones: 'Show geozones on the map:',
      meter: 'm',
      kilometer: 'km',
      hectar: 'ha',
      area_measurement: 'Area measurement units:',
      track_weight: 'Track Thickness on the Map:'
    },
    company: {
      title: 'Company Settings',
      name: 'Name:',
      time_zone: 'Time Zone:',
      integrator: 'Integrator:',
      exit_time: 'Exit After n Minutes of Inactivity',
      exit_time_tooltip:
        '0 or empty field - there will be no exit after inactivity',
      high_security_mode: 'High security mode',
      high_security_mode_hint:
        'To increase security, new users will need to create strong passwords when activating this mode. Such a password must contain at least 8 characters (for administrators at least 15 characters), include uppercase and lowercase letters, numbers and special characters. When changing the password of user, you need to log in using the administrator password, and then set your own password for the account. Thus, the administrator will not know the password from the user account.',
      retransmit_efis: 'Retransmit to EFIS',
      data_format: 'Date Format:',
      time_format: 'Time Format:',
      last_widget_time: 'Time of the Last Date in the Widget:',
      note: 'Specify the time format that will be applied when selecting the last date in a date range (in the date selection widget).',
      success: {
        update_company: 'Company settings have been successfully updated'
      }
    },
    tariff: {
      title: 'Tariff Information',
      on_balance: 'On balance:',
      accounting_type: 'Accounting type:',
      object_count: 'Max. number of objects:',
      object_price: 'Cost per 1 object:',
      period_start: 'Start of the current period:',
      payment_period: 'Payment period:',
      autopay: 'Autopay:',
      card: 'Card:',
      no_card_added: 'Card not added',
      turn_on_autopay: 'Turn on autopay',
      next_write_off: 'Next deduction:',
      last_period_sum: 'Sum for the previous period:',
      active_autopay: 'Autopay activated',
      by_number_objects: 'By the number of objects',
      by_number_objects_sent_data: 'By the number of objects that sent data',
      card_section: {
        add: 'Add Card',
        add_note:
          "To add an autopay card, we'll need to charge 1 ruble from your account. Provide your card details during payment.",
        select_system: '*Select payment system:',
        visa_master: 'Visa or Mastercard',
        mir_system: 'MIR System',
        temporarily_unavailable: 'Temporarily Unavailable',
        continue: 'Continue',
        errors: {
          mir_error: 'Autopay is temporarily unavailable with MIR cards.'
        }
      },
      success: {
        update_autopay: 'Autopayment have been successfully updated',
        delete_card: 'The card was successfully deleted'
      }
    },
    balance: {
      top_up_title: 'Top Up',
      company: 'Company:',
      top_up_amount: 'Top-Up Amount',
      min_amount: 'Minimum payment amount',
      currency: 'ruble',
      save_card: 'Save Card',
      turn_on_autopay: 'Enable Autopay',
      top_up: 'Top Up',
      mir_unavailable: 'MIR cards are temporarily unavailable for saving',
      errors: {
        amount: 'Enter the deposit amount'
      }
    },
    invite: {
      title: 'Invite User',
      user_email: 'User Email:',
      user_email_placeholder: 'Enter email',
      access_level: 'Access Level:',
      submit_button: 'Invite',
      errors: {
        user_email: 'Enter email',
        access_level: 'Select access level'
      },
      success: {
        invite: 'User has been successfully invited'
      }
    },
    support: {
      title: 'Support',
      description:
        'Good day! Employees of Monitoring Plus (Moscow) will be glad to answer your questions. To get in touch with us, please use one of the contacts below:',
      contacts: {
        telegram: 'Telegram: @Monitoring_plus',
        email: 'E-mail: monitoringplus@support.ru',
        phone: 'Phone (weekdays only from 9:00 to 18:00): 89008887712'
      }
    }
  },
  demoTourTranslater: {
    title: 'Your main tool is the map',
    text: 'We strive to provide users with a clear and complete view of objects, so our application places great emphasis on interacting with the map, as it is the main visualization tool.',
    next: 'Next',
    video: 'Interface Video',
    watchVideoYouTube: 'Watch YouTube Intro Video',
    interface: 'Interface video overview',
    modules: 'Modules',
    search: 'Search, notifications and settings',
    workMap: 'Work with the map',
    link: 'Contact developers'
  },
  helperTranslater: {
    helper: 'Assistant'
  },
  addLocatorTranslate: {
    selectObject: 'Нысанды таңдау',
    selectDateFrom: 'Бастау күнін таңдау',
    selectDateTo: 'Аяқтау күнін таңдау'
  },
  dealerTranslater: {
    register: 'New Dealer Registration',
    typeOrganization: 'Organization type',
    nameCompany: 'Company name*',
    enterNameCompany: 'Enter company name',
    INN: 'INN*',
    enterINN: 'Enter your INN',
    enterPhone: 'Enter your phone number',
    next: 'Next',
    registerNewDealer: 'New Dealer Registration',
    email: 'Email*',
    enterEmail: 'Enter your email',
    surname: 'Full name*',
    enterSurname: 'Enter your full name',
    password: 'Password*',
    enterPassword: 'Enter password',
    rewritePassword: 'Confirm Password*',
    enterCodeFromImage: 'Enter code from image to complete registration:',
    back: 'Back',
    goRegister: 'Register',
    address: 'address',
    services: 'services',
    tariff_description: 'tariff description',
    enter: 'Enter',
    confirmHeader: 'Registration of a new integrator is completed',
    confirmMsg: 'In the near future you will receive an email with a link to the support telegram chat; in the chat they will answer all your questions and help you with immigration from another platform.',
    confirmMaterials: 'Additional materials:',
    confirmFastStart: 'Fast start',
    confirmDescBill: 'Tariff description',
    confirmDescAdmin: 'Description of the Admin panel',
    confirmDescUi: 'Interface Description',
    confirmContacts: 'Contacts:',
    confirmGoToApp: 'Go to application',
    confirmApp: 'application',
    confirmDownload: 'Download'
  },
  unitsVisiblityTranslater: {
    filterFormMonitoring: 'Filtering from Monitoring applied.',
    notShowAllObject: 'Not all objects are shown.',
    showAllObject: 'Show all objects'
  },
  monitoringListTranslater: {
    withoutgroup: 'Without a group'
  },
  annotationCameraTranslater: {
    video: 'Video',
    object: 'Object:',
    videoRegister: 'Video Recorder:',
    grid: 'Grid',
    ribbon: 'Ribbon',
    see: 'See'
  },
  sensorNotificationTranslater: {
    inFrame: 'In frame',
    outFrame: 'Out of frame'
  },
  reportEdit: {
    sensor: {
      addTotalValue: 'Add a total to the report:',
      hintText:
        'Add the resulting value in the total row for this column in the generated report.'
    }
  },
  reportsTranslater: {
    noData: 'No data to build report',
    rewrite: 'Re-request'
  },
  // Редактирование шаблона отчета - фильтрация интервалов
  reportsFilter: {
    title: 'Filtering',
    filterByTime: 'Filtering by time intervals:',
    filterHintSelectInterval:
      'Select time intervals by which the data in the report will be filtered.',
    addInterval: 'Add interval',
    daysWeek: 'Days of the week:',
    trimmerInterval: 'Trim intervals:',
    filterHintFunction:
      'If the function is enabled, a state (for example, a trip) that starts inside the interval but ends outside it will be truncated. Only the duration within the specified interval will be taken into account in the report, the rest will be discarded.',
    zeroInterval: 'Zero interval!'
  }
}

export default translation

import { v4 as uuidv4 } from 'uuid'
let WS
let wsUserId

export const initws = () => {
  let id = uuidv4()
  wsUserId = id
  WS = new WebSocket(
    `${location.protocol === 'https:' ? 'wss' : 'ws'}://${
      location.host
    }/messages?id=${wsUserId}`
  )
  return WS

  // setTimeout(() => {
  //   WS.close()
  // }, 43200000)
}
export const getws = () => {
  return WS
}
export const getwsUserId = () => {
  return wsUserId
}

export const closews = () => {
  WS.close()
}

// const notifyWs
// export const initNotifyWS = () => {
//   notifyWs = new WebSocket(
//     `${process.env.NODE_ENV === 'development' ? 'ws' : 'wss'}://${
//       location.host
//     }/notifications/messages`
//   )
//   setTimeout(() => {
//     notifyWs.close()
//   }, 43200000)
//   return notifyWs
// }
// export const getNotifyWS = () => {
//   return notifyWs
// }

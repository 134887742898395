const currentUrl = window.location.href
const translation = {
  'translation.key': 'value',
  'loginPage.login.title': 'Вход',
  'loginPage.login.submit': 'Войти',
  'loginPage.login.guest': 'Демо вход',
  'loginPage.login': 'Логин',
  'loginPage.email': 'E-mail',
  'loginPage.pass': 'Пароль',
  'loginPage.timeZone': 'Часовой пояс',
  'loginPage.lang': 'Язык',
  'loginPage.userName': 'Имя пользователя',
  'loginPage.nameCompany': 'Имя компании',
  'loginPage.textTermsOfUse':
    'Нажимая "Зарегистрироваться" Вы принимаете условия ',
  'loginPage.linkTermsOfUse': '"Пользовательского соглашения"',
  'name.column': 'Название колонки',
  'valid.text': 'Заполните поле',
  back: 'назад',
  'come.back': 'Вернуться',
  'pixels.group': 'точек экрана',
  delete: 'Удалить',
  'monitoring.filterMax': 'Нельзя добавить более 30 фильтров',
  // Регистрация
  'loginPage.register.title': 'Регистрация',
  'loginPage.register.submit': 'Зарегистрироваться',

  // Qr code
  'loginPage.haveAccount': 'У меня есть учетная запись ',
  'loginPage.passHint': 'Ваш пароль состоит минимум из 4 символов.',
  'loginPage.sixCode': 'шестизначный код:',
  'loginPage.carName': 'название машины',
  'loginPage.autoType': 'Тип авто:',
  'loginPage.autoName': 'Имя авто:',
  'loginPage.addObject': 'Добавить объект',
  'loginPage.dontHaveAccount': 'Я новый пользователь SKIF.PRO2',
  'loginPage.enterCode': 'Введи шестизначный код:',
  'loginPage.phoneHolder': 'телефон в формате +XXXXXXXXXXXX',
  'loginPage.mail': 'Ваш email',
  'loginPage.password': 'Пароль:',
  'loginPage.yourpassword': 'Ваш пароль',
  'loginPage.offer': 'оферте',
  'loginPage.accept': ' Я принимаю условия изложенные в ',
  'loginPage.register': 'Зарегистрироваться',
  'loginPage.orgName': 'Название вашей организации:',
  'loginPage.orgName.holder': ' название организации',
  'loginPage.timeZones': 'Часовой пояс:',
  'loginPage.name': 'Ваше имя',
  'loginPage.name.label': 'Имя:',
  'loginPage.back': 'Назад',
  'loginPage.validation.sixCode': 'Вы должны ввести шестизначный код:',
  'loginPage.validation.codeType':
    'шестизначный код должен состоять из заглавных английских букв и цифр',
  'loginPage.validation.choosecartype': 'Выберите тип машины',
  'loginPage.validation.tiemzone': 'Выберите  часовой пояс',
  'loginPage.validation.entercarname': 'Введите имя машины',
  'loginPage.validation.name': ' Введите Ваше имя',
  'loginPage.validation.telephone': 'Введите Ваш телефон',
  'loginPage.validation.email': 'Введите Ваш email',
  'loginPage.validation.password': 'Введите Ваш пароль',
  'loginPage.validation.email.correctform':
    'введите правильный адрес электронной почты',
  'loginPage.validation.enter6code': 'Вы должны ввести шестизначный код',
  'loginPage.validation.acceptTerms': 'пожалуйста, примите условия',

  // Смена пароля
  'loginPass.changePass.adminPass':
    'Введите пароль полученный от администратора',
  'loginPass.recovery.title': 'Восстановление пароля',
  'loginPass.recovery.hint':
    'На указанный e-mail будет отправлено письмо с ссылкой на восстановление пароля',
  'loginPage.recovery.toMain': 'На главную',
  'loginPass.recovery.content':
    'Для восстановления аккаунта, перейдите по ссылке в письме,отправленном на  эл. почту',
  'loginPass.changePass.title': 'Смена пароля',
  'loginPass.changePass.old_pass': 'Старый пароль',
  'loginPass.changePass.newPass': 'Новый пароль',
  'loginPass.changePass.newPass_confirm': 'Новый пароль еще раз',
  'loginPass.changePass.inputPass': 'Введите пароль',
  'loginPass.changePass.inputNewPass': 'Введите новый пароль',
  'loginPass.changePass.current': 'Текущий пароль',
  'loginPass.changePass.empty':
    'Если оставить поле пустым,Вам придет подтверждение о смене пароля на электронную почту',
  'loginPass.changePass.repeatNewPass': 'Повторите пароль',
  'loginPass.changePass.confirmNewPass': 'Подтвердите пароль',
  'loginPass.changePass.submit': 'Войти',
  'loginPass.errors.pass': 'Введите пароль',
  'loginPass.errors.newPass': 'Введите пароль еще раз',
  'loginPass.errors.notMatchPass': 'Введенные пароли не совпадают',
  'loginPass.errors.lengthPass': 'Пароль должен быть от 5 до 25 символов',
  'loginPass.errors.lengthPass.strongPass.regularUser':
    'Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~  ! @ # $ % ^ & * ( ) -  = + [ "{ ] "} | ; : " " , < . > / ?)',
  'loginPass.errors.lengthPass.strongPass.adminUser':
    'Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~  ! @ # $ % ^ & * ( ) -  = + [ "{ ] "} | ; : " " , < . > / ?)',

  // Выбор компании
  'loginPage.company.title': 'Выбор компании',
  'loginPage.company.name': 'Имя компании',
  'loginPage.company.submit': 'Войти',

  // Подтверждение
  'loginPage.confirm.title': 'Подтверждение',
  'loginPage.confirm.text1': 'Компания зарегистрирована.',
  'loginPage.confirm.text2':
    ' Для подтверждения регистрации, перейдите по ссылке в письме, отправленном на указанную почту ',
  'loginPage.confirm.submit': 'На главную',

  // Восстановление
  'loginPass.recovery.back': 'Назад',
  'loginPage.recovery.title': 'Восстановление',
  'loginPage.recovery.text1': 'Для восстановления аккаунта перейдите',
  'loginPage.recovery.text2':
    'по ссылке в письме, отправленном на указанную почту.',

  // Ссылки
  'loginPage.links.ifHaveNotAccount': 'Нет аккаунта?',
  'loginPage.links.ifHaveAccount': 'Есть аккаунт?',
  'loginPage.links.register': 'Регистрация',
  'loginPage.links.lostPass': 'Забыли пароль?',
  'loginPage.links.login': 'Войти',

  // Ошибки почты
  'loginPage.errors.email': 'Введите email',
  'loginPage.errors.emailError': 'Введите существующий email',
  'loginPage.errors.pass': 'Введите пароль',
  'loginPage.errors.name': 'Введите имя',
  'loginPage.errors.nameCompany': 'Введите имя компании',
  'loginPage.errors.region': 'Выберите регион',
  // Таймзона
  'loginPage.hint.timeZone': 'Выберите часовой пояс',
  'loginPage.hint.lang': 'Выберите язык',
  'loginPage.hint.companyName': 'Выберите компанию',

  // Кнопки
  'loginPage.btn.send': 'Отправить',

  // Telegram
  'loginPage.telegramChatId': 'Id-рассылки для Telegram',
  'loginPage.errors.telegramChatId': 'Введите id-рассылки для Telegram',
  'loginPage.enterTheTelegramBot': 'Войдите в телеграм-бот',
  'loginPage.clickOnStartTheBot-':
    'Нажмите на Start, бот возвращает ID рассылки для Telegram',

  // Кнопки на карте
  logout: 'Выйти',

  // Справочник помощи
  'guide.title': 'Справка',
  'guide.short_instruction': 'Краткие инструкции',
  'guide.quiestion_offer': 'ВопросПредложение',
  'guide.instructions': 'Инструкции',
  'guide.back': 'Назад',
  'guide.personal_account': 'Личный кабинет дилера',
  'guide.company_workplace': 'Рабочее место компании',

  // Обратная связь
  'feedback.question_offer': 'ВопросПредложение',
  'feedback.description':
    'Поделитесь с нами своим опытом пользования нашим продуктом',
  'feedback.yours': 'Ваш отзыв',
  'feedback.fill_area': 'Заполните поле',
  'feedback.send': 'Отправить',
  'feedback.message_send': 'Ваш запрос отправлен. Спасибо за обращение!',

  // Меню
  'menuSelect.reports': 'Отчеты',
  'menuSelect.monitoring': 'Мониторинг',
  'menuSelect.geozones': 'Геозоны',
  'menuSelect.notifications': 'Уведомления',
  'menuSelect.users': 'Пользователи',
  'menuSelect.routes': 'Маршруты',
  'menuSelect.objects': 'Объекты',
  'menuSelect.race': 'Рейсы',

  // Карта
  'map.search': 'Поиск точного адреса',
  'map.adrress_not_found': 'Адрес не найден',
  'map.title': 'Карта',

  // Пользователи
  'catalog.user': 'Справочник пользователя',
  'btn.select': 'Выбрать',
  'btn.editlist': 'РЕДАКТИРОВАТЬ СПИСОК',
  'btn.finisheditlist': 'ЗАКОНЧИТЬ РЕДАКТИРОВАНИЕ',
  'btn.delete': 'Удалить',
  'btn.cancel': 'Отмена',
  'btn.create': 'Создать',
  'btn.update': 'Обновить',
  'btn.save': 'Сохранить',
  'user.invite_user': 'Пригласить',
  'user.invite_all': 'Пригласить во все компании',
  'user.invite_email': 'Email',
  'user.invite_role': 'Роль',
  'user.invite_role_error': 'Введите роль!',
  'user.enter_language': 'Введите язык!',
  'user.history_change': 'История изменений',
  'user.create_user': 'Создание пользователя',
  'user.edit_user': 'Редактирование пользователя',
  'users.hint.role': 'Выберите роль пользователя',
  'users.hint.lang': 'Выберите язык',
  'users.subscribe': 'Пригласить пользователя',
  'users.approve_close_form':
    'Изменения не сохранены. Вы действительно хотите закрыть профиль без сохранения?',
  'users.close_form': 'Закрыть профиль',
  'users.invite': 'Пригласить',
  'users.invite_all': 'Пригласить во все компании',
  'users.name': 'Имя',
  'users.email': 'Почта',
  'users.change_password_title': 'Изменить пароль',
  'users.sessions': 'Сессии',
  'users.enter': 'Вход',
  'users.exit': 'Выход',

  'users.change_password': 'Изменить',
  'users.current_password': 'Текущий пароль',
  'users.invite_level_access': 'Уровень доступа',
  'users.email_placeholder': 'Введите почту',
  'users.password_empty':
    'Если оставить поле пустым,Вам придет подтверждение о смене пароля на электронную почту',
  'users.isDriver': 'Водитель',
  'users.invalid_email': 'E-mail не подтверждён.',
  'users.confirm_notification':
    'Пользователь не будет получать уведомления по e-mail до тех пор, пока не подтвердить email.',
  'users.confirm': 'Подтвердить',
  'users.invalid_phone': 'Телефон не подтверждён.',
  'users.confirm_message':
    'будет отправлено письмо с просьбой подтвердить согласие на получение информационных сообщений от системы SKIF.PRO .',
  'users.confirm_link':
    'будет отправлено сообщение с ссылкой. Перейдите по этой ссылке, чтобы подтвердить согласие на получение информационных смс от системы SKIF.PRO .',
  'users.success_email_send': 'Письмо успешно отправлено.',
  'users.success_message_send': 'Сообщение успешно отправлено.',

  // Геозоны
  'geozones.square': 'Площадь:',
  'geozones.library': 'Справочник геозон',
  'geozones.create': 'Добавление геозон',
  'geozones.edit': 'Редактирование геозоны',
  'geozones.add': 'Добавить',
  'geozones.color': 'Цвет',
  'geozones.name': 'Название',
  'geozones.figure': 'Фигура',
  'geozones.type': 'Тип',
  'geozones.width': 'Толщина',
  'geozones.editor': 'Редактирование',
  'geozones.delete': 'Удалить',
  'geozones.use_as_address': 'Использовать как адрес',
  'geozones.unitArea': 'Единицы измерения площади:',
  'geozones.meter': 'м',
  'geozones.km': 'км',
  'geozones.hectar': 'га',
  'geozones.red': 'Красный',
  'geozones.orange': 'Оранжевый',
  'geozones.blue': 'Синий',
  'geozones.grey': 'Серый',
  'geozones.green': 'Зеленый',
  geozones: {
    errors: {
      min_dots_polygon: 'Нужно минимум 3 точки для полигона!',
      min_dots_line: 'Нужно минимум 2 точки для линии!',
      put_marker: 'Поставьте маркере на карту!',
      draw_circle: 'Нарисуйте круг!',
      name: 'Введите имя!',
      width: 'Введите правильное значение ширины!'
    }
  },
  // Сессии
  'sessions.title': 'Сессии',
  'sessions.day': 'Сутки',
  'sessions.week': 'Неделя',
  'sessions.month': 'Месяц',
  'sessions.time': 'Дата/Время',
  'sessions.user': 'Пользователь',
  'sessions.event': 'Событие',
  'sessions.ip': 'IP-адрес',
  // Объекты
  'objects.total.group': 'Количество объектов',
  'objects.showmonitoring': 'Показать в Мониторинге',
  'objects.add.objects': 'Добавить объекты',
  'objects.add.objects.in.group': 'Добавить объекты в группу',
  objects: 'Объект',
  object: 'Объект',
  'objects.name': 'Введите имя!',
  'objects.objects': 'Объекты',
  'objects.groups': 'Группы',
  'objects.groups.acess': 'Доступ',
  'objects.groups.access': 'Права доступа к группе',
  'objects.groups.access-title': 'Права на группу',
  'objects.groups.close-all': 'Свернуть все',
  'objects.groups.objects-in-group': 'Объекты в группе',
  'objects.trailers': 'Прицепы',
  'objects.locators': 'Ссылки доступа',
  'objects.locators-date-start': 'Дата и время начала доступа',
  'objects.locators-date-end': 'Дата и время завершения доступа',
  'objects.locators-date-select': 'Выберите дату',
  'objects.locators-link': 'Ссылка',
  objects_locator_create: 'Создание ссылки доступа',
  objects_locator_change: 'Редактирование ссылки',
  'objects.locator.copy-link': 'Ссылка скопирована',
  'objects.locator.status': 'Статус',
  'objects.locator.access-object': 'Объект доступа',
  'objects.users': 'Пользователи',
  'objects.delete': 'Удаление',
  'objects.create.new.group': 'Создание группы',
  'objects.create.edit.group': 'Редактирование группы',
  'objects.add.units.to.group': 'Добавить объекты в группу',
  'objects.name.group': 'Название группы',
  'objects.title': 'Справочник объектов',
  'objects.titleTrailers': 'Справочник прицепов',
  'objects.titleLocators': 'Справочник ссылок доступа',
  'objects.titlegroups': 'Справочник групп',
  'objects.name.number': 'Имя/Номер',
  'objects.type': 'Тип объекта',
  'objects.unit': 'Единица измерения',
  'objects.device': 'Тип устройства',
  'objects.imei': 'IMEI',
  'objects.editImei': 'Редактировать выбранный IMEI',
  'units.update': 'Редактирование обьекта',
  'units.create': 'Создание объекта',
  'objects_tabs.main': 'Основные',
  'objects_tabs.extra': 'Дополнительные',
  'objects_tabs.sensor': 'Датчики',
  'objects_tabs.TO': 'ТО',
  'objects_tabs.main.analyst': 'Аналитик',
  'objects_tabs.main.name.error': 'Введите Имя/Номер',
  'objects_tabs.main.type.error': 'Выберите тип объекта',
  'objects_tabs.main.device.error': 'Выберите тип устройства',
  'objects_tabs.main.id.error': 'Выберите уникальный ID',
  'objects_tabs.main.phone.error': 'Введите телефон',
  'objects_tabs.main.analyst.error': 'Выберите аналитика',
  'objects_tabs.extra.motohours': 'Моточасы',
  'objects_tabs.extra.trips': 'Поездки',
  'objects_tabs.extra.fuel': 'Топливо',
  'objects_tabs.extra.etc': 'Дополнительно',
  'objects_tabs.extra.custom': 'Пользовательские',
  'objects_tabs.extra.name': 'Имя',
  'objects_tabs.extra.value': 'Значение',
  'objects_tabs.extra.addNewField': 'Добавить новое поле',
  'objects_tabs.extra.changeField': 'Изменить поле',
  'objects_tabs.extra.update_field': 'Обновить поле',
  'objects_tabs.sensors.units': 'Единица измерения',
  'objects_tabs.sensors.hide_in_monitoring': 'Скрывать в мониторинге',
  'objects_tabs.sensors.sensors_create': 'Создание датчика',
  'objects_tabs.sensors.sensors_edit': 'Редактирование датчика',
  'objects_tabs.sensors.type_sensor': 'Тип датчика',
  'objects_tabs.sensors.error_type_sensor': 'Введите тип датчика!',
  'objects_tabs.sensors.error_formula': 'Выберите формулу!',
  'objects_tabs.sensors.error_param12': 'Выберите параметр 1 или параметр 2!',
  'objects_tabs.sensors.option1': 'Параметр 1',
  'objects_tabs.sensors.option2': 'Параметр 2',
  'objects_tabs.sensors.formula': 'Формула',
  'objects_tabs.sensors.formula.columns': 'Формула колонки',
  'objects_tabs.sensors.hide_in_reports': 'Скрывать в отчетах',
  'objects_tabs.sensors.description': 'Описание',
  'objects_tabs.sensors.border_bottom': 'Нижняя граница',
  'objects_tabs.sensors.coefficientA': 'Коэффициент А',
  'objects_tabs.sensors.coefficientB': 'Коэффициент Б',
  'objects_tabs.sensors.valueX': 'Значение X',
  'objects_tabs.sensors.valueY': 'Значение Y',
  'objects_tabs.sensors.enterValue': 'Введите значение!',
  'objects_tabs.sensors.keyExist': 'Такой ключ уже существует!',
  'objects_tabs.sensors.upperBorder': 'Верхняя граница X',
  'objects_tabs.sensors.lowerBorder': 'Нижняя граница X',
  'objects_tabs.sensors.smoothing': 'Сглаживание',
  'objects_tabs.sensors.coefficient': 'Коэффициент',
  'objects_tabs.sensors.selectValueX': 'Выберите значение x!',
  'objects_tabs.sensors.selectValueY': 'Выберите значение !',
  'objects_tabs.sensors.selectFromValue': 'Выберите fromValue!',
  'objects_tabs.sensors.selectA': 'Выберите значение a!',
  'objects_tabs.sensors.selectB': 'Выберите значение b!',
  'objects_tabs.sensors.actions': 'Действия',
  'objects_tabs.sensors.show_graph': 'Показать график',
  'objects_tabs.sensors.show_table': 'Показать таблицу',
  'objects_tabs.drivers.driver': 'Водитель',
  'objects_tabs.drivers.from': 'С',
  'objects_tabs.drivers.to': 'По',
  'objects_tabs.drivers.add': 'Добавить водителя',
  'objects_tabs.drivers.change': 'Изменить водителя',
  'objects_tabs.trailer': 'Прицеп',
  'objects_tabs.trailers': 'Прицепы',
  'objects_tabs.trailer.add': '"Добавить прицеп',
  'objects_tabs.trailer.change': 'Изменить прицеп',

  'objects_tabs.services.create_form': 'Создание ТО',
  'objects_tabs.services.edit_form': 'Редактирование ТО',
  'objects_tabs.services.name_service': 'Cервис',
  'objects_tabs.services.description': 'Описание',
  'objects_tabs.services.active_on_interval_mileage':
    'Активировать интервал по пробегу',
  'objects_tabs.services.active_on_interval_days':
    'Активировать интервал в днях',
  'objects_tabs.services.interval_days_numb_day': 'Интервал в днях, число/дни',
  'objects_tabs.services.last_one_date': 'Последний раз, дата',
  'objects_tabs.services.interval_mileage_numb_km':
    'Интервал по пробегу, число/км',
  'objects_tabs.services.last_one_numb_km': 'Последний раз, число/км',
  'objects_tabs.services.interval_mileage': 'Пробег',
  'objects_tabs.services.interval_days': 'Дни',
  'objects_tabs.services.warning.name': 'Введите названия сервиса!',
  'objects_tabs.services.warning.description': 'Введите описание!',
  'objects_tabs.services.warning.date': 'Введите дату!',
  'objects_tabs.services.date.placeholder': 'Введите дату!',
  'objects_tabs.services.mileageInterval': 'Введите текущее показание пробега',
  'objects_tabs.services.lastTime': 'Введите промежуток пробега',
  'objects_tabs.services.intervalInDays': 'Введите интервал в днях!',
  'objects_tabs.services.intervalInMotoHours':
    'Активировать интервал по моточасам',
  'objects_tabs.services.intervalInMotoHoursDate':
    'Интервал по моточасам, число/часы',
  'objects_tabs.services.lastTimeDate': 'Последний раз, число/часы',
  'objects_tabs.services.lastTimeDate.warning': 'Введите последний раз!',
  objects_trailer_create: 'Создать прицеп',
  objects_trailer_change: 'Изменить прицеп',
  objects_trailer_moreFields: ' Дополнительные поля',
  objects_trailer_key: ' Ключ',
  objects_phone1: 'Телефон 1',
  objects_phone2: 'Телефон 2',
  objects_noPicture: 'Изображение не выбрано',
  objects_server: 'Сервер получения данных: ',
  objects_port: 'Порт: ',
  'objects.fillfield': 'Необходимо заполнить поля в разделе Основные',
  'trailer.width': 'ширина прицепа (метр)',
  // events
  'events.create': 'Создать событие',
  'events.update': 'Редактировать событие',
  'events.datetime': 'Дата/Время',
  'events.deviation_minutes': 'Отклонение, мин.',
  'events.filled': 'Заправлено топлива, л.',
  'events.consumed': 'Расход топлива, л.',
  'events.price': 'Стоимость',
  'events.description': 'Описание',
  'events.stolen': 'Слито топлива, л.',
  'events.service_name': 'Сервис',
  'events.motohours': 'Моточасы, ч.',
  'events.millage': 'Пробег км.',
  'events.duration_hours': 'Длительность ч.',
  'events.guaranteed': 'Гарантийный',
  'events.start_date': 'Начало',
  'events.finish_date': 'Окончание',
  'events.user_id': 'Водитель',
  'events.type_name': 'Тип события',
  'events.duration_minutes': 'Длительность, мин',
  'events.type_duration_hours': 'Длительность, ч.',
  'events.requiredFields': 'Обязательные поля',

  'events.validation.required': 'Обязательное поле',
  'events.validation.datetime': 'Введите дату и время',
  'events.validation.number': 'Должно быть числом больше 0',

  'error.502':
    'К сожалению, сервер временно недоступен. Попробуйте повторить действие чуть позже.',
  'error.429': 'Слишком много попыток, попробуйте позже.',

  // Отчеты
  reports: 'Отчеты',
  'reports.chart.intervals': 'Интервалы',
  'reports.chart.thefts_markers': 'Интервалы сливов',
  'reports.chart.fillings_markers': 'Интервалы заправок',
  'reports.chart.thefts': 'Сливы',
  'reports.chart.fillings': 'Заправки',
  'reports.build-report': 'Построить',
  'reports.base-table': 'Базовая таблица',
  'reports.group-table': 'Группа таблиц',
  'reports.select-table': 'Выбор таблицы',
  'reports.select-template': 'Выбор шаблона таблиц',
  'reports.select-object': 'Выбор транспортного средства',
  'reports.select-group-object': 'Выбор группы объектов',
  'reports.not-select': 'Не выбрано',
  'reports.object': 'Объект',
  'reports.group-object': 'Группа объектов',
  'reports.period': 'Период',
  'reports.reports-visit-geozone': 'Отчет по посещению геозон',
  'reports.reports-size-screen.minimum': '1/4',
  'reports.reports-size-screen.thirdScreen': '1/3',
  'reports.reports-size-screen.medium': '1/2',
  'reports.reports-size-screen.maximum': 'На весь экран',
  'reports.was-deleted': 'был удален',
  'reports.add-group-report': 'Добавить в шаблон',
  'reports.edit-report': 'Редактировать',
  'reports.load-report': 'Скачать',
  'reports.print-report': 'Печать',
  'reports.show-chart': 'График',
  'reports.show-color': 'Цвет трека',
  'reports.сomposite_report': 'Составной отчет',
  'reports.map_snapshot': 'Снимок карты',
  'reports.deleted_messaged': 'Удаленные сообщения',
  'reports.map_note': 'Щелкните, чтобы увидеть точку на карте',
  'reports.action': 'Действия',
  'reports.table.type': 'Тип Таблицы',
  'reports.edit-reports': 'Редактирование параметров отчета',
  'reports.add-table-to-reports': 'Добавить таблицу из шаблона',
  'reports.reports-template': 'Редактирование шаблона',
  'reports.tab-main': 'Общее',
  'reports.tab-columns': 'Колонки',
  'reports.tab-geozones': 'Геозоны',
  'reports.tab-main.group': 'Группировка',
  'reports.tab-main.group-field': 'Группировка по полю',
  'reports.tab-main.show-total': 'Показывать итог',
  'reports.tab-main.interval-priority': 'Приоритет интервалов отчета',
  'reports.tab-main.interval-priority-tooltip': `Если эта настройка активна и интервал разрезается началом или концом Смены, то он в отчет попадает полностью.
    <br>
    Если же эта настройка не активна, то в отчет попадет точный интервал Смены.
    <br>
    Например если смена начинается 08:00, а поездка началась в 06:00,
    <br>
    то в случае активной настройки “Приоритет интервалов отчета”, интервал в отчете Поездки начнется в 06:00,
    <br>
    а если настройка “Приоритет интервалов отчета” не активна - то в 08:00.`,
  'reports.editTemplate.nameTemplate': 'Название шаблона',
  'reports.add-new-template': 'Добавить новый шаблон',
  'reports.name-template': 'Имя шаблона',
  'reports.add-in-template': 'Добавить в шаблон',
  'reports.is-already-template': 'Существующий',
  'reports.new-temlate': 'Новый',
  'reports.templateWarning': 'Введите имя шаблона!',
  'reports.tables': 'Таблицы',
  'reports.onlyResult': 'Показать только итоги',
  'reports.connect': 'Связать с графиком',
  'reports.attached': 'Файл прикреплен',
  'reports.tables.report': 'Отчет',
  'reports.column': 'Колонка',
  'reports.operation': 'Операция',
  'reports.value': 'Значение',
  'reports.contains': 'содержит',
  'reports.success_copy_template': 'Шаблон успешно скопирован.',
  'reports.create_template': 'Создать шаблон',
  'reports.edit_template': 'Изменить шаблон',
  'reports.tables': 'Таблицы',
  'reports.enter_name': 'Введите имя!',
  'reports.template': 'Шаблон',
  'reports.without_grouping': 'Без группировки',
  'reports.form_for_unloading': 'Форма для выгрузки (Шаблон excel)',
  'reports.show_key': 'Смотреть список ключей для Excel-шаблона',
  'reports.tables.tabs.general': 'Общее',
  'reports.tables.tabs.columns': 'Колонки',
  'reports.tables.tabs.sensors': 'Датчики',
  'reports.tables.tabs.additional_columns': 'Доп. колонки',
  'reports.tables.tabs.data': 'Данные',
  'reports.tables.tabs.hint':
    'В закрытых отчетах пользователь не может изменить датчики и параметры по которым он строится.',
  'reports.tables.tabs.mask_sensor': 'Маски датчиков',
  'reports.tables.tabs.mask_sensor_hint':
    'Для формирования отчета выбираются те датчики объекта, которые будут удовлетворять хотя бы одной из указанных масок (читайте подробнее на ',
  'reports.tables.tabs.type_table': 'Тип таблицы:',
  'reports.tables.tabs.table_name': 'Название:',
  'reports.tables.tabs.all_protocol': 'Все протоколы',
  'reports.tables.tabs.parameters': 'Выберите параметр',
  'report.tables.tabs.name_mask': 'Маска названия датчиков',
  'report.tables.tabs.enter_value': 'Введите значение',
  'report.tables.tabs.add_mask': 'Добавить маску по названию датчиков',
  'report.tables.tabs.parameters': 'Параметры',
  'report.tables.tabs/parameter_hint':
    'Выберите названия параметров, которые будут участвовать при формировании отчета.',
  'report.tables.tabs.parameter': 'Параметр',
  'report.tables.tabs.add_parameter': 'Добавить параметр',
  'reports.tables.tabs.make_close_report': 'Сделать отчет закрытым',
  'reports.tables.tabs.geozones': 'Геозоны',
  'reports.tables.fields.name': {
    label: 'Имя',
    error: 'Введите имя!'
  },
  'reports.tables.add_mask_sensor': 'Добавить маску по названию датчиков',
  'reports.tables.fields.grouping': 'Группировка',
  'reports.tables.fields.grouping_by_field': 'Группировка по полю',
  'reports.tables.fields.sensor_name_mask': 'Маска названия датчиков',
  'reports.tables.fields.template_upload': 'Загрузить',
  'reports.tables.fields.files_warn':
    'Можно загружать файлы только в форматах xlsx  xlsm  xlsb',
  'reports.tables.fields.excel_template_name': 'Имя Шаблона Excel',
  'reports.tables.fields.geozone_warn':
    'Требуется выбрать хотя бы одну геозону входа и одну геозону выхода.',
  'reports.tables.fields.showfillings': 'Отображать сливы',
  'reports.tables.fields.showThefts': 'Отображать заправки',
  'reports,tables.fields.mask_name_sensor': 'Маска названия датчиков топлива',
  'reports.tables.fields.add_mask': 'Добавить маску по названию датчиков',
  'reports.tables.geozones.title': 'Геозоны',
  'reports.tables.geozones.enter': 'Вход',
  'reports.tables.geozones.exit': 'Выход',
  'reports.tables.columns.fields.column': 'Колонка',
  'reports.tables.columns.fields.visibility': 'Видимость',
  'reports.tables.columns.fields.filter': 'Фильтровать',
  'reports.tables.columns.fields.min_value': 'Мин. значение',
  'reports.tables.columns.fields.max_value': 'Макс. значение',
  'reports.tables.columns.fields.name.label': 'Имя колонки',
  'reports.tables.columns.fields.name.error': 'Введите название',
  'reports.download_selected': 'Загрузить выбранные',
  'reports.from_monitoring': 'из Мониторинга',
  'reports.applied_filter': 'Применена фильтрация из Мониторинга.',
  'reports.not_all_objects_displayed': 'Отображаются не все объекты.',
  'reports.show_all_objects': 'Показать все объекты',
  'reports.select_object': 'Выберите объект!',
  'reports.select_objects_group': 'Выберите группу объектов!',
  'reports.template_deletion': 'Удаление шаблона',
  'reports.template_deletion_sure':
    'Вы действительно хотите удалить шаблон отчета',
  'reports.increase': 'Увеличить',
  'reports.reduce': 'Уменьшить',
  'reports.moving': 'Перемещение',
  'reports.reset_magnification': 'Сбросить увеличение',
  'reports.select_with_magnification': 'Выбор с увеличением',
  'reports.select': 'Выбор',
  'reports.menu': 'Меню',
  'reports.link_to_magazine': 'Связать с журналом',
  'reports.display_all_events': 'Отобразить все события',
  'reports.show_annotation_on_click': 'Аннотация по клику на строку',
  'reports.show_annotation_on_click_hint':
    'Если активировать, то при при клике на строку отчета отображается аннотация по событию.',
  'reports.deleted_intervals_messages': 'Удаленные интервалы сооб-ний',
  'reports.interval': 'Интервал',
  'reports.exclude_interval': 'Исключить интервал',
  'reports.interval_info':
    'Удалённые интервалы сообщений не отображаются в журнале и не участвуют в формировании отчетов.',
  'reports.interval_for_deletion': 'Интервал для удаления сообщений',
  'reports.list_key': 'Список ключей для шаблона Excel',
  'reports.copy_key':
    'Скопируйте ключ в ячейку Excel-шаблона, а затем загрузите шаблон в SKIF.PRO',
  'reports.key_hint':
    'Создайте шаблон отчета в Excel, затем поместите в него данные скопировав соответствующие ключи. Сохраните шаблон и загрузите в SKIF.PRO, система сама найдёт и подставит значения вместо ключей в момент формирования отчета.',
  'reports.sensor_placeholder': 'Маска датчика',
  'reports.mini_hint':
    'После копирования выберите ячейку Excel-шаблона и нажмите CTRL+V.',
  'reports.copy': 'Копировать',

  // Легенда
  'tracking.speed.legend.text': 'Градация скорости',

  // Мониторинг
  monitoring: 'Мониторинг',
  'monitoring.datepoint': 'Был на связи',
  'monitoring.add-objects': 'Добавить объекты',
  'monitoring.filter-journal-events': 'Фильтр журнала событий',
  'monitoring.view-events': 'Вид события',
  'monitoring.objects': 'Объекты',
  'monitoring.send-command': 'Отправит команду',
  'monitoring.change-object': 'Изменить объект',
  'monitoring.registration-event': 'Регистрация события',
  'monitoring.fast-track': 'Быстрый трек',
  'monitoring.fast-report': 'Быстрый отчет',
  'monitoring.create-event': 'Создать событие',
  'monitoring.type-event': 'Тип события',
  'monitoring.position': 'Положение',
  'monitoring.violation': 'Нарушение',
  'monitoring.date-and-time': 'Дата и время',
  'monitoring.sendCommand-title': 'Отправить команду',
  'monitoring.enter-description': 'Введите описание',
  'monitoring.trips': 'Поездки',
  'monitoring.graph': 'График',
  'monitoring.editObject': 'Редактировать объект',
  'monitoring.activeFilters': 'Активные фильтры',
  'monitoring.choose': 'Выбрать',
  'monitoring.searchHolder': 'зажиг, топливо',
  'monitoring.addFilter': 'Добавить фильтр',
  'monitoring.accept': 'Применить',
  'monitoring.filters': 'Фильтры',
  'monitoring.maxUnits':
    'Нельзя отобразить более 300 объектов сразу, воспользуйтесь фильтром для уменьшения количества объектов.',
  'monitoring.filter.name': 'Имя',
  'monitoring.filter.imei': 'IMEI',
  'monitoring.filter.phoneNumber': 'Телефоны',
  'monitoring.filter.customFields': 'Доп. поля',
  'monitoring.filter.sensorName': 'Имена датчиков',
  'monitoring.filter.sensorType': 'Типы датчиков',
  'monitoring.filter.sensorParam': 'Параметр датчиков',
  'monitoring.filter.event': 'События',
  'monitoring.filter.driver': 'Водитель',
  'monitoring.filter.fieldsEmpty': 'Все поля должны быть заполнены',

  'monitoring.map_work_settings': 'Настройки работы с картой',
  'monitoring.objects_on_map': 'Объекты на карте:',
  'monitoring.distance_group':
    'Группировать, если расстояние менее (точек экрана):',
  'monitoring.objects_list': 'Список объектов:',
  'monitoring.show_annotation_on_click':
    'Показывать аннотацию при клике на объект в списке мониторинга',
  'monitoring.show_address': 'Показывать адрес объекта',
  'monitoring.apply_filter': 'Применить фильтры',
  'monitoring.objects_grouping': 'Группировка объектов',
  'monitoring.display_all': 'Отобразить все',
  'monitoring.track_all': 'Отслеживать все',
  'monitoring.columns.name': 'Название',
  'monitoring.fast_tracks': 'Быстрые треки',
  'monitoring.fast_tracks.today': 'За сегодня',
  'monitoring.fast_tracks.yesterday': 'За вчера',
  'monitoring.fast_tracks.week': 'За неделю',
  'monitoring.fast_tracks.month': 'За месяц',
  'monitoring.to_reports': 'В отчеты',
  'monitoring.commands': 'Команды',
  'monitoring.send_command': 'Отправить команду',
  'monitoring.command_response': 'Ответ',
  'monitoring.commands.columns.data': 'Дата/Время',
  'monitoring.commands.columns.command': 'Команда',
  'monitoring.commands.columns.status': 'Статус',
  'monitoring.commands.exit_number': 'Номер выхода',
  'monitoring.commands.error_message':
    'Заполните поле "Выберите объект" и "Команда"',
  'monitoring.commands.object': 'Объект',
  'monitoring.groups.edit': 'Редактирование',
  'monitoring.displayed_objects': 'Отображено объектов:',
  'monitoring.shape_size': 'Размер формы:',
  'monitoring.grouping_on_map': 'Группировка на карте',
  'monitoring.object_search': 'Поиск по названию объекта',
  'monitoring.search.name': 'Имя',
  'monitoring.search.imei': 'IMEI',
  'monitoring.search.phonenumber': 'Телефоны',
  'monitoring.search.custom_fields': 'Доп. поля',
  'monitoring.search.sensors_name': 'Имена датчиков',
  'monitoring.search.sensors_types': 'Типы датчиков',
  'monitoring.search.sensors_param': 'Параметр датчиков',
  'monitoring.search.events': 'События',
  'monitoring.search.driver': 'Водитель',
  'monitoring.search.groups': 'Группы',
  'monitoring.terminal_on': 'Терминал подключён',

  // Аннотации
  'annotation.data_date': 'Дата получения данных:',
  'annotation.reports': 'Отчеты:',
  'annotation.gps_data': 'GPS-Данные:',
  'annotation.sensors': 'Датчики:',
  'annotation.additional': 'Дополнительно:',
  'annotation.last_params': 'Последние данные:',

  // Журнал событий
  'journal-events': 'Журнал событий',
  'journal-events.filter': 'Фильтр',
  help: 'Справка',
  // Уведомления
  'notifications.notificationName': 'Название уведомления',
  'notifications.responsetime': 'Мин. время сработки уведомления',
  'notifications.periodcontrol':
    'Период контроля относительно текущего времени',
  'notifications.notification': 'Уведомления',
  'notifications.name': 'Название',
  'notifications.view': 'Вид',
  'notifications.objects': 'Объекты',
  'notifications.enterName': 'Введите имя!',
  'notifications.change': 'Изменить уведомление',
  'notifications.add': 'Добавить уведомление',
  'notifications.main': 'Основное',
  'notifications.more': 'Дополнительно',
  'notifications.motohours': 'По моточасам',
  'notifications.delivery': 'Доставка',
  'notifications.text': 'Текст',
  'notifications.control': 'Контроль',
  'notifications.types': 'Типы уведомлений',
  'notifications.markAsRead': 'Отметить прочитанным',
  'notifications.template': 'Шаблон уведомлений',
  'notifications.textDescription':
    'Введите текст уведомления, используя специальные параметры, приведеные ниже. Когда уведомление сработает, эти параметры будут заменены реальными значениями.',
  'notifications.enterNotifyText': ' Введите текст уведомления!',
  'notifications.paramTab': 'Табличка параметров:',
  'notifications.latitude': 'Широта',
  'notifications.longitude': 'Долгота',
  'notifications.driver': 'Водитель',
  'notifications.speed': 'Скорость',
  'notifications.objectName': 'Имя объекта',
  'notifications.sensors': 'Датчики',
  'notifications.sensorValue': 'Значение датчика',
  'notifications.adress': 'Адрес',
  'notifications.time': 'Время сработки',
  'notifications.fill': 'Заправка',
  'notifications.drain': 'Слив ',
  'notifications.final_fuel': ' Конечное топливо',
  'notifications.geozone_name': 'Имя геозоны',
  'notifications.tripName': 'Название рейса',
  'notifications.workType': 'Тип работ',
  'notifications.pointName': 'Название точки',
  'notifications.deviation': 'Отклонение',
  'notifications.days_to_service': 'Количество дней просрочки ТО',
  'notifications.km_to_service': 'Количество километров превышенного пробега',
  'notifications.hs_to_service': 'Количество превышенных моточасов',

  'notifications.deliveryMethod': 'Способ доставки',
  'notifications.chooseEmail': 'Выберите хотя бы 1 email!',
  'notifications.choosePhone': 'Выберите хотя бы 1 телефон!',
  'notifications.chooseUser': 'Выберите хотя бы одного пользователя',
  'notifications.chooseDeliveryType': 'Не выбран ни один тип доставки',
  'notifications.use_speed_limit': 'Ограничить возможность разгона свыше   ',
  km_hour: 'км/ч',
  'notification.use_speed_description':
    'Активация данного чекбокса не позволит водителю разогнать объект, свыше указанной максимальной скорости, нажатием на педаль газа, при входе в выбранные ниже геозоны.',
  'notifications.asEvent': 'Регистрировать как события',
  'notifications.asViolation': 'Регистрировать как нарушение',
  'notifications.temporaryValueMonitoring': 'Временный контроль значений',
  'notifications.from': 'с',
  'notifications.to': 'до',
  'notifications.monday': 'Понедельник',
  'notifications.tuesday': 'Вторник',
  'notifications.wednesday': 'Среда',
  'notifications.thursday': 'Четверг',
  'notifications.friday': 'Пятница',
  'notifications.saturday': 'Суббота',
  'notifications.sunday': 'Воскресенье',
  'notifications.geozones.control': 'Контроль геозон',
  'notifications.geozones.verificationType': 'Тип проверки',
  'notifications.geozones.chooseVerficationType': 'Выберите тип проверки!',
  'notifications.geozones.geozones': 'Геозоны',
  'notifications.simpleTransport': 'Простой транспортного средства',
  'notifications.maxSpeed': 'Скорость не более',
  'notifications.kmph': 'км/ч',
  'notifications.allowedDowntime': 'Допустимое время простоя',
  'notifications.min': 'мин',
  'notifications.warning': 'введите минимальную и максимальную скорость',
  'notifications.connexionLost': 'Потеря связи',
  'notifications.lossDuration': 'Длительность потери',
  'notifications.lostMsg': 'Выберите интервал по времени!',
  'notifications.sensor.control': 'Контроль значения датчика',
  'notifications.sensor.controleGeo': 'Контроль геозон',
  'notifications.sensor.type': 'Тип датчика',
  'notifications.sensor.maskByName': 'Маска по имени',
  'notifications.sensor.meaning': 'Значение',
  'notifications.sensor.warning': 'Выберите тип датчика или маску по имени!',
  'notifications.sensor.meaningFrom': 'Значение от',
  'notifications.sensor.minimalTime': 'Мин. время срабатывания:',
  'notifications.sensor.writeNumber': 'Введите число',
  'notifications.sensor.meaningTo': 'Значение до',
  'notifications.sensor.warningFromTo': 'Выберите значение от или до!',
  'notifications.sensor.work': 'Срабатывать',
  'notifications.sensor.selectFrame': 'Выберите рамки!',
  'notifications.service.maintenance':
    'Уведомлять о приближении срока планового ТО',
  'notifications.service.days': 'дней',
  'notifications.service.for': 'За',
  'notifications.service.byMilage': 'По пробегу',
  'notifications.service.km': 'км',
  'notifications.service.warning':
    'Введите  интервал по дням, пробегу или моточасам!',
  'notifications.trips.mask': 'Маска имени рейса',
  'notifications.trips.validationOne': 'Правильно введите названия сервиса!',
  'notifications.trips.alertEvent': 'События оповещения:',
  'notifications.trips.start': 'Начало рейса',
  'notifications.trips.end': 'Конец рейса',
  'notifications.trips.departure': 'Отправление из контрольных точек',
  'notifications.trips.onlyViolation': 'Только в случае нарушения:',
  'notifications.trips.arrCheckPoint': 'Прибытие в контрольные точки',
  'notifications.trips.pass': 'Пропуск контрольных точек (Строгий порядок)',
  'notifications.velocity.speed': 'Контроль скорости ',
  'notifications.velocity.min': 'Не менее',
  'notifications.velocity.max': 'Не более',
  'notifications.velocity.warning': 'Введите скорость не более или не менее!',
  'notifications.velocity.geozones': 'Геозоны',
  'notifications.online': 'Онлайн',
  'notifications.byEmail': 'По E-mail',
  'notifications.bySms': 'По Sms',
  'notifications.byPush': 'Push-уведомления',
  'notifications.byTelegram': 'На Telegram',
  'notifications.email.placeHolder': 'Введите email',
  'notifications.phone.placeHolder': 'Введите телефон...',
  'notifications.push.placeHolder': 'Введите имя или email',
  'notifications.telegram.placeHolder': 'Введите имя или telegram',
  'notifications.period': 'Период',
  'notifications.no_sound': 'Без звука',

  // notifications texts
  'notifications.text.arrival':
    'Уведомляем о приближении или просрочке планового техобслуживания у объекта %UNIT%',
  'notifications.text.exit':
    '%UNIT% в %TIME% зафиксирован вход в геозону / выход из геозоны на скорости %SPEED% возле %ADDRESS%',
  'notifications.text.sensor':
    ' %UNIT% в %TIME% зафиксировано включение датчика на скорости %SPEED% возле %ADDRESS%',
  'notifications.text.trip':
    '%UNIT% в %TIME% зафиксировано событие рейса возле %ADDRESS%',
  'notifications.text.speed':
    '%UNIT% в %TIME% превысил максимально допустимый предел скорости со значением %SPEED% возле %ADDRESS%',
  'notifications.text.connection':
    'Зафиксирована потеря связи или координат %UNIT% в %TIME% возле %ADDRESS%',
  'notifications.text.idle':
    'Объект %UNIT% в %TIME% превысил допустимое время простоя возле %ADDRESS%',
  'notifications.text.filling':
    '%UNIT% в %TIME% заправился на %FILL% до %LAST_FUEL% возле %ADDRESS%',
  'notifications.text.drain':
    '%UNIT% в %TIME% слил %DRAIN% до %LAST_FUEL% возле %ADDRESS%',
  'notifications.text.confirmation':
    'Пользователи не подтвердившие e-mail получат письмо с предложением сделать это. После подтверждения они начнут получать e-mail рассылки.',
  'notifications.text.events_routes': 'Cобытие рейса',
  'notifications.text.geozone.inside':
    '%UNIT% в %TIME% зафиксирован вход в геозону %GEOZONE% на скорости %SPEED% возле %ADDRESS%',
  'notifications.text.geozone.outside':
    '%UNIT% в %TIME% зафиксирован выход из геозоны %GEOZONE% на скорости %SPEED% возле %ADDRESS%',
  // trips & routes
  'routesTrips.title': 'Маршруты и рейсы',
  'routesTrips.routes': 'Маршруты',
  'routesTrips.trips': 'Рейсы',
  'routesTrips.name': 'Имя',
  'routesTrips.addTrip': 'Добавить рейс',
  'routesTrips.editTrip': 'Редактировать рейс',
  'routesTrips.trip.name': 'Имя*',
  'routesTrips.trip.date.placeholder': 'Выберите даты',
  'routesTrips.trip.date.set_days_period': 'Отфильтровать дни периода',
  'routesTrips.trip.date.reset_days_period': 'Восстановить дни периода',
  'routesTrips.trip.date.filter_days': 'Отфильтровать дни',
  'routesTrips.trip.date.total_days_period': 'Итоговые дни периода',
  'routesTrips.trip.date.cancel_days_period': 'Исключить праздничные дни',
  'routesTrips.trip.date.week_days.monday': 'ПН',
  'routesTrips.trip.date.week_days.tuesday': 'ВТ',
  'routesTrips.trip.date.week_days.wednesday': 'СР',
  'routesTrips.trip.date.week_days.thursday': 'ЧТ',
  'routesTrips.trip.date.week_days.friday': 'ПТ',
  'routesTrips.trip.date.week_days.saturday': 'СБ',
  'routesTrips.trip.date.week_days.sunday': 'ВС',
  'routesTrips.trip.date.week_days.all_days': 'Все дни',
  'routesTrips.trip.date.week_days.even': 'Четные',
  'routesTrips.trip.date.week_days.odd': 'Не четные',
  'routesTrips.trip.name.placeholder': 'Имя рейса...',
  'routesTrips.trip.validation': 'Поле обязательно для заполнения',
  'routesTrips.trip.validation.total_period':
    'Требуется добавить как минимум один итоговый день',
  'routesTrips.trip.object': 'Объект*',
  'routesTrips.trip.object.placeholder': 'Объект',
  'routesTrips.trip.activationType': 'Тип активации',
  'routesTrips.trip.activationType.tooltip':
    'Если выбрано “по времени первой точки” то начало рейса фиксируется строго по времени указанных в первой контрольной точке. Если выбрано “по входу в первую точку” то начало рейса фиксируется по времени входа в область первой точки, а все последующие времена сдвигаются на время разницы между временем “Прибытие” указанным в первой контрольной точке и временем входа в область первой точки.  Если выбрано “по выходу из первой точки” то начало рейста фиксируется по времени выхода из области первой точки, а все последующие контрольные времена сдвигаются на время разницы времени “Отправления” указанном в первой контрольной точке и временем выхода из области первой точки.',

  'routesTrips.trip.firstPoint': 'По времени первой точки',
  'routesTrips.trip.entranceToFirstPoint': 'По входу в первую точку',
  'routesTrips.trip.leavingToFirstPoint': 'По выходу из первой точки',
  'routesTrips.trip.order': 'Порядок прохождения',
  'routesTrips.trip.order.strict': 'Строгий',
  'routesTrips.trip.order.arbitrary': 'Произвольный',
  'routesTrips.trip.period': 'Период',
  'routesTrips.trip.driver': 'Водитель',
  'routesTrips.trip.workType': 'Тип работ',
  'routesTrips.trip.trailer': 'Прицеп',
  'routesTrips.trip.point': 'Точка',
  'routesTrips.trip.arrival': 'Прибытие',
  'routesTrips.trip.arrivalDev': 'Отклонение прибытия',
  'routesTrips.trip.departure': 'Отправление',
  'routesTrips.trip.departureDev': 'Отклонение отправления',
  'routesTrips.trip.clearDays': 'Очистить доп. сутки',
  'routesTrips.trip.addDays': 'Добавить сутки к последующим точкам',
  'routesTrips.trip.removeDays': 'Удалить сутки к последующим точкам',
  'routesTrips.trip.pointsValidation': 'Выберите хотя бы две точки',
  'routesTrips.trip.addFromRoutes': 'Добавить точки маршрута',
  'routesTrips.trip.addFromMap': 'Добавить точку с карты',
  'routesTrips.trip.addFromGeozones': 'Добавить геозоны',
  'routesTrips.trip.editGeozone.title': 'Редактирование геозоны',
  'routesTrips.trip.editGeozone.figure': 'Фигура',
  'routesTrips.trip.editGeozone.name': 'Название',
  'routesTrips.trip.createGeozone.title': 'Добавить точку с карты',
  'routesTrips.trip.createGeozone.figure': 'Фигура',
  'routesTrips.trip.createGeozone.name': 'Название',
  'routesTrips.trip.addFromRoutes.title': 'ДОБАВИТЬ ТОЧКИ МАРШРУТА',
  'routesTrips.trip.addFromGeozones.title': 'Добавить геозоны',
  'routesTrips.route.create': 'Создать маршрут',
  'routesTrips.route.name.placeholder': 'Имя маршрута',
  'routesTrips.route.edit': 'Редактировать маршрут',
  'routesTrips.route.addFromMap': 'Добавить точку с карты',
  'routesTrips.route.addFromGeozones': 'Добавить геозоны',
  'routesTrips.route.point': 'Точка',
  'routesTrips.route.createGeozone.title': 'Добавить точку с карты',
  'routesTrips.route.createGeozone.figure': 'Фигура',
  'routesTrips.route.createGeozone.name': 'Название',
  'routesTrips.route.createGeozone.width': 'Ширина',
  'routesTrips.route.addFromGeozones.title': 'Добавить геозоны',
  'routesTrips.trip.work_type_one': 'Тип работ 1',
  'routesTrips.trip.work_type_two': 'Тип работ 2',
  'routesTrips.trip.work_type_three': 'Тип работ 3',

  // Справочник
  'mailing.title': 'Справочник рассылок',
  'mailing.name': 'Название',
  'mailing.date': 'Дата',
  'mailing.isActive': 'Активность',
  'mailing.edit': 'Изменить',
  'mailing.create': 'Создать рассылку',
  'mailing.enterName': 'Введите имя!',
  'mailing.template': 'Выбор шаблона',
  'mailing.vehicle': 'Выбор транспортного средства',
  'mailing.chooseObject ': 'Выберите объект!',
  'mailing.chooseGroup': 'Выберите группу!',
  'mailing.object': 'Объект',
  'mailing.objectGroup': 'Группа объектов',
  'mailing.periodicity': 'Периодичность',
  'mailing.periodicity.warning': 'Введите периодичность!',
  'mailing.daysweek': 'Дни недели',
  'mailing.daysweek.warning': 'Введите дни недели!',
  'mailing.adresses': 'Адресаты',
  'mailing.adresses.warning': 'Введите адресатов рассылки!',
  'mailing.subject': 'Тема сообщения',
  'mailing.subject.warning': 'Введите сообщение!',
  'mailing.timeDateSent': 'Дата/время последней отправки',
  'mailing.mailing': 'Рассылка',

  // История изменений

  'history.title': 'История изменений',
  'history.date': 'Дата/Время',
  'history.author': 'Автор',
  'history.operation': 'Операция',
  'history.event': 'Событие',
  'history.edit': 'Редактирование',
  'history.create': 'Создание',
  'history.delete': 'Удаление',

  // ADMIN
  'admin-panel': 'Админ. панель',
  'admin-panel.timezone': 'Часовой пояс',
  'admin-panel.title': 'Админ-панель',
  'admin-panel.company': 'Компания',
  'admin-panel.is-company': 'Введите компанию!',
  'admin-panel.show': 'Показать',
  'admin-panel.module': 'Модуль',
  'admin-panel.period': 'Период',
  'admin-panel.finance': 'Финансовый отчет',
  'admin-panel.download-finance': 'Скачать отчет',

  // Terminal
  'edit-terminal': 'Редактирование терминала',
  'add-terminal': 'Добавление терминалов',
  'send-terminal': 'Отправка',
  'status-terminal': 'Статус',
  'command-terminal': 'Команда',
  'text-command-terminal': 'Текст команды',
  'response-terminal': 'Ответ',

  // retranslators
  'edit-retranslators': 'Редактировать ретранслятор ',
  'add-retranslators': 'Добавить ретранслятор',

  // navigation

  'nav.users': 'Пользователи',
  'nav.objects': 'Объекты',
  'nav.geozones': 'Геозоны',
  'nav.notifications': 'Уведомления',
  'nav.trips': 'Рейсы',
  'nav.mailing': 'Рассылка',
  'nav.reports': 'Отчеты',
  'nav.monitoring': 'Мониторинг',
  'nav.admin': 'Админ-панель',

  // MEASURE

  'measure.title': 'Измерение расстояний и площади',
  'measure.lastPoint': 'Последняя точка',
  'measure.distance': 'Расстояние',
  'measure.region': 'Область',
  'measure.cancel': 'Отменить',
  'measure.finish': 'Закончить измерения',
  'measure.description': 'Вы можете изменить измерение путем перемещения точки',
  'measure.line': 'Линия',
  'measure.polygon': 'Полигон',

  // Formula
  'formula.tooltip':
    'В формуле можно использовать арифметически операции +,-,/,*, скобки )(,\n' +
    'любые цифры',

  // CompanySETTINGS
  'company.name': 'Имя',
  'company.passwordLabel': 'Режим повышенной безопасности',
  'company.passwordLabelText1':
    'Для повышения безопасности, при активации этого режима новым пользователям, потребуется создавать надежные пароли. Такой пароль должен содержать не менее 15 символов, включать буквы верхнего и нижнего регистра, цифры и специальные символы.',
  'company.passwordLabelText2':
    'При смене пароля пользователю, необходимо войти в систему с использованием пароля от администратора, а затем установить собственный пароль для учетной записи. Таким образом, администратору не будет известен пароль от аккаунта пользователя.',
  'company.timeZone': 'Часовой пояс',
  'company.integrator': 'Дилер',
  'company.exitTime': 'Выход через n минут бездействия',
  'company.tooltip': '0 или пустое поле - не будет выхода по бездействию',

  // 500 Page

  'mainPage.title': 'Добро пожаловать в SKIF!',
  'mainPage.sorry':
    ' К сожалению ваша ссылка уже не работает. Попробуйте повторить операцию.',
  'mainPage.back': 'На главную!',

  // Map Layers
  'map.style': 'Стиль карты',
  'map.yandex': 'Яндекс Карты',
  'map.googleSatelite': 'Google Sattelite',
  'map.googleRoadMap': 'Google Roadmap',
  'map.googleTerrain': 'Google Terrain',
  'map.googleHybrid': 'Google Hybrid',
  'map.osm': 'OSM',
  'map.here': 'Here',
  'map.bing': 'Bing',
  'map.bingSatellite': 'Bing Satellite',

  // Hide from  Map

  hide: 'СТЕРЕТЬ',
  'hide.title': 'Видимость на карте',
  'hide.objects': 'Объекты',
  'hide.objects.text': 'Скрыть объекты на карте',
  'hide.tracks': 'Треки',
  'hide.tracks.text': 'Скрыть треки на карте',
  'hide.tracks.signatures': 'Скрыть подписи на треках',
  'hide.tracks.direction_of_movement': 'Направление движения',
  'hide.tracks.group': 'Группировать, если расстояние менее',
  'hide.notifications': 'Настройки уведомлений',
  'hide.notifications.text': 'Скрыть уведомления на карте',
  'hide.geozones': 'Настройки работы с геозонами',
  'hide.geozones.text': 'Скрыть геозоны на карте',
  'hide.trips': 'Маршруты и Рейсы ',
  'hide.trips.text': 'Скрыть маршруты и рейсы',
  'hide.all': 'Все элементы',
  'hide.all.text': 'Скрыть все элементы',

  'hide.events': 'События',
  'hide.events.text': 'Скрыть события на карте',

  // billing
  'tariff.information': 'ИНФОРМАЦИЯ ПО ТАРИФУ',
  'tariff.current': 'Ваш текущий тариф:',
  'tariff.price': 'Стоимость тарифа:',
  'tariff.payement-date': 'Оплата:',
  'tariff.balance': 'На счету:',
  'tariff.units': 'объектов',
  'tariff.notselected': 'Не выбран',
  'tariff.currency-month': 'валюта/мес',
  'tariff.currency': 'вал',
  'tariff.company-blocked': 'компания заблокирована',
  'tariff.trial': 'Ознакомительный период:',
  'tariff.paid': 'Оплаченный период',
  'tariff.rouble': 'руб',
  'tariff.kz': 'KZT',
  'tariff.one_day': 'день',
  'tariff.less_than_four_days': 'дня',
  'tariff.days': 'дней',

  'tariff.remaining': 'Осталось:',
  'tariff.next-payment': ' Следующее списание:',
  'tariff.payment-history': 'ИСТОРИЯ СПИСАНИЙ И ОПЛАТ',
  'tariff.choose-pay': ' ВЫБРАТЬ И ОПЛАТИТЬ ТАРИФ  ',
  'tariff.pay': ' ОПЛАТИТЬ ТАРИФ ',
  'tariff.amounttopay': 'Сумма',

  'tariff.payments-history': 'История платежей',
  'tariff.date-time': 'Дата/Время',
  'tariff.operation': 'Операция ',
  'tariff.amount': 'Сумма на счёте',
  'tariff.currency-type': 'валюты',
  'tariff.objects': 'Объекты',

  'tariff.choose': 'Выбор тарифного плана',
  'tariff.txt-one':
    'Использование системы мониторинга SKIF PRO является платным.',
  'tariff.txt-two': 'Вы можете выбрать Тариф необходимый для Вашей компании.',
  'tariff.txt-three': 'Первые 7 дней являются бесплатными.',
  'tariff.choose-units': ' Выберите нужное количество объектов:',
  'tariff.info-one': 'Стоимость 1 объекта в системе SKIF PRO составляет',
  'tariff.info-two--part-one': 'на',
  'tariff.info-two--part-two': 'календарных дней.',
  'tariff.tariffprice': 'Стоимость тарифа:',
  'tariff.payableto': 'к оплате:',
  'tariff.txt-four': 'Если Вы захотите уменьшить тариф, Вам будет',
  'tariff.txt-five': 'необходимо обратиться в поддержку.',
  'tariff.txt-six': ' Нажимая кнопку “Согласен” вы соглашаетесь с',
  'tariff.txt-seven': 'тарифом, правилами оплаты и',
  'tariff.offers': 'оферты.',
  'tariff.accept': 'Согласен',
  'tariff.blocked-info':
    'На счету Компании недостаточно средств. Доступ к компании заблокирован.',
  copy: 'IMEI скопирован!',
  // tariff ADMINKA

  'admintariff.company': 'Компания',
  'admintariff.tariff': 'Тариф',
  'admintariff.date': 'Дата следующего платежа',
  'admintariff.for': 'за ',
  'admintariff.objects': 'объектов каждые',
  'admintariff.days': 'дней',

  'admintariff.changetitle': 'Изменение тарифа',
  'admintariff.cancel': 'Отменить',
  'admintariff.refill': 'Пополнить',
  'admintariff.accept': 'Подтвердить',
  'admintariff.object': 'объекта',
  'admintariff.objectsnumber': 'Количество объектов',
  'admintariff.price': 'Стоимость одного объекта',
  'admintariff.period': 'Период оплаты',
  'admintariff.paymentdate': 'Дата платежа',

  // tariff history
  'admintariff.newtariff': 'Новый тариф',
  'admintariff.oldtariff': 'Старый тариф',
  'admintariff.historyobjects': 'объектов',

  // Другое
  'company.retransmit': 'Ретранслировать в ЕФИС',
  'company.date-format': 'Формат даты',
  'company.time-format': 'Формат времени',
  'company.last_date_widget': 'Время последней даты в виджете',
  'company.last_date_widget_note':
    'Укажите какой формат времени будет применяться при выборе последней даты в интервале дат (в виджете выбора дат).',
  'company.download_offer': 'Скачать оферту',
  'company.accounting_type': 'Тип учета:',
  'company.by_number_objects': 'По числу объектов',
  'company.by_number_objects_sent_data': 'По числу объектов присылавших данные',
  'company.max_number_objects': 'Макс. кол-во объектов:',
  'company.cost_one_object': 'Стоимость 1 объекта:',
  'company.start_current_period': 'Начало текущего периода:',
  'company.payment_period': 'Период оплаты:',
  'company.previous_period_amount': 'Сумма за прошлый период',
  'company.minimum_payment_amount': 'Минимальный размер платежа 1 руб.',
  'company.use_card_for_autopayments': 'Использовать карту для автоплатежа',
  'company.select_payment_system': 'Выберите платёжную систему:',
  'company.temporarily_unavailable': 'Временно не доступно',
  'company.choose_payment_system': 'Выберите платёжную систему',
  'company.autopayment_unavailable_with_mir':
    'Автоплатёж временно недоступен с картами МИР',
  'company.autopayment': 'Автоплатёж',
  'company.autopayment_on': 'Включить автоплатёж',
  'company.no': 'Нет',
  'company.change_card_autopayment': 'Смена карты для автоплатежа',
  'company.change_card_cost_note':
    'Для изменения карты автоплатежа, нам потребуется списать у вас 1 рубль. При оплате укажите реквизиты новой карты.',
  'company.cancel': 'Отменить',
  'company.continue': 'Продолжить',
  'company.card_add_for_autopayments': 'Добавление карты для автоплатежа',
  'company.add_card_cost_note':
    'Для добавления карты автоплатежа, нам потребуется списать у вас . При оплате укажите реквизиты карты.',
  'company.visa_or_mastercard': 'Visa или Mastercard',
  company_system_mir: 'Система МИР',

  search: 'Поиск',
  error: 'Ошибка',
  attention: 'Внимание',
  notification: 'Уведомление',
  change: 'Изменение',
  success: 'Успех',
  loading: 'Загрузка...',
  close: 'Закрыть',
  send: 'Отправить',
  phone: 'Телефон',
  name: 'Имя',
  type: 'Тип',
  add: 'Добавить',
  cancel: 'Отмена',
  value: 'Значение',
  role: 'Роль',
  email: 'Почта',
  time: 'Время',
  author: 'Автор',
  events: 'Событие',
  action: 'Действие',
  code: 'Код',
  details: 'Дополнительно',
  description: 'Описание',
  create: 'Создать',
  change: 'Изменить',
  yes: 'Да',
  no: 'Нет',
  'setting-company': 'Настройки компании',
  'setting-user': 'Настройки пользователя',
  select: 'Выбрать',
  select_all: 'Выбрать все',
  companies: 'Компании',
  blocking: 'Блокировка',
  to: 'На',
  in: 'В',
  noMatch: 'Совпадений не найдено',
  phone_confirmation: 'Подтверждение телефона',
  email_confirmation: 'Подтверждение e-mail',
  cancel: 'Отменить',
  send: 'Отправить',
  outdated_version: 'Версия устарела',
  outdated_version_message: 'Обновите страницу',
  one_element_deleted: '1 элемент  удален',
  already_deleted_elements: ' элемента были удалены',
  save: 'Сохранить',
  excel: 'Excel',
  word: 'Word',
  pdf: 'PDF',
  no_data: 'Нет данных',
  confirm: 'Подтвердить',

  // add-company admin panel
  addCompany: 'Добавить компанию',
  // NEW UNITS
  new_units: {
    tabs: {
      objects: 'Объекты',
      groups: 'Группы',
      trailers: 'Прицепы',
      links: 'Ссылки доступа'
    },
    titles: {
      objects: 'Справочник объектов',
      groups: 'Справочник групп',
      trailers: 'Справочник прицепов',
      links: 'Справочник ссылок доступа',
      create: 'Создание объекта',
      edit: 'Редактирование объекта'
    },
    add: {
      objects: 'Добавить объект',
      groups: 'Добавить группу',
      trailers: 'Добавить прицеп',
      links: 'Создать ссылку',
      couplings: 'Добавить',
      shifts: 'Добавить',
      add_field: 'Добавить поле',
      service: 'Добавить ТО',
      parameters: 'Добавить параметры'
    },
    trailers: {
      title: 'Прицепы:',
      additionalFields: 'Дополнительные поля:',
      save: 'Сохранить',
      search: 'Поиск по прицепам',
      fields: {
        name: 'Название:',
        code: 'Код:',
        length: 'Ширина:',
        description: 'Описание:',
        value: 'Значение:'
      },
      no_trailers: 'Не создан ни один прицеп',
      success: {
        approved: 'Прицеп был успешно создан!',
        updated: 'Прицеп был успешно обновлен!',
        delete: 'Прицепы были успешно удалены!'
      },
      errors: {
        name: 'Введите имя!',
        approved: 'Пожалуйста сохраните текущий прицеп, чтобы создать новый!',
        already_deleted:
          'Вы уже удалили этот прицеп, если вы хотите вернуть нажмите на кнопку отмены ниже.',
        validation:
          'Заполните все обязательные поля, чтобы перейти к другому прицепу!'
      },
      search_options: {
        name: 'Название',
        code: 'Код'
      },
      table_columns: {
        name: 'Имя',
        width: 'Ширина',
        code: 'Код'
      }
    },
    links: {
      create: 'Создать ссылку доступа',
      edit: 'Редактировать ссылку',
      object: 'Объект',
      empty: 'Не выбран',
      accessStart: 'Дата начала доступа',
      accessEnd: ' Дата завершения доступа',
      description: 'Описание',
      placeholder: 'Длинное описание ....',
      link: 'Ссылка',
      search: 'Поиск по ссылкам',
      no_links: 'Список ссылок доступа пуст',
      status: {
        planned: 'Запланирована',
        active_off: 'Активна (выкл.)',
        active_on: 'Активна (вкл.)',
        inactive: 'Не активна'
      },
      success: {
        create: 'Ссылка доступа успешна создана!',
        edit: 'Ссылка доступа успешна отредактирована!',
        delete: 'Ссылки доступа успешно удалены!',
        create_imei: 'Imei код успешно создан!',
        edit_imei: 'Imei код успешно отредактирован!'
      },
      errors: {
        unit: 'Выберите объект!',
        access_start: 'Выберите дату начала!',
        access_end: 'Выберите дату завершения!',
        create: 'В процессе создания ссылки доступа возникла ошибка!',
        edit: 'В процессе редактирования ссылки доступа возникла ошибка!',
        delete: 'В процессе удаления ссылок доступа возникла ошибка!'
      },
      search_options: {
        object: 'Объект',
        status: 'Статус'
      },
      table_columns: {
        object: 'Объект',
        active_from: 'Активна с',
        active_to: 'Активна по',
        status: 'Статус'
      }
    },
    groups: {
      no_groups: 'Не создана ни одной группы объектов',
      search: 'Поиск по группам',
      errors: {
        name: 'Введите имя!',
        create: 'Упс... Возникла проблема при создании',
        edit: 'Упс... Возникла проблема при редактировании',
        validation: 'Заполните обязательные поля!',
        delete: 'В процессе удаления групп возникла ошибка!'
      },
      success: {
        create: 'Группа успешна создана!',
        edit: 'Группа успешна отредактирована!',
        delete: 'Группы успешно удалены!'
      },
      search_options: {
        name: 'Имя',
        object: 'Объект'
      },
      table_columns: {
        group_name: 'Название группы',
        users_count: 'Пользователи',
        objects: 'Объектов'
      }
    },
    objects: {
      object: 'Объект:',
      create: 'Создание объекта',
      edit: 'Редактирование объекта',
      saved: 'Сохраненные',
      approved: 'ПОДТВЕРЖДЕННЫЕ',
      fill: 'Заполнить',
      no_objects: 'В систему не добавлен ни один объект',
      search: 'Поиск по объектам',
      success: {
        delete: 'Объекты успешно удалены!'
      },
      errors: {
        delete: 'В процессе удаления объектов возникла ошибка!'
      },
      search_options: {
        name: 'Имя',
        imei: 'IMEI',
        phonenumber: 'Телефоны',
        custom_fields: 'Доп. поля',
        sensors_name: 'Имена датчиков',
        sensors_type: 'Типы датчиков',
        sensors_param: 'Параметр датчиков',
        events: 'События'
      },
      table_columns: {
        name: 'Название',
        protocol: 'Протокол',
        imei: 'IMEI',
        sim_1_2: 'Сим 1,2',
        sensors: 'Датчики'
      },
      tabs: {
        basic: 'Основные',
        sensors: 'Датчики',
        couplings: 'Cцепки',
        shifts: 'Смены',
        service: 'ТО',
        parameters: 'Тех. параметры',
        blocked_text:
          'Смены, ТО и Сцепки будут доступны после сохранения объекта'
      },
      fields: {
        basic: {
          name: 'Название объекта:',
          type: 'Тип объекта:',
          gadget: 'Устройство (терминал):',
          protocol: 'Протокол обмена данными:',
          imei: 'IMEI:',
          server: 'Сервер получения данных:',
          port: 'Порт:',
          sim_1: 'Sim1:',
          sim_2: 'Sim2:',
          note: 'Вы можете не указывать название устройства. Для корректной работы достаточно указать протокол.',
          groups: 'Группы',
          errors: {
            name: 'Введите имя!',
            type: 'Выберите тип объекта!',
            terminal_type: 'Выберите тип устройства!',
            imei: 'Выберите уникальный ID!',
            phonenumber: 'Введите телефон!',
            custom_imei: 'Имей заблокирован!'
          }
        },
        sensors: {
          title: 'Датчики:',
          fields: {
            name: 'Название:',
            new_sensor: 'Новый датчик',
            sensor_type: 'Тип датчика:',
            hide_in_report: 'Скрывать в отчетах',
            hidden_in_report: 'Скрыт в отчетах',
            hide_in_monitoring: 'Скрывать в мониторинге',
            hidden_in_monitoring: 'Скрыт в мониторинге',
            hidden_in_both: 'Скрыт в отчетах и в мониторинге',
            formula: 'Формула',
            expression: 'Выражение',
            help_info:
              'При помощи клика мыши выберите формат работы с параметрами датчика.',
            multiple_deletion_mode: 'Вы находитесь в режиме удаления датчиков',
            hint: {
              title: 'Подсказка',
              hide: 'Скрыть',
              show: 'Показать'
            },
            parameters: {
              param_title_1: 'Параметр 1',
              param_title_2: 'Параметр 2',
              param_title_s_1: 'S1',
              param_title_s_2: 'S2',
              param_option_1: 'Параметр 1:',
              param_option_2: 'Параметр 2:',
              lower_bound_x: 'Нижняя граница X:',
              upper_bound_x: 'Верхняя граница Х:',
              smoothing_coefficient: 'Коэффициент сглаживания',
              smoothing_coefficient_range: '(от 0 до 999)',
              type_filter: 'Тип фильтрации',
              download_from_csv: 'Загрузить X и Y из CSV-файла',
              download_as_csv: 'Скачать X,Y как CSV-файл',
              related_tables: 'Связанные таблицы',
              show_graph: 'Показать график',
              hide_graph: 'Скрыть график',
              x: 'X',
              y: 'Y',
              a: 'А',
              b: 'Б',
              lower_bound_short: '(ниж. гран.)',
              sensor_calibration: 'Тарировка датчика',
              exist: 'Есть',
              helpers: {
                param: 'Параметр',
                param_minus_1: 'Параметр[-1]',
                sensor_name: 'Название д-ка',
                param1: 'param1',
                param1_minus_1: 'param1[-1]',
                param2: 'param2',
                param2_minus_1: 'param2[-1]',
                degree: 'степень'
              },
              helpers_description: {
                param: 'обращение к значениям любого параметра из сырых данных',
                param_minus_1:
                  'обращение к предыдущему значению параметра из сырых данных',
                param1:
                  'обращение к первому параметру(после линейнего преобразование) текущего датчика',
                param1_minus_1:
                  'обращение к предыдущему значению первого параметра(после линейнего преобразование) текущего датчика',
                param2:
                  'обращение ко второму параметру(после линейнего преобразование) текущего датчика',
                param2_minus_1:
                  'обращение к предыдущему значению второго параметра(после линейнего преобразование) текущего датчика',
                sensor_name: 'обращение к значению других датчиков',
                byte_control:
                  'побитовый контроль параметра (например param101:3 - третий бит параметра param101)'
              },
              clear: 'Очистить таблицу'
            },
            technical_params: {
              title: 'Технические параметры',
              add_params: 'Добавить тех. параметр',
              show_annotation: 'Отображении параметра в аннотации объекта.',
              errors: {
                wrong_value: 'Неверное значение!'
              }
            },
            connected_sensors: {
              title: 'Связка с датчиками',
              connected: 'Связан',
              cancel: 'Отменить',
              bundle_with_ignition: 'Связать с датчиком зажигания'
            },
            errors: {
              name: 'Введите название датчика!',
              type: 'Выберите тип датчика!',
              formula: 'Выберите формулу!',
              smoothing: 'Введите правильное значение!',
              parameter_1: 'Выберите параметр 1!',
              parameter_2: 'Выберите параметр 2!',
              validation:
                'Исправьте ошибку в текущем датчике, для того чтобы перейти к другому!',
              save_validation:
                'Исправьте ошибку в текущем датчике, для того чтобы сохранить!',
              duplicate_x: 'Значения X не могут повторяться!',
              invalid_value:
                'Вы не можете добавить новую строку, так как в таблице есть невалидное значение.',
              duplicate_lower_bound:
                'Значения нижних границ не могут повторяться!'
            }
          },
          no_sensors: 'В объекте не создан ни один датчик',
          value_to_sensor: 'Преобразовывать значения в текст:',
          value_to_sensor_text:
            'Превратите числовое значение датчика в текстовое. Для этого укажите значения и текстовые ключи для них. Используйте линейное преобразование датчика для работы с диапазонами значений.',
          value: 'Значение',
          output_text: 'Выводимый текст'
        },
        couplings: {
          title: 'Планирование:',
          date_filter: 'Фильтр по дате:',
          start: 'С',
          end: 'По',
          trailer: 'Прицеп',
          start_date: 'Дата начала:',
          end_date: 'Датат завершения:',
          note: 'Интервал использования прицепа пересекается с другим интервалом.',
          work_type: 'Тип работ:',
          min_speed: 'Мин. скорость (км/ч):',
          max_speed: 'Макс. скорость (км/ч):',
          sensor_mask: 'Маска названия датчиков:',
          no_couplings: 'Работа с прицепами не запланирована',
          success: {
            approved: 'Сцепка была успешна подтверждена!',
            updated: 'Сцепка была успешна обновлена'
          },
          errors: {
            trailer: 'Выберите прицеп!',
            couplig_start: 'Выберите дату начала!',
            couplig_end: 'Выберите дату завершения!',
            work_type: 'Выберите тип работы!',
            already_deleted:
              'Вы уже удалили эту сцепку, если вы хотите вернуть нажмите на кнопку отмены ниже.',
            approved:
              'Пожалуйста подтвердите текущий прицеп, чтобы создать новую сцепку!'
          },
          table_columns: {
            from: 'С',
            to: 'По',
            trailer: 'Прицеп'
          }
        },
        shifts: {
          title: 'Смены:',
          start: 'С',
          end: 'По',
          driver: 'Водитель',
          shift_start: 'Начало смены:',
          shift_end: 'Конец смены:',
          note: 'Интервал использования cмены пересекается с другим интервалом.',
          hide_old_shifts: 'Скрыть прошедшие смены',
          additionalFields: 'Дополнительные поля:',
          name: 'Название:',
          value: 'Значение:',
          no_shifts: 'Список смен пуст',
          success: {
            approved: 'Смена была успешна подтверждена!',
            updated: 'Смена была успешна обновлена!'
          },
          errors: {
            driver: 'Выберите водителя!',
            shift_start: 'Выберите начало смены!',
            shift_end: 'Выберите конец смены!',
            already_deleted:
              'Вы уже удалили эту смену, если вы хотите вернуть нажмите на кнопку отмены ниже.',
            approved:
              'Пожалуйста подтвердите текущую смену, чтоб создать новую!'
          },
          table_columns: {
            from: 'С',
            to: 'По',
            driver: 'Водитель'
          }
        },
        service: {
          title: 'ТО:',
          service: 'Сервис',
          mileage: 'Пробег/ М.ч.',
          days: 'Дни',
          no_service: 'Список ТО пуст',
          fields: {
            service: 'Сервис:',
            description: 'Описание:',
            last_serviced: 'Дата последнего ТО:',
            mileage_period: 'Активировать интервал по пробегу',
            current_mileage: 'Тек. пробег (км):',
            interval: 'Интервал (км):',
            days_period: 'Активировать интервал по дням',
            interval_days: 'Интервал в днях:',
            ativate_engine_hours: 'Активировать интервал по моточасам',
            last_times: 'Последнее раз:',
            engine_hours: 'Интервал (M/ч):'
          },
          errors: {
            name: 'Введите название!',
            description: 'Введите описание!',
            lastDays: 'Выберите дату последнего ТО!',
            lastKm: 'Введите текущий пробег (км)!',
            intervalKm: 'Введите текущий пробег (км)!',
            intervalDays: 'Введите интервал (дни)!',
            lastMotohours: 'Введите последний (раз)!',
            intervalMotohours: 'Введите интервал (моточасы)!',
            checkbox: 'Выберите 1 из типов интервала!',
            already_deleted:
              'Вы уже удалили эту смену, если вы хотите вернуть нажмите на кнопку отмены ниже.',
            validation: 'Пожалуйста заполните все обязательные поля!'
          },
          table_columns: {
            service: 'Сервис',
            mileage: 'Пробег/ М.ч.',
            days: 'Дни'
          }
        },
        parameters: {
          tabs: {
            trips: 'Поездки',
            fuel: 'Топливо',
            engine_hours: 'Моточасы',
            video: 'Видео',
            additional: 'Дополнительно',
            parameters: 'Ваши параметры',
            filter_data: 'Фильтрация данных'
          },
          video: {
            hint: 'Введите номера каналов через запятую для отображения видео.'
          },
          switch: {
            hint: 'Показывать в мониторинге',
            show_annotation: 'Отображать в аннотации',
            not_show_annotation: 'Не отображать в аннотации'
          },
          errors: {
            wrong_value: 'Неверное значение!',
            correct_values:
              'Заполните корректными значениями чтобы перейти в раздел!'
          },
          name: 'Название:',
          value: 'Значение:',
          no_parameters: 'Вы не добавили ни одного дополнительного параметра'
        }
      }
    },
    apply_changes: 'Применить изменения',
    cancel: 'Отменить изменения',
    approve: 'Подтвердить',
    save_modal: {
      message: 'Объект и данные успешно сохранены',
      continue: 'Продолжить',
      close: 'Закрыть форму'
    },
    recover: {
      button: 'Отменить удаление',
      removed: {
        sensors: 'Удалено датчиков:',
        couplings: 'Удалено сцепок:',
        shifts: 'Удалено смен:',
        service: 'Удалено ТО:',
        parameters: 'Удалено параметров:',
        trailers: 'Удалено прицепов:'
      }
    },
    footer: {
      cancel: 'Отменить',
      save: 'Сохранить'
    },
    placeholder: {
      select: 'Выберите'
    },
    delete: 'удалить',
    create: 'Создать',
    edit: 'Редактировать',
    unspecified: 'не указан',
    not_selected: 'не выбран'
  },

  // добавление параметров из отчета
  add_parametrs: {
    add_parametrs: 'Добавление параметров',
    no_accepted: 'Не принято',
    accepted: 'Принято',
    protocol: 'Протокол',
    parametrs_available: 'Доступно параметров',
    support:
      'Обратитесь в поддержку для добавления следующих параметров (так как их нельзя добавить)',
    parametrs_time: 'Успешно добавлены следующие параметры:',
    send_parametrs: 'Отправить параметры на добавление',
    good: 'Хорошо'
  },
  // попап tools
  tools: {
    admin_panel_v2: 'Админ. панель 2.0',
    admin_panel_beta: 'βeta тест'
  },
  // геозоны
  geozones_lang: {
    geozones_enter: 'Вход в геозоны',
    geozones_exit: 'Выход из геозон'
  },
  // попап быстрый трек
  fast_track: {
    close: 'Скрыть быстрый трек'
  },

  // формула
  formula: {
    add: 'Добавить',
    extra_fields_sensors: 'Добавить доп. поля и датчики',
    errors: {
      name: 'Введите название',
      formula: 'Введите формулу'
    }
  },
  // Подсказки
  notification_alert: {
    success: 'Событие успешно сохранено',
    error_points: 'Точки для трека отсутствуют!'
  },
  // Иконки в мониторинге
  content: {
    object_stay: 'Объект стоит',
    object_move: 'Объект в движении',
    object_last_state_move: 'Последнее состояние: объект в движении',
    object_last_state_stay: 'Последнее состояние: объект стоит'
  },
  // Помощник
  helper: {
    description_text:
      'В демо режиме вам не доступна панель администратора и возможность создавать/редактировать сущности (объекты, геозоны и тд.).',
    objects:
      'Создавайте и редактируйте объекты. Добавляйте датчики, ТО, смены, прицепы и тд.',
    video: 'Видео: “Создание объекта”',
    content_text: 'Для работы с датчиками:',
    content_text_list: {
      one: '1. Откройте модуль “Объекты”',
      two: '2. Выберите объект кликом по названию из списка',
      three: '3. В верхнем левом углу выберите “Датчики”.',
      four: 'Вы увидите список датчиков в объекте. Если список пуст, вы можете сами добавить датчики, нажав на кнопку',
      five: 'В датчике вы сможете выбрать параметры и настроить их тарировки для этого нажмите на',
      six: 'По мимо стандартных формул, для параметров вы можете создавать собственные выражения с возможностью обращаться к сырым данным датчика.',
      seven: 'Для этого в поле “Формула” выберите последний пункт "Выражение".'
    },
    video_add_sensor: 'Видео: "Добавление датчиков"',
    reports: {
      text: 'Сформируйте отчет по объекту или группе объектов. Выберите один из 25 отчетов или создайте свой при помощи редактора шаблона отчетов.',
      video_base_reports: 'Видео: “Базовые отчеты”',
      video_create_template: 'Видео: “Создание шаблонов”'
    },
    monitoring: {
      text_one: 'Отслеживайте актуальное состояние ТС.',
      text_two:
        'Чтобы вы быстро понимали как давно пришли данные по объекту мы добавили цветовую индикацию:',
      colors: {
        one: '— Данные пришли мене 15 минут назад',
        two: '— Данные пришли мене 30 минут назад',
        three: '— Данные пришли более 30 минут назад'
      },
      statuses: {
        text: 'Объект может иметь один из 4 статусов:',
        one: '— Объект стоит',
        two: '— Объект стоит с включённым зажиганием',
        three: '— Объект находится в движении',
        four: '— Объект находится в движении'
      },
      text_three:
        'Так же в мониторинге вам доступны быстрые действия связанные с объектом. Например вы можете построить “Быстрый трек” (трек за день, неделю или месяц).'
    },
    admin: {
      text: 'Для доступа в панель администратора вам нужно зарегистрироваться как интегратор или запросить демонстрацию платформы нашим специалистом.'
    },
    footer: {
      account: 'Зарегистрируйте аккаунт',
      get_free: '(Получите 30 дней бесплатного пробного периода)'
    },
    list: {
      one: {
        title: 'Объекты',
        content:
          'Создавайте и редактируйте объекты. Добавляйте датчики, ТО, смены, прицепы и тд.',
        link: 'Видео: "Создание объекта"'
      },
      two: {
        title: 'Датчики',
        content:
          'Для работы с датчиками: 1. Откройте модуль "Объекты". 2. Выберите объект кликом по названию из списка. 3. В верхнем левом углу выберите "Датчики".'
      },
      three: {
        title: 'Отчеты'
      },
      four: {
        title: 'Мониторинг'
      },
      five: {
        title: 'Панель администратора'
      }
    }
  },
  // кабинет дилера
  lkDiler: {
    create_company: 'Создание компаний',
    title: 'Личный кабинет Дилера',
    change_tariff: 'Изменения тарифа',
    create_terminal: 'Заведение терминалов',
    template_command: 'Шаблоны комманд',
    admin_panel: {
      step_1:
        'Для создания компании в Админ-панели необходимо нажать на "Добавить компанию" в модуле "Компании":',
      step_2:
        'В открывшейся форме создания компании необходимо заполнить имя компании,выбрать часовой пояс и при необходимости добавить 1 или несколько терминалов и нажать "Сохранить". Оставим выбранным пункт "Переключится в выбранную компанию" Вы автоматически попадете в созданную компанию.',
      step_3:
        'Оставим выбранным пункт "Переключится в выбранную компанию" Вы автоматически попадете в созданную компанию.'
    },
    register_link: {
      step_1:
        'Для отправки ссылки-приглашения необходимо в списке своих компаний нажать  “Пригласить”',
      step_2:
        'В открывшемся поле необходимо ввести адрес электронной почты сотрудника Компании, которому придет приглашение, перейдя по ссылке в письме он попадет на страницу регистрации новой Компании. Сотрудник, которому придет ссылка станет Администратором созданной Компании'
    },
    change_step: {
      step_1:
        'По умолчанию в SKIF.PRO во вновь созданных компаниях используется тариф 250 рублей за объект и возможность клиента оплачивать услугу через процессинговый центр компании СКИФ ЭПП. Для того чтобы отключить процессинговый центр и использовать свою схему тарификации просто в Админ-панели установите Тариф для этой Компании 0 руб. за 0 объектов. Для доступа к тарифам выберите “Тарифы” в списке модулей Админ-панели',
      step_2:
        'Выберите из списка необходимую компанию и нажмите на кнопку “Показать”',
      step_3:
        'Кликните строку с тарифом, выберите количество объектов 0 и стоимость одного  объекта 0, нажмите подтвердить'
    },
    create_company_step: {
      title: 'Создание компаний',
      create: 'Создание компании в Админ-панели',
      register: 'Регистрация по ссылке-приглашению'
    },
    start_terminal: {
      create_terminal: 'Заведение терминалов',
      step_1:
        'Для добавления терминалов, необходимо перейти в модуль терминалы в Вашей Админ-панели  и нажать на "+"',
      step_2: 'Далее Вам необходимо:',
      step_3:
        '1. Выбрать компанию из списка Ваших компанию, в которой хотите разрешить использование терминалов.',
      step_4:
        '2. Ввести один или несколько imei терминалов, которые хотите добавить. Удобно сразу вставлять список imei разделенных новой строкой.',
      step_5:
        '3. Если терминалы подключаются с использованием пароля то можно его тут указать.',
      step_6: '4. Нажать на кнопку сохранить.',
      template: {
        title: 'Шаблоны команд для терминалов',
        step_1:
          'В личном кабинете Дилера, Вы можете создавать шаблоны команд для терминалов, для этого перейдите в Админ-панель и выберите модуль “Справочник команд”',
        step_2:
          'Нажмите на “+” для добавления команды, в открывшемся окне выберите тип терминала, выберите  или заполните название команды и введите саму команды в поле “Текст команды”, нажмите сохранить'
      }
    }
  },
  // кабинет компании
  work_company: {
    title: 'Рабочее место Компании',
    data: 'Данные от терминала',
    create_object: 'Создание объектов',
    short_list: {
      action_one:
        'Для  создания объектов Вам будет необходимо перейти в одну из Ваших компаний. Для этого нажмите на кнопку с именем пользователя и Компании, а затем выберите нужную компанию списке',
      action_two:
        'Для перехода в Админ-панель из любой Компании нажмите на кнопку с именем пользователя, а затем на пункт “Админ.панель”В выбранной компании перейдите в справочник объектов',
      action_three: 'Нажмите добавить',
      action_four:
        'В открывшейся форме заполните все поля и выберите изображение для объекта',
      action_five:
        'Примечание.После выбора нужного типа терминала, обратите внимание на подсказку указывающую сервер и порт куда необходимо настраивать терминалы для отправки данных на сервер',
      action_six:
        'Для добавления датчика в объект выберите из верхнего  выпадающего списка “Датчики”',
      action_seven:
        'Далее необходимо нажать на “+”, откроется окно добавления датчика, в котором необходимо заполнить все поля, выбрать параметры и формулы',
      action_eight:
        'При выборе параметров вы можете настроить их настраивать, для этого нажмите на шестеренку напротив нужного параметра, введите необходимые значения и нажмите сохранить',
      action_nine:
        'После добавления и сохранения датчика нажмите создать объект'
    },
    data_terminal: {
      step_1:
        'Необработанные “сырые” данные от терминалов Вы можете просматривать в отчете Журнал. Для получения отчета “Журнал” необходимо перейти в модуль Отчеты',
      step_2:
        'Затем, в открывшемся окне необходимо выбрать объект, период и нажать на журнал в списке отчетов',
      ste_3:
        'В отчете Журнал Вы также можете добавлять параметры и другие датчики. Примечание:Журнал недоступен для группы объектов'
    }
  },
  // измерение площади библиотека
  measure: {
    title: 'Измерение области',
    square: 'Площадь',
    perimeter: 'Периметр',
    clear: 'ОЧИСТИТЬ'
  },
  // Админ панель - добавление компании
  addCompanyTransalter: {
    description:
      'Добавление нескольких терминалов при переносе на новую строчку.',
    selectCompany: 'Переключиться в выбранную компанию',
    save: 'Сохранить',
    addUser: 'ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ',
    cancel: 'ОТМЕНИТЬ',
    password: 'Пароль:',
    repeatPassword: 'Подтвердите пароль:',
    error: {
      name: 'Заполните поле "Имя компании"',
      time: 'Заполните поле "Часовой пояс"',
      password: 'Пароли не совпадают',
      enterName: 'Введите имя пользователя',
      enterEmail: 'Введите корректный адрес электронной почты',
      enterPassword: 'Введите пароль',
      repeatPassword: 'Пароли должны совпадать'
    }
  },
  templateCommandTranslater: {
    add: 'Добавление шаблона команд',
    edit: 'Редактирование шаблона команд',
    typeTermial: 'Тип терминала',
    selectTypeTerminal: 'Выберите тип терминала',
    nameCommand: 'Название команды',
    selectCommand: 'ВЫБРАТЬ КОМАНДУ',
    enterNameCommand: 'Введите название команды',
    textCommand: 'Текст команды',
    enterTextCommand: 'Введите текст команды',
    parametrsCommand: 'Параметры команды'
  },
  companiesListTranslater: {
    copyObject: 'Копирование объекта',
    selectCompany: 'Выберите компанию в которую хотите',
    copiedObject: 'скопировать объект.',
    cancel: 'Отменить',
    save: 'Сохранить'
  },
  createPayment: {
    title: 'Создать платёж',
    company: 'Компания',
    amount: 'Сумма платежа',
    numberPayment: '№ платежа',
    createPayment: 'Создать платёж',
    upBalance: 'Данная операция увеличит баланс выбранной компании',
    error: {
      createPayment: 'Платеж создан',
      error: 'Ошибка',
      enterCompany: 'Заполните поле "Kомпания"',
      enterAmount: 'Заполните поле "Сумма платежа"'
    }
  },
  editTarifTranslater: {
    dateStart: 'Дата начала тарифа:',
    type: 'Тип учета',
    info: 'Сумма платежа за период оплаты формируется по формуле: Макс. количество объектов, помноженное на стоимость 1 объекта.',
    info_2:
      'Сумма платежа за период оплаты формируется по формуле: Кол-во объектов приславших данные за период, помноженное на стоимость 1 объекта',
    periodStart: 'Начало действия периода',
    max: 'Макс. количество объектов',
    amountPer1Object: 'Стоимость за 1 объект',
    nextPayment: 'Следующий платёж по тарифу:',
    amount: 'Сумма:',
    dateNextPayment:
      'Дата следующего платежа не может находится в прошлом. Измените дату начала платежа или увеличьте период оплаты.',
    changePeriod:
      'Вы изменили период оплаты, поэтому дата следующего платежа изменилась с',
    setStartTarif:
      'Вы установили начало действия тарифа на будущую дату, из-за этого компания будет в режиме пробного периода с',
    setBeforeDate:
      'Вы установили начало действия тарифа на прошедшую дату, из-за этого дата следующего платежа изменилась с'
  },
  feedbackTranslater: {
    addSomeTerminals:
      'Добавление нескольких терминалов при переносе на новую строчку.',
    selectCompany: 'Переключиться в выбранную компанию',
    save: 'Сохранить',
    error: {
      name: 'Заполните поле "Имя компании"',
      time: 'Заполните поле "Часовой пояс"'
    }
  },
  copyReportTemplateTranslater: {
    title: 'Копирование шаблона',
    selectCompany: 'Выберите компанию в которую хотите',
    copy: ' скопировать шаблон.',
    cancel: 'Отменить',
    save: 'Сохранить'
  },
  importKmlTranslater: {
    title: 'Импорт из KML',
    selectFileKML: 'Выберите файл KML с геозонами',
    inCompany: 'В компанию',
    selectFile: 'Выбрать файл',
    import: 'Импортировать',
    error: {
      fileSize: 'Ошибка: Файл должен быть менее 5 Мб.',
      formatKML: 'Формат файла должен быть kml'
    }
  },
  importWialonTranslater: {
    title: 'Импорт из Wialon',
    fileObject: 'Выберите файл с объектами из Wialon',
    inCompany: 'В компанию',
    selectFile: 'Выбрать файл',
    typeFiles: '.wlp, .xml или zip архив таких файлов',
    next: 'Далее',
    findEquipment:
      'Обнаружены следующие устройства, выберите для них протоколы:',
    selectProtocol: 'Выберите протокол',
    back: 'Назад',
    findedParametrs:
      'Обнаружены следующие параметры выбранные в датчиках, проверьте их соответствие. В будущем вы сможете их изменить.',
    import: 'Импортировать'
  },
  integratorsTranslater: {
    addParameter: 'Добавление интегратора ',
    editParameter: 'Редактирование интегратора',
    name: 'Название',
    enterName: 'Введите Название',
    INN: 'ИНН',
    fieldMiss: 'Заполните поле',
    numberCallCenter: 'Номер контактного центра',
    contactPerson: 'Контактное лицо',
    email: 'Email',
    typeOrganization: 'Тип организации',
    password: 'Пароль',
    smsTariff: 'Смс-тариф',
    paymentInforamion: 'Платёжная информация',
    paySystem: 'Платежная система',
    idShop: 'Идентификатор магазина',
    secretKey: 'Секретный ключ',
    change: 'Изменить',
    delete: 'Удалить',
    ofertaLoaded: 'Оферта загружена',
    moveOferta: 'Поместите оферту сюда или нажмите для выбора',
    documentMobileApp:
      'Соглашение об использовании мобильного приложения будет отображаться вашим клиентам',
    tariffDefault: 'Тариф по умолчанию для новых компаний',
    typeAccount: 'Тип учета',
    priceObject: 'Стоимость за объект',
    periodPayment: 'Период оплаты (дни)',
    tryPeriod: 'Пробный период (дней)',
    tariffDefaultQR: 'Тариф по умолчанию для регистраций по QR',
    cancel: 'Отменить',
    fileSize: 'Размер файла не должен быть больше 10мб',
    address: 'Адрес',
    services: 'Услуги',
    tariffDescription: 'Описание тарифа'
  },
  inviteUserTranslater: {
    invite: 'Пригласить',
    next: 'Далее',
    enterNumber: 'Введите номер телефона в формате +XXXXXXXXXX или XXXXXXXXXX',
    back: 'Назад',
    register: 'Зарегистрироваться',
    passwordMustBe: 'Пароль должен быть больше 5 символов',
    wrongNumber: 'Неправильный номер телефона'
  },
  objecrRecoveryTranslater: {
    copyObject: 'Копирование объекта',
    restore: 'Вы действительно хотите восстановить?',
    objectCompany: ' объект в компании',
    restoring: 'Восстановить',
    success: 'объект успешно восстановлен'
  },
  qrAdminTranslater: {
    qrCreate: 'QR-код создания',
    register: 'Регистрация и ',
    addGps: 'Добавление GPS-трекера',
    print: 'Печать',
    scanCode: `Отсканируйте код для регистрации GPS-трекера или введите код по ссылке ${currentUrl}c`
  },
  retranslatorTranlsater: {
    changeIMEI: 'Заменить на IMEI',
    protocol: 'Протокол',
    serverRetranslation: 'Сервер ретрансляции',
    port: 'Порт ретрасляции',
    retranslationFrom: 'Ретраслировать с',
    retranslationTo: 'Ретраслировать до',
    participant: 'Идентификатор участника программы:',
    route_identifier: 'Идентификатор маршрута:',
    gps_signal_category: 'Категория GPS-сигнала:',
    hint: 'Медленный используется для трекеров общественного транспорта',
    type_vehicle: 'Тип общественного транспортного средства:'
  },
  adminPanelTranlsater: {
    importWialon: 'Импорт из Wialon',
    importKML: 'Импорт из KML',
    invite: 'Пригласить',
    object: 'Объект',
    name: 'Имя',
    phone: 'Телефон',
    typeTerminal: 'Тип терминала',
    command: 'Команда',
    user: 'Пользователь',
    grade: 'Оценка',
    type: 'Тип',
    message: 'Cообщение',
    company: 'Компания',
    total: 'Сумма',
    date: 'Дата',
    numberType: '№ Тип',
    period: 'Период',
    protocol: 'Протокол',
    addCompany: 'Добавить компанию',
    copyObject: 'Скопировать объект',
    copyTemplate: 'Скопировать Шаблон',
    recoveryObject: 'Восстановить объект',
    cashType: {
      deposit: 'ручной',
      card: 'с карты',
      withdrawal: 'оплата тарифа',
      trial: 'пробная версия'
    },
    enterEmail: 'введите электронную почту',
    wrongEmail: 'Неверная электронная почта',
    notData: 'Данные отсутствуют',
    acceptDeleteTemplate: 'Вы уверены, что хотите удалить Шаблон ?',
    delete: 'Удалить',
    cancel: 'Отменить'
  },
  settings: {
    main: {
      exit: 'Выйти',
      company: 'Компания:',
      go_admin_panel: 'Перейти в админ. панель',
      go_back: 'Вернуться',
      go_admin_panel_beta: 'Админ. панель',
      company_settings: 'Настройки компании',
      finance_and_balance: 'Финансы и баланс:',
      company_tariff: 'Тариф компании',
      add_balance: 'Пополнить баланс',
      payments_history: 'История платежей',
      financical_report: 'Финансовый отчет',
      work_with_company: 'Работа с компанией:',
      access_settings: 'Настройки доступов',
      invite_user: 'Пригласить пользователя',
      display_settings: 'Настройки отображения',
      sessions: 'Сессии',
      additional: 'Дополнительно:',
      news_and_updates: 'Новости и обновления',
      reference: 'Справка (Wiki)',
      support: 'Поддержка'
    },
    user: {
      edit: 'Редактирование',
      user: 'Пользователь:',
      access_level: 'Уровень доступа:',
      name: 'Имя:',
      email: 'Email:',
      language: 'Язык:',
      phone: 'Телефон:',
      code: 'Код:',
      mailing_id: 'Id-рассылки для Telegram:',
      note: {
        first: '1) Войдите в телеграм-бот:t.me/skif2_bot',
        second: '2) Нажмите на Start, бот возвращает ID рассылки для Telegram'
      },
      description: 'Описание:',
      set_password: 'Задать пароль',
      change_password: 'Изменить пароль',
      change_access_level: 'Изменить уровень доступа',
      success: {
        update_info: 'Информация успешно обновлена',
        avatar_change: 'Аватар был успешно обновлен',
        change_password: 'Пароль был успешно изменен'
      },
      errors: {
        name: 'Введите имя',
        email: 'Введите Email',
        phone_confirmation: 'Телефон не подтверждён.',
        admin_strong_pass:
          "Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' (\") , < . > / ?)",
        user_strong_pass:
          "Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' (\") , < . > / ?)",
        simple_pass:
          'Пароль должен состоять от 5 до 25 символов и состоять как минимум из одной английской буквы и как минимум одной цифры'
      }
    },
    display: {
      title: 'Настройки отображения',
      map: 'Карта',
      hide_events: 'Скрыть события на карте:',
      monitoring: 'Мониторинг',
      group_by_dots: 'Группировать, если расстояние менее (точек экрана):',
      object_grouping: 'Группировка объектов на карте:',
      show_annotation:
        'Показывать аннотацию при клике на объект в списке мониторинга:',
      notification: 'Уведомления',
      hide_notification: 'Скрыть уведомления на карте:',
      geozones: 'ГЕОЗОНЫ',
      show_geozones: 'Показать геозоны на карте:',
      meter: 'м',
      kilometer: 'км',
      hectar: 'га',
      area_measurement: 'Единицы измерения площади:',
      track_weight: 'Толщина трека на карте: '
    },
    company: {
      title: 'Настройки компании',
      name: 'Название:',
      time_zone: 'Часовой пояс:',
      integrator: 'Интегратор:',
      exit_time: 'Выход через n минут бездействия',
      exit_time_tooltip: '0 или пустое поле - не будет выхода по бездействию',
      high_security_mode: 'Режим повышенной безопасности',
      high_security_mode_hint: {
        first:
          'Для повышения безопасности, при активации этого режима новым пользователям, потребуется создавать надежные пароли. Такой пароль должен содержать не менее 8 символов (для администраторов не менее 15 символов), включать буквы верхнего и нижнего регистра, цифры и специальные символы.',
        second:
          'При смене пароля пользователю, необходимо войти в систему с использованием пароля от администратора, а затем установить собственный пароль для учетной записи. Таким образом, администратору не будет известен пароль от аккаунта пользователя.'
      },

      retransmit_efis: 'Ретранслировать в ЕФИС',
      data_format: 'Формат даты:',
      time_format: 'Формат времени:',
      last_widget_time: 'Время последней даты в виджете:',
      note: 'Укажите какой формат времени будет применяться при выборе последней даты в интервале дат (в виджете выбора дат).',
      success: {
        update_company: 'Настройки компании успешно обновлены'
      }
    },
    tariff: {
      title: 'Информация по тарифу',
      on_balance: 'На счету (баланс):',
      accounting_type: 'Тип учета:',
      object_count: 'Макс. кол-во объектов:',
      object_price: 'Стоимость 1 объекта:',
      period_start: 'Начало текущего периода:',
      payment_period: 'Период оплаты:',
      autopay: 'Автоплатёж:',
      card: 'Карта:',
      no_card_added: 'Карта не добавлена',
      turn_on_autopay: 'Включить автоплатёж',
      next_write_off: 'Следующее списание:',
      last_period_sum: 'Сумма за прошлый период',
      active_autopay: 'Активирован автоплатёж',
      by_number_objects: 'По числу объектов',
      by_number_objects_sent_data: 'По числу объектов присылавших данные',
      card_section: {
        add: 'Добавление карты',
        change: 'Смена карты для автоплатежа',
        add_note:
          'Для добавления карты автоплатежа, нам потребуется списать у вас 1 рубль. При оплате укажите реквизиты карты.',
        select_system: '*Выберите платёжную систему:',
        visa_master: 'Visa или Mastercard',
        mir_system: 'Система МИР',
        temporarily_unavailable: 'Временно не доступно',
        continue: 'Продолжить',
        errors: {
          mir_error: 'Автоплатёж временно недоступен с картами МИР'
        }
      },
      errors: {
        autopay:
          'Функция автоплатежа была отключена, так как на карте недостаточно средств для совершения операции.'
      },
      success: {
        update_autopay: 'Автоплатеж успешно обновлен',
        delete_card: 'Карта успешна удалена'
      }
    },
    balance: {
      top_up_title: 'Пополнение',
      company: 'Компания:',
      top_up_amount: 'Сумма пополнения',
      min_amount: 'Минимальная сумма платежа',
      currency: 'руб',
      save_card: 'Сохранить карту',
      turn_on_autopay: 'Включить автоплатёж',
      top_up: 'Пополнить',
      mir_unavailable: 'Карты МИР временно не доступны для сохранения',
      errors: {
        amount: 'Введите сумму пополнения'
      }
    },
    invite: {
      title: 'Пригласить пользователя',
      user_email: 'Email пользователя:',
      user_email_placeholder: 'Укажите email',
      access_level: 'Уровень доступа:',
      submit_button: 'Пригласить',
      errors: {
        user_email: 'Введите email',
        access_level: 'Выберите уровень доступа'
      },
      success: {
        invite: 'Пользователь был успешно приглашен'
      }
    },
    support: {
      title: 'Поддержка',
      description:
        'Добрый день! Сотрудники Мониторинг плюс (Москва), будут рады ответить на ваши вопросы. Чтобы связаться с нами обратитесь по одному из контактов ниже:',
      contacts: {
        telegram: 'Telegram: @Monitoring_plus',
        email: 'E-mail: monitoringplus@support.ru',
        phone: 'Телефон (только по будням с 9:00 до 18:00): 89008887712'
      }
    }
  },
  demoTourTranslater: {
    title: 'Ваш основной инструмент — это карта',
    text: 'Мы стремимся предоставить пользователям четкое и полное представление об объектах, поэтому в нашем приложении большое внимание уделяется взаимодействию с картой, так как она является основным инструментом визуализации.',
    next: 'Далее',
    video: 'Видеообзор интерфейса',
    watchVideoYouTube: 'Смотреть ознакомительное видео на YouTube',
    interface: 'Видеообзор интерфейса',
    modules: 'Модули',
    search: 'Поиск, уведомления и настройки',
    workMap: 'Работа с картой',
    link: 'Связь с разработчиками'
  },
  helperTranslater: {
    helper: 'Помощник'
  },
  addLocatorTranslate: {
    selectObject: 'Выберите объект',
    selectDateFrom: 'Выберите дату начала',
    selectDateTo: 'Выберите дату завершения'
  },
  dealerTranslater: {
    register: 'Регистрация нового дилера',
    typeOrganization: 'Тип организации',
    nameCompany: 'Название компании',
    enterNameCompany: 'Введите название компании',
    INN: 'ИНН',
    enterINN: 'Введите ваш ИНН',
    enterPhone: 'Введите ваш номер телефона',
    next: 'Далее',
    registerNewDealer: 'Регистрация нового дилера',
    email: 'Email',
    enterEmail: 'Введите ваш email',
    surname: 'ФИО',
    enterSurname: 'Введите ваш ФИО',
    password: 'Пароль',
    enterPassword: 'Введите пароль',
    rewritePassword: 'Подтвердите пароль*',
    enterCodeFromImage: 'Для завершения регистрации введите код с картинки:',
    back: 'Назад',
    goRegister: 'Зарегистрироваться',
    address: 'Адрес',
    services: 'Описание услуг',
    tariff_description: 'Описание тарифа',
    enter: 'Укажите',
    confirmHeader: 'Регистрация нового интегратора завершена',
    confirmMsg: 'В ближайшее время вы получите email письмо с ссылкой на телеграм чат поддержки, в чате вам ответят на все вопросы и помогут при миграции с другой платформы.',
    confirmMaterials: 'Дополнительные материалы:',
    confirmFastStart: 'Быстрый старт',
    confirmDescBill: 'Описание тарификации',
    confirmDescAdmin: 'Описание Админ панели',
    confirmDescUi: 'Описание интерфейса',
    confirmContacts: 'Контакты:',
    confirmGoToApp: 'Перейти в приложение',
    confirmApp: 'приложение',
    confirmDownload: 'Загрузить'
  },
  unitsVisiblityTranslater: {
    filterFormMonitoring: 'Применена фильтрация из Мониторинга.',
    notShowAllObject: 'Отображаются не все объекты.',
    showAllObject: 'Показать все объекты'
  },
  monitoringListTranslater: {
    withoutgroup: 'Без группы'
  },
  annotationCameraTranslater: {
    video: 'Видео',
    object: 'Обьект:',
    videoRegister: 'Видео-регистратор:',
    grid: 'Сетка',
    ribbon: 'Лента',
    see: 'Смотреть'
  },
  sensorNotificationTranslater: {
    inFrame: 'В установленных рамках',
    outFrame: 'Вне установленных рамок'
  },
  objectsSensors: {
    copySensors: 'Копировать датчики',
    clearSelect: 'Очистить выбор',
    noData: 'Нет данных',
    cansel: 'Отменить',
    copy: 'Скопировать'
  },

  fill_field: 'Поиск по объектам',
  search_company: 'Поиск по объектам',
  sensors_details: {
    sensor_validator: 'Датчик валидатор:',
    choose: 'Выбрать',
    help_validator:
      'Валидатора поможет отфильтровать или преобразовать значения датчика',
    validation_principle: 'Принцип валидации:',
    validation_info:
      'В мониторинге не будет отображаться значение датчика если валидных данных не было более 3 суток.'
  },
  // редактор параметров отчета
  reportEdit: {
    sensor: {
      addTotalValue: 'Добавить итоговое значение в отчете:',
      hintText:
        'Добавить результирующее значение в строке итогов по данному столбцу в сформированном отчете.'
    }
  },
  reportsTranslater: {
    noData: 'Нет данных для построения отчета',
    rewrite: 'Перезапросить'
  },
  // Редактирование шаблона отчета - фильтрация интервалов
  reportsFilter: {
    title: 'Фильтрация',
    filterByTime: 'Фильтрация по интервалам времени:',
    filterHintSelectInterval:
      'Выберите временные интервалы по которым будут отфильтрованы данные в отчете.',
    addInterval: 'Добавить интервал',
    daysWeek: 'Дни недели:',
    trimmerInterval: 'Обрезать интервалы:',
    filterHintFunction:
      'Если функция включена, то состояние (например, поездка), начавшееся внутри интервала, но завершившееся за его пределами, будет обрезано. Только продолжительность внутри указанного интервала будет учтена в отчёте, остальное будет отброшено.',
    zeroInterval: 'Нулевой интервал!'
  }
}

export default translation

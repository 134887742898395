<template>
  <div class="app-download">
    <div class="img-container">
      <img src="../assets/screen.jpg" alt="" />
    </div>
    <div v-if="isNew" class="mt-4">
      <span>
        Установите приложение SKIF.PRO, для входа используйте email (на который
        пришло приглашение) и пароль установленный на предыдущем шаге.
      </span>
    </div>
    <div v-else class="mt-4">
      <span>
        Установите приложение SKIF.PRO, для входа используйте email и пароль от
        вашей учетной записи.
      </span>
    </div>
    <button @click="$emit('downloadapp')" class="mt-4 download-btn">
      Установить приложение SKIF.PRO
    </button>
  </div>
</template>

<script>
export default {
  name: 'AppDownload',
  props: {
    isNew: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss">
.app-download {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Source Sans Pro;
  color: #292929;
  font-size: 16px;
}
.download-btn {
  background: #2767b6;
  border-radius: 12px;
  color: #fff;
  padding: 12px 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;
  width: 360px;
  cursor: pointer;
  border: none;
}
.img-container {
  border-bottom: 1px solid #d1d1d6;
}
</style>
